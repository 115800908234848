$sidebar-width: 60px;

textarea {
  resize: none;
}

.editor-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  .left-sidebar {
    z-index: 4;
    display: flex;
    flex-direction: column;
    width: $sidebar-width + 2px;
    background: white;
    border-right: 1px solid $gray-light-3;
  }

  .left-sidebar-drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $sidebar-width;
    z-index: 3;

    /* blueprintjs drawer overrides */
    .bp4-portal {
      .bp4-drawer {
        left: $sidebar-width;
        overflow-y: auto;
      }

      .bp4-overlay-backdrop {
        background-color: rgba(16, 22, 26, 0.3);
      }
    }
  }

  .viewport-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
}

/* remove active highlights */
*,
:focus {
  outline: none;
}

// Preview mode

.preview-mode {
  #chat-widget-container {
    display: none;
  }
  .player-ui-container .player-logo {
    display: none;
  }
}

// Restructions Mobile overlay

.restrictions-mobile-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $gray-light-5;
  z-index: 999;

  .panel {
    width: 80%;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 28px 20px 28px 16px;
    border-radius: $border-radius-xl;
    box-shadow: $elevation1;
    display: flex;
    align-items: flex-start;

    .custom-icon {
      width: 36px;
      height: 36px;
      color: $primary;
      flex-shrink: 0;
      margin-right: 10px;
      margin-top: -2px;
    }
    .bp4-heading {
      font-size: 16px;
      line-height: 1.2;
    }

    p {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: #{$mobile-threshold}) and (orientation: portrait) {
  .restrictions-mobile-overlay {
    display: block;
  }
  .editor-container .viewport-container {
    overflow: hidden;
  }
}
