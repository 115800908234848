/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */

$black: #111418;

$dark-gray1: #1c2127;
$dark-gray2: #252a31;
$dark-gray3: #2f343c;
$dark-gray4: #383e47;
$dark-gray5: #404854;

$gray1: #5f6b7c;
$gray2: #738091;
$gray3: #8f99a8;
$gray4: #abb3bf;
$gray5: #c5cbd3;

$light-gray1: #d3d8de;
$light-gray2: #dce0e5;
$light-gray3: #e5e8eb;
$light-gray4: #edeff2;
$light-gray5: #f6f7f9;

$white: #fff;

$blue1: #184a90;
$blue2: #215db0;
$blue3: #2d72d2;
$blue4: #4c90f0;
$blue5: #8abbff;

$green1: #165a36;
$green2: #1c6e42;
$green3: #238551;
$green4: #32a467;
$green5: #72ca9b;

$orange1: #77450d;
$orange2: #935610;
$orange3: #c87619;
$orange4: #ec9a3c;
$orange5: #fbb360;

$red1: #8e292c;
$red2: #ac2f33;
$red3: #cd4246;
$red4: #e76a6e;
$red5: #fa999c;

$vermilion1: #96290d;
$vermilion2: #b83211;
$vermilion3: #d33d17;
$vermilion4: #eb6847;
$vermilion5: #ff9980;

$rose1: #a82255;
$rose2: #c22762;
$rose3: #db2c6f;
$rose4: #f5498b;
$rose5: #ff66a1;

$violet1: #5c255c;
$violet2: #7c327c;
$violet3: #9d3f9d;
$violet4: #bd6bbd;
$violet5: #d69fd6;

$indigo1: #5642a6;
$indigo2: #634dbf;
$indigo3: #7961db;
$indigo4: #9881f3;
$indigo5: #bdadff;

$cerulean1: #1f4b99;
$cerulean2: #2458b3;
$cerulean3: #2965cc;
$cerulean4: #4580e6;
$cerulean5: #669eff;

$turquoise1: #004d46;
$turquoise2: #007067;
$turquoise3: #00a396;
$turquoise4: #13c9ba;
$turquoise5: #7ae1d8;

$forest1: #1d7324;
$forest2: #238c2c;
$forest3: #29a634;
$forest4: #43bf4d;
$forest5: #62d96b;

$lime1: #43501b;
$lime2: #5a701a;
$lime3: #8eb125;
$lime4: #b6d94c;
$lime5: #d4f17e;

$gold1: #5c4405;
$gold2: #866103;
$gold3: #d1980b;
$gold4: #f0b726;
$gold5: #fbd065;

$sepia1: #5e4123;
$sepia2: #7a542e;
$sepia3: #946638;
$sepia4: #af855a;
$sepia5: #d0b090;
$pt-intent-primary: #2d72d2;
$pt-intent-success: #238551;
$pt-intent-warning: #c87619;
$pt-intent-danger: #cd4246;

$pt-app-background-color: #f6f7f9;
$pt-dark-app-background-color: #2f343c;

$pt-outline-color: rgba(45, 114, 210, 0.6);

$pt-text-color: #1c2127;
$pt-text-color-muted: #5f6b7c;
$pt-text-color-disabled: rgba(95, 107, 124, 0.6);
$pt-heading-color: #1c2127;
$pt-link-color: #215db0;
$pt-dark-text-color: #f6f7f9;
$pt-dark-text-color-muted: #abb3bf;
$pt-dark-text-color-disabled: rgba(171, 179, 191, 0.6);
$pt-dark-heading-color: #f6f7f9;
$pt-dark-link-color: #8abbff;
$pt-text-selection-color: rgba(125, 188, 255, 0.6);

$pt-icon-color: #5f6b7c;
$pt-icon-color-hover: #1c2127;
$pt-icon-color-disabled: rgba(95, 107, 124, 0.6);
$pt-icon-color-selected: #2d72d2;
$pt-dark-icon-color: #abb3bf;
$pt-dark-icon-color-hover: #f6f7f9;
$pt-dark-icon-color-disabled: rgba(171, 179, 191, 0.6);
$pt-dark-icon-color-selected: #2d72d2;

$pt-divider-black: rgba(17, 20, 24, 0.15);
$pt-dark-divider-black: rgba(17, 20, 24, 0.4);
$pt-dark-divider-white: rgba(255, 255, 255, 0.15);

$pt-code-text-color: #5f6b7c;
$pt-dark-code-text-color: #abb3bf;
$pt-code-background-color: rgba(255, 255, 255, 0.7);
$pt-dark-code-background-color: rgba(17, 20, 24, 0.3);
$ns: bp4;
$bp-ns: bp4;

$pt-grid-size: 10px;

$icons16-family: blueprint-icons-16;
$icons20-family: blueprint-icons-20;

$pt-icon-size-standard: 16px;
$pt-icon-size-large: 20px;

$pt-font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16, sans-serif;

$pt-font-family-monospace: monospace;

$pt-font-size: 14px;
$pt-font-size-large: 16px;
$pt-font-size-small: 12px;

$pt-line-height: 1.28581;

$pt-border-radius: 3px;

$pt-button-height: 30px;
$pt-button-height-small: 24px;
$pt-button-height-smaller: 20px;
$pt-button-height-large: 40px;

$pt-input-height: 30px;
$pt-input-height-large: 40px;
$pt-input-height-small: 24px;

$pt-navbar-height: 50px;

$pt-z-index-base: 0;
$pt-z-index-content: 10;
$pt-z-index-overlay: 20;
$pt-z-index-dialog-header: 30;

$pt-border-shadow-opacity: 0.1;
$pt-drop-shadow-opacity: 0.2;
$pt-dark-border-shadow-opacity: 0.2;
$pt-dark-drop-shadow-opacity: 0.4;

$pt-elevation-shadow-0: 0 0 0 1px rgba(17, 20, 24, 0.15),
  0 0 0 rgba(17, 20, 24, 0), 0 0 0 rgba(17, 20, 24, 0);
$pt-elevation-shadow-1: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 0 0 rgba(17, 20, 24, 0), 0 1px 1px rgba(17, 20, 24, 0.2);
$pt-elevation-shadow-2: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 1px 1px rgba(17, 20, 24, 0.2), 0 2px 6px rgba(17, 20, 24, 0.2);
$pt-elevation-shadow-3: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
$pt-elevation-shadow-4: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);

$pt-dark-elevation-shadow-0: 0 0 0 1px rgba(17, 20, 24, 0.4),
  0 0 0 rgba(17, 20, 24, 0), 0 0 0 rgba(17, 20, 24, 0);
$pt-dark-elevation-shadow-1: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 0 0 rgba(17, 20, 24, 0), 0 1px 1px rgba(17, 20, 24, 0.4);
$pt-dark-elevation-shadow-2: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 1px 1px rgba(17, 20, 24, 0.4), 0 2px 6px rgba(17, 20, 24, 0.4);
$pt-dark-elevation-shadow-3: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
$pt-dark-elevation-shadow-4: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 4px 8px rgba(17, 20, 24, 0.4), 0 18px 46px 6px rgba(17, 20, 24, 0.4);

$pt-transition-ease: cubic-bezier(0.4, 1, 0.75, 0.9);
$pt-transition-ease-bounce: cubic-bezier(0.54, 1.12, 0.38, 1.11);
$pt-transition-duration: 100ms;

$pt-input-box-shadow: inset 0 0 0 1px rgba(17, 20, 24, 0.2),
  inset 0 1px 1px rgba(17, 20, 24, 0.5);

$pt-dialog-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 4px 8px rgba(17, 20, 24, 0.2), 0 18px 46px 6px rgba(17, 20, 24, 0.2);
$pt-popover-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
$pt-tooltip-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.1),
  0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);

$pt-dark-input-box-shadow: inset 0 1px 0 0 rgba(17, 20, 24, 0.6),
  inset -1px 0 0 rgba(17, 20, 24, 0.6), inset 1px 0 0 rgba(17, 20, 24, 0.6),
  inset 0 -1px 0 0 #8f99a8;

$pt-dark-dialog-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 4px 8px rgba(17, 20, 24, 0.4), 0 18px 46px 6px rgba(17, 20, 24, 0.4);
$pt-dark-popover-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
$pt-dark-tooltip-box-shadow: 0 0 0 1px rgba(17, 20, 24, 0.2),
  0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
$pt-dark-input-intent-box-shadow-colors: (
  "primary": #4c90f0,
  "success": #32a467,
  "warning": #ec9a3c,
  "danger": #e76a6e
);
