.settings-panel {
  padding: 25px;
  padding-top: 20px;

  .bp4-label {
    font-size: 16px;
  }

  .thumbnail-preview {
    position: relative;
    margin: 10px 0 20px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .bp4-form-group {
      margin-bottom: 0;
    }
    .bp4-spinner {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 44px;
      background: rgba(255, 255, 255, 0.6);
    }
    .bp4-button {
      border-radius: $border-radius;
    }
  }

  .thumbnail {
    border: 1px solid $gray-light-4;
    border-radius: $border-radius;
    margin-bottom: 8px;
    overflow: hidden;
    animation: fade-in 0.5s ease;
  }

  .bp4-slider {
    width: 100%;
    margin: 7px 0;
  }
}
