// Base

.bp4-icon {
  svg {
    width: 16px;
    height: 16px;
    color: lighten($black-font, 10%);
  }
}

svg {
  path {
    fill: currentColor;
  }

  &.custom-icon {
    color: lighten($black-font, 10%);
    width: 24px;
    height: 24px;
  }
}

.link {
  color: $primary;
  display: inline-block;
  .bp4-icon {
    margin-right: 4px;
    svg {
      color: $primary;
    }
  }
  &:hover {
    color: darken($primary, 5%);
    .bp4-icon svg {
      color: darken($primary, 5%);
    }
  }
}
