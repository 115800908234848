// Layout player

.player-ui-container {
  $player-color: lighten($black-font, 5%);
  $player-background: #fff;
  $player-separation: 12px;
  $player-base-svg-size: 20px;
  $button-height: 72px;

  // Utils

  .bp4-button {
    min-width: 62px;
    min-height: 62px;
    border-radius: $border-radius-md;
  }

  .player-logo {
    display: block;
    z-index: 99;
    width: 120px;
    img {
      display: block;
    }
    svg {
      width: 100%;
      height: auto;
    }
  }

  .top-button {
    background: #fff;
    border: 1px solid $gray-light-4;
    svg {
      color: $primary;
    }
  }

  .big-button {
    color: $primary;
    padding: 20px 28px;
    border-radius: $border-radius-md;
    border: none;
    background: #fff;
    border: 1px solid $gray-light-4;
    .bp4-icon svg,
    .custom-icon {
      color: $primary;
    }

    &:hover {
      background: $gray-light-5;
      color: $primary;
    }

    &.bp4-disabled {
      background: #fff;
      border-color: $gray-light-4;
      color: $gray-light-1;
      path {
        stroke: $gray-light-1;
      }
      &:hover {
        background: $gray-light-5;
      }
    }

    svg {
      width: $player-base-svg-size;
      height: $player-base-svg-size;
    }
  }

  .player-controls {
    svg {
      path {
        stroke: $primary;
      }
    }
  }

  // Top bar

  .top-bar {
    position: fixed;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 0;
    padding: $player-separation;

    .dropdown-menu {
      margin-left: auto;
    }
  }

  // Player controls

  .player-controls {
    position: fixed;
    right: $player-separation;
    top: 50%;
    transform: translateY(-55%);
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      align-self: center;
      margin-bottom: calc($player-separation * 0.75);
    }

    .button-forward {
      .big-button {
        border-radius: $border-radius-md;
        svg {
          width: calc($player-base-svg-size * 2.5);
          height: calc($player-base-svg-size * 2.5);
        }
      }
    }

    .button-back {
      .big-button {
        svg {
          width: calc($player-base-svg-size * 1.5);
          height: calc($player-base-svg-size * 1.5);
        }
      }
    }

    .progress-info {
      padding: 5px 10px;
      border-radius: $border-radius-md;
      background: $player-background;
      color: $player-color;
    }
  }

  // Step instructions

  .player-instructions-container {
    position: fixed;
    bottom: $player-separation;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: $button-height;
    margin: 0 auto;
    width: 80%;
    max-width: 800px;
    left: 0;
    right: 0;
  }

  .player-audio-instructions {
    margin-left: calc($player-separation * 0.5);

    .big-button {
      width: $button-height;
      height: $button-height;
      svg {
        width: calc($player-base-svg-size * 2);
        height: calc($player-base-svg-size * 2);
      }
    }

    .audio-button {
      svg {
        width: 36px;
        height: 36px;
        animation: fade-in-out 2s ease forwards infinite;
      }
    }
  }

  .player-text-instructions {
    border: none;
    font-size: 21px;
    text-align: center;
    min-width: 30vw;
    max-width: 60vw;
    min-height: $button-height;
    color: $player-color;
    background: #fff;
    border-radius: $border-radius-md;
    padding: 20px;
    border: 1px solid $gray-light-4;
    position: relative;

    &.multiple-lines {
      text-align: left;
    }

    p {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .read-more {
    color: $primary;
    border: none;
    box-shadow: none;
    background: transparent;
    font-size: 16px;
    font-weight: $medium;
    margin-top: 2px;
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    background: linear-gradient(
      0deg,
      white 0%,
      white 40%,
      rgba(255, 255, 255, 0) 100%
    );
    padding: 48px 10px 6px 10px;
    display: none;
    &.visible {
      display: block;
    }
  }

  // Detailed text instructions

  .detailed-instructions {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    display: none;
    background: #fff;
    padding: 16px 16px 66px 16px;
    font-size: 16px;
    line-height: 1.4;
    animation: appear-bottom $general-change;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    box-shadow: $elevation1;
    z-index: 99;
    &.visible {
      display: block;
      + .player-text-instructions {
        display: none;
      }
    }
    .bp4-button {
      min-height: 40px;
      background: $primary;
      border-radius: $border-radius;
      font-weight: $semibold;
      &:hover {
        background: darken($primary, 10%);
      }
    }
    .actions {
      background: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 16px 16px;
      display: flex;
      justify-content: flex-end;
    }
  }

  // Phone portrait

  @media screen and (max-width: #{$mobile-threshold}) and (orientation: portrait) {
    .detailed-instructions {
      padding-bottom: 60px;
      font-size: 15px;
      .bp4-button {
        width: 100%;
      }
      .actions {
        padding: 12px;
      }
    }

    .read-more {
      font-size: 14px;
      bottom: 5px;
      padding-top: 28px;
      background: linear-gradient(
        0deg,
        white 0%,
        white 30%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .top-bar {
      height: auto;
      align-items: center;
      padding: 10px;
      .top-button {
        min-width: 42px;
        min-height: 42px;
      }
      .player-logo {
        width: 87px;
      }
    }

    .player-controls {
      flex-direction: row;
      top: auto;
      bottom: 0;
      width: 100%;
      transform: none;
      left: 0;
      padding: 10px;
      .bp4-button {
        width: 100%;
        height: 56px;
        padding: 10px;
        min-width: 0;
        min-height: 0;
        font-size: 14px;
      }

      .button-back,
      .button-forward {
        flex: 1 1 50%;
        margin-bottom: 0;
        font-weight: 500;
        .big-button {
          svg {
            width: 26px;
            height: 26px;
          }
        }
      }

      .button-back {
        margin-right: 5px;
        order: -1;
        .big-button {
          &:after {
            content: 'Previous step';
            margin-left: 10px;
          }
        }
      }

      .button-forward {
        margin-left: 5px;
        svg {
          margin-right: -10px;
        }
        .big-button {
          &:before {
            content: 'Next step';
          }
        }
      }

      .progress-info {
        position: fixed;
        top: 21px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        padding: 2px 6px;
      }
    }

    .player-instructions-container {
      padding: 0 10px;
      bottom: 65px;
      width: 100%;
      flex-direction: column;
      align-items: flex-end;
      min-height: 0;
      left: 0;
      transform: none;
    }

    .player-text-instructions {
      font-size: 15px;
      max-width: none;
      flex: 1 1 auto;
      width: 100%;
      min-height: 0;
      padding: 10px 11px;
      margin: 0 0 10px 0;
      text-align: left;
      p {
        -webkit-line-clamp: 3;
      }
    }

    .player-audio-instructions {
      order: -1;
      position: static;
      .big-button {
        width: auto;
        height: auto;
        padding: 6px;
        min-width: 0;
        min-height: 0;
        background: transparent;
        border: none;
        font-size: 14px;
        font-weight: 500;
        margin: 0 2px 0 0;
        &:after {
          content: 'Play audio';
        }
        .custom-icon {
          width: 20px;
          height: 20px;
          margin-right: 6px;
        }
      }
      .audio-button {
        &:after {
          content: 'Audio playing...';
        }
      }
    }
  }
}
