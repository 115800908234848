.bp4-context-menu2-virtual-target{
  position:fixed;
}
.bp4-popover2{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  -webkit-transform:scale(1);
          transform:scale(1);
  border-radius:3px;
  display:inline-block;
  z-index:20;
}
.bp4-popover2 .bp4-popover2-arrow{
  height:30px;
  position:absolute;
  width:30px;
}
.bp4-popover2 .bp4-popover2-arrow::before{
  height:20px;
  margin:5px;
  width:20px;
}
.bp4-popover2 .bp4-popover2-content{
  background:#fff;
}
.bp4-popover2 .bp4-popover2-content,
.bp4-popover2 .bp4-heading{
  color:inherit;
}
.bp4-popover2 .bp4-popover2-arrow::before{
  -webkit-box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
          box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp4-popover2 .bp4-popover2-arrow-border{
  fill:#111418;
  fill-opacity:0.1;
}
.bp4-popover2 .bp4-popover2-arrow-fill{
  fill:#fff;
}
.bp4-popover2-enter > .bp4-popover2, .bp4-popover2-appear > .bp4-popover2{
  -webkit-transform:scale(0.3);
          transform:scale(0.3);
}
.bp4-popover2-enter-active > .bp4-popover2, .bp4-popover2-appear-active > .bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:300ms;
          transition-duration:300ms;
  -webkit-transition-property:-webkit-transform;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
          transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-popover2-exit > .bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
}
.bp4-popover2-exit-active > .bp4-popover2{
  -webkit-transform:scale(0.3);
          transform:scale(0.3);
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:300ms;
          transition-duration:300ms;
  -webkit-transition-property:-webkit-transform;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
          transition-timing-function:cubic-bezier(0.54, 1.12, 0.38, 1.11);
}
.bp4-popover2 .bp4-popover2-content{
  border-radius:3px;
  position:relative;
}
.bp4-popover2.bp4-popover2-content-sizing .bp4-popover2-content{
  max-width:350px;
  padding:20px;
}
.bp4-popover2-target + .bp4-overlay .bp4-popover2.bp4-popover2-content-sizing{
  width:350px;
}
.bp4-popover2.bp4-minimal{
  margin:0 !important;
}
.bp4-popover2.bp4-minimal .bp4-popover2-arrow{
  display:none;
}
.bp4-popover2.bp4-minimal.bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
}
.bp4-popover2-enter > .bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-appear > .bp4-popover2.bp4-minimal.bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
}
.bp4-popover2-enter-active > .bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-appear-active > .bp4-popover2.bp4-minimal.bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:100ms;
          transition-duration:100ms;
  -webkit-transition-property:-webkit-transform;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
          transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-exit > .bp4-popover2.bp4-minimal.bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
}
.bp4-popover2-exit-active > .bp4-popover2.bp4-minimal.bp4-popover2{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:100ms;
          transition-duration:100ms;
  -webkit-transition-property:-webkit-transform;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
          transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2.bp4-dark, .bp4-dark .bp4-popover2{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-popover2.bp4-dark .bp4-popover2-content, .bp4-dark .bp4-popover2 .bp4-popover2-content{
  background:#383e47;
}
.bp4-popover2.bp4-dark .bp4-popover2-content,
.bp4-popover2.bp4-dark .bp4-heading, .bp4-dark .bp4-popover2 .bp4-popover2-content,
.bp4-dark .bp4-popover2 .bp4-heading{
  color:inherit;
}
.bp4-popover2.bp4-dark .bp4-popover2-arrow::before, .bp4-dark .bp4-popover2 .bp4-popover2-arrow::before{
  -webkit-box-shadow:1px 1px 6px rgba(17, 20, 24, 0.4);
          box-shadow:1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp4-popover2.bp4-dark .bp4-popover2-arrow-border, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-border{
  fill:#111418;
  fill-opacity:0.2;
}
.bp4-popover2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-fill{
  fill:#383e47;
}

.bp4-popover2-arrow::before{
  border-radius:2px;
  content:"";
  display:block;
  position:absolute;
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
}

.bp4-overlay-backdrop.bp4-popover2-backdrop{
  background:rgba(255, 255, 255, 0);
}

.bp4-popover2-transition-container{
  opacity:1;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  z-index:20;
}
.bp4-popover2-transition-container.bp4-popover2-enter, .bp4-popover2-transition-container.bp4-popover2-appear{
  opacity:0;
}
.bp4-popover2-transition-container.bp4-popover2-enter-active, .bp4-popover2-transition-container.bp4-popover2-appear-active{
  opacity:1;
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:100ms;
          transition-duration:100ms;
  -webkit-transition-property:opacity;
  transition-property:opacity;
  -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
          transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-transition-container.bp4-popover2-exit{
  opacity:1;
}
.bp4-popover2-transition-container.bp4-popover2-exit-active{
  opacity:0;
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:100ms;
          transition-duration:100ms;
  -webkit-transition-property:opacity;
  transition-property:opacity;
  -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
          transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-transition-container:focus{
  outline:none;
}
.bp4-popover2-transition-container.bp4-popover2-leave .bp4-popover2-content{
  pointer-events:none;
}

span.bp4-popover2-target{
  display:inline-block;
}
.bp4-button-group:not(.bp4-minimal) > .bp4-popover2-target:not(:first-child) .bp4-button{
  border-bottom-left-radius:0;
  border-top-left-radius:0;
}
.bp4-button-group:not(.bp4-minimal) > .bp4-popover2-target:not(:last-child) .bp4-button{
  border-bottom-right-radius:0;
  border-top-right-radius:0;
  margin-right:-1px;
}
.bp4-button-group .bp4-popover2-target{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:first-child .bp4-button{
  border-radius:3px 3px 0 0;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:last-child .bp4-button{
  border-radius:0 0 3px 3px;
}
.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:not(:last-child) .bp4-button{
  margin-bottom:-1px;
}
.bp4-control-group .bp4-popover2-target{
  border-radius:inherit;
}
label.bp4-label .bp4-popover2-target{
  display:block;
  margin-top:5px;
  text-transform:none;
}
.bp4-submenu .bp4-popover2-target{
  display:block;
}
.bp4-submenu.bp4-popover2{
  -webkit-box-shadow:none;
          box-shadow:none;
  padding:0 5px;
}
.bp4-submenu.bp4-popover2 > .bp4-popover2-content{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
}
.bp4-dark .bp4-submenu.bp4-popover2, .bp4-submenu.bp4-popover2.bp4-dark{
  -webkit-box-shadow:none;
          box-shadow:none;
}
.bp4-dark .bp4-submenu.bp4-popover2 > .bp4-popover2-content, .bp4-submenu.bp4-popover2.bp4-dark > .bp4-popover2-content{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-tree-node-secondary-label .bp4-popover2-target{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
}
.bp4-tooltip2{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.1), 0 2px 4px rgba(17, 20, 24, 0.2), 0 8px 24px rgba(17, 20, 24, 0.2);
  -webkit-transform:scale(1);
          transform:scale(1);
}
.bp4-tooltip2 .bp4-popover2-arrow{
  height:22px;
  position:absolute;
  width:22px;
}
.bp4-tooltip2 .bp4-popover2-arrow::before{
  height:14px;
  margin:4px;
  width:14px;
}
.bp4-tooltip2 .bp4-popover2-content{
  background:#404854;
}
.bp4-tooltip2 .bp4-popover2-content,
.bp4-tooltip2 .bp4-heading{
  color:#f6f7f9;
}
.bp4-tooltip2 .bp4-popover2-arrow::before{
  -webkit-box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
          box-shadow:1px 1px 6px rgba(17, 20, 24, 0.2);
}
.bp4-tooltip2 .bp4-popover2-arrow-border{
  fill:#111418;
  fill-opacity:0.1;
}
.bp4-tooltip2 .bp4-popover2-arrow-fill{
  fill:#404854;
}
.bp4-popover2-enter > .bp4-tooltip2, .bp4-popover2-appear > .bp4-tooltip2{
  -webkit-transform:scale(0.8);
          transform:scale(0.8);
}
.bp4-popover2-enter-active > .bp4-tooltip2, .bp4-popover2-appear-active > .bp4-tooltip2{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:100ms;
          transition-duration:100ms;
  -webkit-transition-property:-webkit-transform;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
          transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-popover2-exit > .bp4-tooltip2{
  -webkit-transform:scale(1);
          transform:scale(1);
}
.bp4-popover2-exit-active > .bp4-tooltip2{
  -webkit-transform:scale(0.8);
          transform:scale(0.8);
  -webkit-transition-delay:0;
          transition-delay:0;
  -webkit-transition-duration:100ms;
          transition-duration:100ms;
  -webkit-transition-property:-webkit-transform;
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  -webkit-transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
          transition-timing-function:cubic-bezier(0.4, 1, 0.75, 0.9);
}
.bp4-tooltip2 .bp4-popover2-content{
  padding:10px 12px;
}
.bp4-tooltip2.bp4-popover2-placement-top .bp4-popover2-arrow{
  -webkit-transform:translateY(-3px);
          transform:translateY(-3px);
}
.bp4-tooltip2.bp4-popover2-placement-left .bp4-popover2-arrow{
  -webkit-transform:translateX(-3px);
          transform:translateX(-3px);
}
.bp4-tooltip2.bp4-popover2-placement-bottom .bp4-popover2-arrow{
  -webkit-transform:translateY(3px);
          transform:translateY(3px);
}
.bp4-tooltip2.bp4-popover2-placement-right .bp4-popover2-arrow{
  -webkit-transform:translateX(3px);
          transform:translateX(3px);
}
.bp4-tooltip2.bp4-dark, .bp4-dark .bp4-tooltip2{
  -webkit-box-shadow:0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
          box-shadow:0 0 0 1px rgba(17, 20, 24, 0.2), 0 2px 4px rgba(17, 20, 24, 0.4), 0 8px 24px rgba(17, 20, 24, 0.4);
}
.bp4-tooltip2.bp4-dark .bp4-popover2-content, .bp4-dark .bp4-tooltip2 .bp4-popover2-content{
  background:#e5e8eb;
}
.bp4-tooltip2.bp4-dark .bp4-popover2-content,
.bp4-tooltip2.bp4-dark .bp4-heading, .bp4-dark .bp4-tooltip2 .bp4-popover2-content,
.bp4-dark .bp4-tooltip2 .bp4-heading{
  color:#404854;
}
.bp4-tooltip2.bp4-dark .bp4-popover2-arrow::before, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow::before{
  -webkit-box-shadow:1px 1px 6px rgba(17, 20, 24, 0.4);
          box-shadow:1px 1px 6px rgba(17, 20, 24, 0.4);
}
.bp4-tooltip2.bp4-dark .bp4-popover2-arrow-border, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-border{
  fill:#111418;
  fill-opacity:0.2;
}
.bp4-tooltip2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-fill{
  fill:#e5e8eb;
}
.bp4-tooltip2.bp4-intent-primary .bp4-popover2-content{
  background:#2d72d2;
  color:#fff;
}
.bp4-tooltip2.bp4-intent-primary .bp4-popover2-arrow-fill{
  fill:#2d72d2;
}
.bp4-tooltip2.bp4-intent-success .bp4-popover2-content{
  background:#238551;
  color:#fff;
}
.bp4-tooltip2.bp4-intent-success .bp4-popover2-arrow-fill{
  fill:#238551;
}
.bp4-tooltip2.bp4-intent-warning .bp4-popover2-content{
  background:#c87619;
  color:#fff;
}
.bp4-tooltip2.bp4-intent-warning .bp4-popover2-arrow-fill{
  fill:#c87619;
}
.bp4-tooltip2.bp4-intent-danger .bp4-popover2-content{
  background:#cd4246;
  color:#fff;
}
.bp4-tooltip2.bp4-intent-danger .bp4-popover2-arrow-fill{
  fill:#cd4246;
}

.bp4-tooltip2-indicator{
  border-bottom:dotted 1px;
  cursor:help;
}
/*# sourceMappingURL=blueprint-popover2.css.map */