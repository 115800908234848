html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  letter-spacing: 0;
  text-transform: none;
  color: #1c2127;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28581;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}

small {
  font-size: 12px;
}

strong {
  font-weight: 600;
}

::selection {
  background: #7dbcff99;
}

.bp4-icon {
  vertical-align: text-bottom;
  flex: none;
  display: inline-block;
}

.bp4-icon:not(:empty):before {
  content: "" !important;
  content: unset !important;
}

.bp4-icon > svg {
  display: block;
}

.bp4-icon > svg:not([fill]) {
  fill: currentColor;
}

.bp4-icon.bp4-intent-primary, .bp4-icon-standard.bp4-intent-primary, .bp4-icon-large.bp4-intent-primary {
  color: #215db0;
}

.bp4-dark .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-icon-large.bp4-intent-primary {
  color: #8abbff;
}

.bp4-icon.bp4-intent-success, .bp4-icon-standard.bp4-intent-success, .bp4-icon-large.bp4-intent-success {
  color: #1c6e42;
}

.bp4-dark .bp4-icon.bp4-intent-success, .bp4-dark .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-icon-large.bp4-intent-success {
  color: #72ca9b;
}

.bp4-icon.bp4-intent-warning, .bp4-icon-standard.bp4-intent-warning, .bp4-icon-large.bp4-intent-warning {
  color: #935610;
}

.bp4-dark .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-icon-large.bp4-intent-warning {
  color: #fbb360;
}

.bp4-icon.bp4-intent-danger, .bp4-icon-standard.bp4-intent-danger, .bp4-icon-large.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-dark .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-icon-large.bp4-intent-danger {
  color: #fa999c;
}

span.bp4-icon-standard {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

span.bp4-icon-large {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

span.bp4-icon:empty {
  font-family: blueprint-icons-20;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

span.bp4-icon:empty:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.bp4-icon-blank:before {
  content: "";
}

.bp4-icon-style:before {
  content: "";
}

.bp4-icon-align-left:before {
  content: "";
}

.bp4-icon-align-center:before {
  content: "";
}

.bp4-icon-align-right:before {
  content: "";
}

.bp4-icon-align-justify:before {
  content: "";
}

.bp4-icon-bold:before {
  content: "";
}

.bp4-icon-italic:before {
  content: "";
}

.bp4-icon-underline:before {
  content: "";
}

.bp4-icon-search-around:before {
  content: "";
}

.bp4-icon-graph-remove:before {
  content: "";
}

.bp4-icon-group-objects:before {
  content: "";
}

.bp4-icon-merge-links:before {
  content: "";
}

.bp4-icon-layout:before {
  content: "";
}

.bp4-icon-layout-auto:before {
  content: "";
}

.bp4-icon-layout-circle:before {
  content: "";
}

.bp4-icon-layout-hierarchy:before {
  content: "";
}

.bp4-icon-layout-grid:before {
  content: "";
}

.bp4-icon-layout-group-by:before {
  content: "";
}

.bp4-icon-layout-skew-grid:before {
  content: "";
}

.bp4-icon-geosearch:before {
  content: "";
}

.bp4-icon-heatmap:before {
  content: "";
}

.bp4-icon-drive-time:before {
  content: "";
}

.bp4-icon-select:before {
  content: "";
}

.bp4-icon-predictive-analysis:before {
  content: "";
}

.bp4-icon-layers:before {
  content: "";
}

.bp4-icon-locate:before {
  content: "";
}

.bp4-icon-bookmark:before {
  content: "";
}

.bp4-icon-citation:before {
  content: "";
}

.bp4-icon-tag:before {
  content: "";
}

.bp4-icon-clipboard:before {
  content: "";
}

.bp4-icon-selection:before {
  content: "";
}

.bp4-icon-timeline-events:before {
  content: "";
}

.bp4-icon-timeline-line-chart:before {
  content: "";
}

.bp4-icon-timeline-bar-chart:before {
  content: "";
}

.bp4-icon-applications:before {
  content: "";
}

.bp4-icon-projects:before {
  content: "";
}

.bp4-icon-changes:before {
  content: "";
}

.bp4-icon-notifications:before {
  content: "";
}

.bp4-icon-lock:before {
  content: "";
}

.bp4-icon-unlock:before {
  content: "";
}

.bp4-icon-user:before {
  content: "";
}

.bp4-icon-search-template:before {
  content: "";
}

.bp4-icon-inbox:before {
  content: "";
}

.bp4-icon-more:before {
  content: "";
}

.bp4-icon-help:before {
  content: "";
}

.bp4-icon-calendar:before {
  content: "";
}

.bp4-icon-media:before {
  content: "";
}

.bp4-icon-link:before {
  content: "";
}

.bp4-icon-share:before {
  content: "";
}

.bp4-icon-download:before {
  content: "";
}

.bp4-icon-document:before {
  content: "";
}

.bp4-icon-properties:before {
  content: "";
}

.bp4-icon-import:before {
  content: "";
}

.bp4-icon-export:before {
  content: "";
}

.bp4-icon-minimize:before {
  content: "";
}

.bp4-icon-maximize:before {
  content: "";
}

.bp4-icon-tick:before {
  content: "";
}

.bp4-icon-cross:before {
  content: "";
}

.bp4-icon-plus:before {
  content: "";
}

.bp4-icon-minus:before {
  content: "";
}

.bp4-icon-arrow-left:before {
  content: "";
}

.bp4-icon-arrow-right:before {
  content: "";
}

.bp4-icon-exchange:before {
  content: "";
}

.bp4-icon-comparison:before {
  content: "";
}

.bp4-icon-list:before {
  content: "";
}

.bp4-icon-filter:before {
  content: "";
}

.bp4-icon-confirm:before {
  content: "";
}

.bp4-icon-fork:before {
  content: "";
}

.bp4-icon-trash:before {
  content: "";
}

.bp4-icon-person:before {
  content: "";
}

.bp4-icon-people:before {
  content: "";
}

.bp4-icon-add:before {
  content: "";
}

.bp4-icon-remove:before {
  content: "";
}

.bp4-icon-geolocation:before {
  content: "";
}

.bp4-icon-zoom-in:before {
  content: "";
}

.bp4-icon-zoom-out:before {
  content: "";
}

.bp4-icon-refresh:before {
  content: "";
}

.bp4-icon-delete:before {
  content: "";
}

.bp4-icon-cog:before {
  content: "";
}

.bp4-icon-flag:before {
  content: "";
}

.bp4-icon-pin:before {
  content: "";
}

.bp4-icon-warning-sign:before {
  content: "";
}

.bp4-icon-error:before {
  content: "";
}

.bp4-icon-info-sign:before {
  content: "";
}

.bp4-icon-credit-card:before {
  content: "";
}

.bp4-icon-edit:before {
  content: "";
}

.bp4-icon-history:before {
  content: "";
}

.bp4-icon-search:before {
  content: "";
}

.bp4-icon-log-out:before {
  content: "";
}

.bp4-icon-star:before {
  content: "";
}

.bp4-icon-star-empty:before {
  content: "";
}

.bp4-icon-sort-alphabetical:before {
  content: "";
}

.bp4-icon-sort-numerical:before {
  content: "";
}

.bp4-icon-sort:before {
  content: "";
}

.bp4-icon-folder-open:before {
  content: "";
}

.bp4-icon-folder-close:before {
  content: "";
}

.bp4-icon-folder-shared:before {
  content: "";
}

.bp4-icon-caret-up:before {
  content: "";
}

.bp4-icon-caret-right:before {
  content: "";
}

.bp4-icon-caret-down:before {
  content: "";
}

.bp4-icon-caret-left:before {
  content: "";
}

.bp4-icon-menu-open:before {
  content: "";
}

.bp4-icon-menu-closed:before {
  content: "";
}

.bp4-icon-feed:before {
  content: "";
}

.bp4-icon-two-columns:before {
  content: "";
}

.bp4-icon-one-column:before {
  content: "";
}

.bp4-icon-dot:before {
  content: "";
}

.bp4-icon-property:before {
  content: "";
}

.bp4-icon-time:before {
  content: "";
}

.bp4-icon-disable:before {
  content: "";
}

.bp4-icon-unpin:before {
  content: "";
}

.bp4-icon-flows:before {
  content: "";
}

.bp4-icon-new-text-box:before {
  content: "";
}

.bp4-icon-new-link:before {
  content: "";
}

.bp4-icon-new-object:before {
  content: "";
}

.bp4-icon-path-search:before {
  content: "";
}

.bp4-icon-automatic-updates:before {
  content: "";
}

.bp4-icon-page-layout:before {
  content: "";
}

.bp4-icon-code:before {
  content: "";
}

.bp4-icon-map:before {
  content: "";
}

.bp4-icon-search-text:before {
  content: "";
}

.bp4-icon-envelope:before {
  content: "";
}

.bp4-icon-paperclip:before {
  content: "";
}

.bp4-icon-label:before {
  content: "";
}

.bp4-icon-globe:before {
  content: "";
}

.bp4-icon-home:before {
  content: "";
}

.bp4-icon-th:before {
  content: "";
}

.bp4-icon-th-list:before {
  content: "";
}

.bp4-icon-th-derived:before {
  content: "";
}

.bp4-icon-circle:before {
  content: "";
}

.bp4-icon-draw:before {
  content: "";
}

.bp4-icon-insert:before {
  content: "";
}

.bp4-icon-helper-management:before {
  content: "";
}

.bp4-icon-send-to:before {
  content: "";
}

.bp4-icon-eye-open:before {
  content: "";
}

.bp4-icon-folder-shared-open:before {
  content: "";
}

.bp4-icon-social-media:before {
  content: "";
}

.bp4-icon-arrow-up:before {
  content: "";
}

.bp4-icon-arrow-down:before {
  content: "";
}

.bp4-icon-arrows-horizontal:before {
  content: "";
}

.bp4-icon-arrows-vertical:before {
  content: "";
}

.bp4-icon-resolve:before {
  content: "";
}

.bp4-icon-graph:before {
  content: "";
}

.bp4-icon-briefcase:before {
  content: "";
}

.bp4-icon-dollar:before {
  content: "";
}

.bp4-icon-ninja:before {
  content: "";
}

.bp4-icon-delta:before {
  content: "";
}

.bp4-icon-barcode:before {
  content: "";
}

.bp4-icon-torch:before {
  content: "";
}

.bp4-icon-widget:before {
  content: "";
}

.bp4-icon-unresolve:before {
  content: "";
}

.bp4-icon-offline:before {
  content: "";
}

.bp4-icon-zoom-to-fit:before {
  content: "";
}

.bp4-icon-add-to-artifact:before {
  content: "";
}

.bp4-icon-map-marker:before {
  content: "";
}

.bp4-icon-chart:before {
  content: "";
}

.bp4-icon-control:before {
  content: "";
}

.bp4-icon-multi-select:before {
  content: "";
}

.bp4-icon-direction-left:before {
  content: "";
}

.bp4-icon-direction-right:before {
  content: "";
}

.bp4-icon-database:before {
  content: "";
}

.bp4-icon-pie-chart:before {
  content: "";
}

.bp4-icon-full-circle:before {
  content: "";
}

.bp4-icon-square:before {
  content: "";
}

.bp4-icon-print:before {
  content: "";
}

.bp4-icon-presentation:before {
  content: "";
}

.bp4-icon-ungroup-objects:before {
  content: "";
}

.bp4-icon-chat:before {
  content: "";
}

.bp4-icon-comment:before {
  content: "";
}

.bp4-icon-circle-arrow-right:before {
  content: "";
}

.bp4-icon-circle-arrow-left:before {
  content: "";
}

.bp4-icon-circle-arrow-up:before {
  content: "";
}

.bp4-icon-circle-arrow-down:before {
  content: "";
}

.bp4-icon-upload:before {
  content: "";
}

.bp4-icon-asterisk:before {
  content: "";
}

.bp4-icon-cloud:before {
  content: "";
}

.bp4-icon-cloud-download:before {
  content: "";
}

.bp4-icon-cloud-upload:before {
  content: "";
}

.bp4-icon-repeat:before {
  content: "";
}

.bp4-icon-move:before {
  content: "";
}

.bp4-icon-chevron-left:before {
  content: "";
}

.bp4-icon-chevron-right:before {
  content: "";
}

.bp4-icon-chevron-up:before {
  content: "";
}

.bp4-icon-chevron-down:before {
  content: "";
}

.bp4-icon-random:before {
  content: "";
}

.bp4-icon-fullscreen:before {
  content: "";
}

.bp4-icon-log-in:before {
  content: "";
}

.bp4-icon-heart:before {
  content: "";
}

.bp4-icon-office:before {
  content: "";
}

.bp4-icon-duplicate:before {
  content: "";
}

.bp4-icon-ban-circle:before {
  content: "";
}

.bp4-icon-camera:before {
  content: "";
}

.bp4-icon-mobile-video:before {
  content: "";
}

.bp4-icon-video:before {
  content: "";
}

.bp4-icon-film:before {
  content: "";
}

.bp4-icon-settings:before {
  content: "";
}

.bp4-icon-volume-off:before {
  content: "";
}

.bp4-icon-volume-down:before {
  content: "";
}

.bp4-icon-volume-up:before {
  content: "";
}

.bp4-icon-music:before {
  content: "";
}

.bp4-icon-step-backward:before {
  content: "";
}

.bp4-icon-fast-backward:before {
  content: "";
}

.bp4-icon-pause:before {
  content: "";
}

.bp4-icon-stop:before {
  content: "";
}

.bp4-icon-play:before {
  content: "";
}

.bp4-icon-fast-forward:before {
  content: "";
}

.bp4-icon-step-forward:before {
  content: "";
}

.bp4-icon-eject:before {
  content: "";
}

.bp4-icon-record:before {
  content: "";
}

.bp4-icon-desktop:before {
  content: "";
}

.bp4-icon-phone:before {
  content: "";
}

.bp4-icon-lightbulb:before {
  content: "";
}

.bp4-icon-glass:before {
  content: "";
}

.bp4-icon-tint:before {
  content: "";
}

.bp4-icon-flash:before {
  content: "";
}

.bp4-icon-font:before {
  content: "";
}

.bp4-icon-header:before {
  content: "";
}

.bp4-icon-saved:before {
  content: "";
}

.bp4-icon-floppy-disk:before {
  content: "";
}

.bp4-icon-book:before {
  content: "";
}

.bp4-icon-hand-right:before {
  content: "";
}

.bp4-icon-hand-up:before {
  content: "";
}

.bp4-icon-hand-down:before {
  content: "";
}

.bp4-icon-hand-left:before {
  content: "";
}

.bp4-icon-thumbs-up:before {
  content: "";
}

.bp4-icon-thumbs-down:before {
  content: "";
}

.bp4-icon-box:before {
  content: "";
}

.bp4-icon-compressed:before {
  content: "";
}

.bp4-icon-shopping-cart:before {
  content: "";
}

.bp4-icon-shop:before {
  content: "";
}

.bp4-icon-layout-linear:before {
  content: "";
}

.bp4-icon-undo:before {
  content: "";
}

.bp4-icon-redo:before {
  content: "";
}

.bp4-icon-code-block:before {
  content: "";
}

.bp4-icon-double-caret-vertical:before {
  content: "";
}

.bp4-icon-double-caret-horizontal:before {
  content: "";
}

.bp4-icon-sort-alphabetical-desc:before {
  content: "";
}

.bp4-icon-sort-numerical-desc:before {
  content: "";
}

.bp4-icon-take-action:before {
  content: "";
}

.bp4-icon-contrast:before {
  content: "";
}

.bp4-icon-eye-off:before {
  content: "";
}

.bp4-icon-timeline-area-chart:before {
  content: "";
}

.bp4-icon-doughnut-chart:before {
  content: "";
}

.bp4-icon-layer:before {
  content: "";
}

.bp4-icon-grid:before {
  content: "";
}

.bp4-icon-polygon-filter:before {
  content: "";
}

.bp4-icon-add-to-folder:before {
  content: "";
}

.bp4-icon-layout-balloon:before {
  content: "";
}

.bp4-icon-layout-sorted-clusters:before {
  content: "";
}

.bp4-icon-sort-asc:before {
  content: "";
}

.bp4-icon-sort-desc:before {
  content: "";
}

.bp4-icon-small-cross:before {
  content: "";
}

.bp4-icon-small-tick:before {
  content: "";
}

.bp4-icon-power:before {
  content: "";
}

.bp4-icon-column-layout:before {
  content: "";
}

.bp4-icon-arrow-top-left:before {
  content: "";
}

.bp4-icon-arrow-top-right:before {
  content: "";
}

.bp4-icon-arrow-bottom-right:before {
  content: "";
}

.bp4-icon-arrow-bottom-left:before {
  content: "";
}

.bp4-icon-mugshot:before {
  content: "";
}

.bp4-icon-headset:before {
  content: "";
}

.bp4-icon-text-highlight:before {
  content: "";
}

.bp4-icon-hand:before {
  content: "";
}

.bp4-icon-chevron-backward:before {
  content: "";
}

.bp4-icon-chevron-forward:before {
  content: "";
}

.bp4-icon-rotate-document:before {
  content: "";
}

.bp4-icon-rotate-page:before {
  content: "";
}

.bp4-icon-badge:before {
  content: "";
}

.bp4-icon-grid-view:before {
  content: "";
}

.bp4-icon-function:before {
  content: "";
}

.bp4-icon-waterfall-chart:before {
  content: "";
}

.bp4-icon-stacked-chart:before {
  content: "";
}

.bp4-icon-pulse:before {
  content: "";
}

.bp4-icon-new-person:before {
  content: "";
}

.bp4-icon-exclude-row:before {
  content: "";
}

.bp4-icon-pivot-table:before {
  content: "";
}

.bp4-icon-segmented-control:before {
  content: "";
}

.bp4-icon-highlight:before {
  content: "";
}

.bp4-icon-filter-list:before {
  content: "";
}

.bp4-icon-cut:before {
  content: "";
}

.bp4-icon-annotation:before {
  content: "";
}

.bp4-icon-pivot:before {
  content: "";
}

.bp4-icon-ring:before {
  content: "";
}

.bp4-icon-heat-grid:before {
  content: "";
}

.bp4-icon-gantt-chart:before {
  content: "";
}

.bp4-icon-variable:before {
  content: "";
}

.bp4-icon-manual:before {
  content: "";
}

.bp4-icon-add-row-top:before {
  content: "";
}

.bp4-icon-add-row-bottom:before {
  content: "";
}

.bp4-icon-add-column-left:before {
  content: "";
}

.bp4-icon-add-column-right:before {
  content: "";
}

.bp4-icon-remove-row-top:before {
  content: "";
}

.bp4-icon-remove-row-bottom:before {
  content: "";
}

.bp4-icon-remove-column-left:before {
  content: "";
}

.bp4-icon-remove-column-right:before {
  content: "";
}

.bp4-icon-double-chevron-left:before {
  content: "";
}

.bp4-icon-double-chevron-right:before {
  content: "";
}

.bp4-icon-double-chevron-up:before {
  content: "";
}

.bp4-icon-double-chevron-down:before {
  content: "";
}

.bp4-icon-key-control:before {
  content: "";
}

.bp4-icon-key-command:before {
  content: "";
}

.bp4-icon-key-shift:before {
  content: "";
}

.bp4-icon-key-backspace:before {
  content: "";
}

.bp4-icon-key-delete:before {
  content: "";
}

.bp4-icon-key-escape:before {
  content: "";
}

.bp4-icon-key-enter:before {
  content: "";
}

.bp4-icon-calculator:before {
  content: "";
}

.bp4-icon-horizontal-bar-chart:before {
  content: "";
}

.bp4-icon-small-plus:before {
  content: "";
}

.bp4-icon-small-minus:before {
  content: "";
}

.bp4-icon-step-chart:before {
  content: "";
}

.bp4-icon-euro:before {
  content: "";
}

.bp4-icon-drag-handle-vertical:before {
  content: "";
}

.bp4-icon-drag-handle-horizontal:before {
  content: "";
}

.bp4-icon-mobile-phone:before {
  content: "";
}

.bp4-icon-sim-card:before {
  content: "";
}

.bp4-icon-trending-up:before {
  content: "";
}

.bp4-icon-trending-down:before {
  content: "";
}

.bp4-icon-curved-range-chart:before {
  content: "";
}

.bp4-icon-vertical-bar-chart-desc:before {
  content: "";
}

.bp4-icon-horizontal-bar-chart-desc:before {
  content: "";
}

.bp4-icon-document-open:before {
  content: "";
}

.bp4-icon-document-share:before {
  content: "";
}

.bp4-icon-horizontal-distribution:before {
  content: "";
}

.bp4-icon-vertical-distribution:before {
  content: "";
}

.bp4-icon-alignment-left:before {
  content: "";
}

.bp4-icon-alignment-vertical-center:before {
  content: "";
}

.bp4-icon-alignment-right:before {
  content: "";
}

.bp4-icon-alignment-top:before {
  content: "";
}

.bp4-icon-alignment-horizontal-center:before {
  content: "";
}

.bp4-icon-alignment-bottom:before {
  content: "";
}

.bp4-icon-git-pull:before {
  content: "";
}

.bp4-icon-git-merge:before {
  content: "";
}

.bp4-icon-git-branch:before {
  content: "";
}

.bp4-icon-git-commit:before {
  content: "";
}

.bp4-icon-git-push:before {
  content: "";
}

.bp4-icon-build:before {
  content: "";
}

.bp4-icon-symbol-circle:before {
  content: "";
}

.bp4-icon-symbol-square:before {
  content: "";
}

.bp4-icon-symbol-diamond:before {
  content: "";
}

.bp4-icon-symbol-cross:before {
  content: "";
}

.bp4-icon-symbol-triangle-up:before {
  content: "";
}

.bp4-icon-symbol-triangle-down:before {
  content: "";
}

.bp4-icon-wrench:before {
  content: "";
}

.bp4-icon-application:before {
  content: "";
}

.bp4-icon-send-to-graph:before {
  content: "";
}

.bp4-icon-send-to-map:before {
  content: "";
}

.bp4-icon-join-table:before {
  content: "";
}

.bp4-icon-derive-column:before {
  content: "";
}

.bp4-icon-image-rotate-left:before {
  content: "";
}

.bp4-icon-image-rotate-right:before {
  content: "";
}

.bp4-icon-known-vehicle:before {
  content: "";
}

.bp4-icon-unknown-vehicle:before {
  content: "";
}

.bp4-icon-scatter-plot:before {
  content: "";
}

.bp4-icon-oil-field:before {
  content: "";
}

.bp4-icon-rig:before {
  content: "";
}

.bp4-icon-map-create:before {
  content: "";
}

.bp4-icon-key-option:before {
  content: "";
}

.bp4-icon-list-detail-view:before {
  content: "";
}

.bp4-icon-swap-vertical:before {
  content: "";
}

.bp4-icon-swap-horizontal:before {
  content: "";
}

.bp4-icon-numbered-list:before {
  content: "";
}

.bp4-icon-new-grid-item:before {
  content: "";
}

.bp4-icon-git-repo:before {
  content: "";
}

.bp4-icon-git-new-branch:before {
  content: "";
}

.bp4-icon-manually-entered-data:before {
  content: "";
}

.bp4-icon-airplane:before {
  content: "";
}

.bp4-icon-merge-columns:before {
  content: "";
}

.bp4-icon-split-columns:before {
  content: "";
}

.bp4-icon-dashboard:before {
  content: "";
}

.bp4-icon-publish-function:before {
  content: "";
}

.bp4-icon-path:before {
  content: "";
}

.bp4-icon-moon:before {
  content: "";
}

.bp4-icon-remove-column:before {
  content: "";
}

.bp4-icon-numerical:before {
  content: "";
}

.bp4-icon-key-tab:before {
  content: "";
}

.bp4-icon-regression-chart:before {
  content: "";
}

.bp4-icon-translate:before {
  content: "";
}

.bp4-icon-eye-on:before {
  content: "";
}

.bp4-icon-vertical-bar-chart-asc:before {
  content: "";
}

.bp4-icon-horizontal-bar-chart-asc:before {
  content: "";
}

.bp4-icon-grouped-bar-chart:before {
  content: "";
}

.bp4-icon-full-stacked-chart:before {
  content: "";
}

.bp4-icon-endorsed:before {
  content: "";
}

.bp4-icon-follower:before {
  content: "";
}

.bp4-icon-following:before {
  content: "";
}

.bp4-icon-menu:before {
  content: "";
}

.bp4-icon-collapse-all:before {
  content: "";
}

.bp4-icon-expand-all:before {
  content: "";
}

.bp4-icon-intersection:before {
  content: "";
}

.bp4-icon-blocked-person:before {
  content: "";
}

.bp4-icon-slash:before {
  content: "";
}

.bp4-icon-percentage:before {
  content: "";
}

.bp4-icon-satellite:before {
  content: "";
}

.bp4-icon-paragraph:before {
  content: "";
}

.bp4-icon-bank-account:before {
  content: "";
}

.bp4-icon-cell-tower:before {
  content: "";
}

.bp4-icon-id-number:before {
  content: "";
}

.bp4-icon-ip-address:before {
  content: "";
}

.bp4-icon-eraser:before {
  content: "";
}

.bp4-icon-issue:before {
  content: "";
}

.bp4-icon-issue-new:before {
  content: "";
}

.bp4-icon-issue-closed:before {
  content: "";
}

.bp4-icon-panel-stats:before {
  content: "";
}

.bp4-icon-panel-table:before {
  content: "";
}

.bp4-icon-tick-circle:before {
  content: "";
}

.bp4-icon-prescription:before {
  content: "";
}

.bp4-icon-new-prescription:before {
  content: "";
}

.bp4-icon-filter-keep:before {
  content: "";
}

.bp4-icon-filter-remove:before {
  content: "";
}

.bp4-icon-key:before {
  content: "";
}

.bp4-icon-feed-subscribed:before {
  content: "";
}

.bp4-icon-widget-button:before {
  content: "";
}

.bp4-icon-widget-header:before {
  content: "";
}

.bp4-icon-widget-footer:before {
  content: "";
}

.bp4-icon-header-one:before {
  content: "";
}

.bp4-icon-header-two:before {
  content: "";
}

.bp4-icon-form:before {
  content: "";
}

.bp4-icon-series-add:before {
  content: "";
}

.bp4-icon-series-search:before {
  content: "";
}

.bp4-icon-series-filtered:before {
  content: "";
}

.bp4-icon-series-derived:before {
  content: "";
}

.bp4-icon-series-configuration:before {
  content: "";
}

.bp4-icon-console:before {
  content: "";
}

.bp4-icon-compass:before {
  content: "";
}

.bp4-icon-walk:before {
  content: "";
}

.bp4-icon-taxi:before {
  content: "";
}

.bp4-icon-train:before {
  content: "";
}

.bp4-icon-heart-broken:before {
  content: "";
}

.bp4-icon-inner-join:before {
  content: "";
}

.bp4-icon-left-join:before {
  content: "";
}

.bp4-icon-right-join:before {
  content: "";
}

.bp4-icon-strikethrough:before {
  content: "";
}

.bp4-icon-updated:before {
  content: "";
}

.bp4-icon-outdated:before {
  content: "";
}

.bp4-icon-flame:before {
  content: "";
}

.bp4-icon-folder-new:before {
  content: "";
}

.bp4-icon-mountain:before {
  content: "";
}

.bp4-icon-shield:before {
  content: "";
}

.bp4-icon-diagram-tree:before {
  content: "";
}

.bp4-icon-crown:before {
  content: "";
}

.bp4-icon-globe-network:before {
  content: "";
}

.bp4-icon-snowflake:before {
  content: "";
}

.bp4-icon-tree:before {
  content: "";
}

.bp4-icon-notifications-updated:before {
  content: "";
}

.bp4-icon-list-columns:before {
  content: "";
}

.bp4-icon-flow-linear:before {
  content: "";
}

.bp4-icon-flow-branch:before {
  content: "";
}

.bp4-icon-flow-review:before {
  content: "";
}

.bp4-icon-flow-review-branch:before {
  content: "";
}

.bp4-icon-flow-end:before {
  content: "";
}

.bp4-icon-clean:before {
  content: "";
}

.bp4-icon-th-filtered:before {
  content: "";
}

.bp4-icon-lifesaver:before {
  content: "";
}

.bp4-icon-cube:before {
  content: "";
}

.bp4-icon-cube-add:before {
  content: "";
}

.bp4-icon-cube-remove:before {
  content: "";
}

.bp4-icon-inbox-filtered:before {
  content: "";
}

.bp4-icon-inbox-geo:before {
  content: "";
}

.bp4-icon-inbox-search:before {
  content: "";
}

.bp4-icon-inbox-update:before {
  content: "";
}

.bp4-icon-inheritance:before {
  content: "";
}

.bp4-icon-reset:before {
  content: "";
}

.bp4-icon-filter-open:before {
  content: "";
}

.bp4-icon-th-disconnect:before {
  content: "";
}

.bp4-icon-equals:before {
  content: "";
}

.bp4-icon-not-equal-to:before {
  content: "";
}

.bp4-icon-greater-than:before {
  content: "";
}

.bp4-icon-greater-than-or-equal-to:before {
  content: "";
}

.bp4-icon-less-than:before {
  content: "";
}

.bp4-icon-less-than-or-equal-to:before {
  content: "";
}

.bp4-icon-learning:before {
  content: "";
}

.bp4-icon-new-layer:before {
  content: "";
}

.bp4-icon-new-layers:before {
  content: "";
}

.bp4-icon-stopwatch:before {
  content: "";
}

.bp4-icon-archive:before {
  content: "";
}

.bp4-icon-unarchive:before {
  content: "";
}

.bp4-icon-data-lineage:before {
  content: "";
}

.bp4-icon-new-drawing:before {
  content: "";
}

.bp4-icon-signal-search:before {
  content: "";
}

.bp4-icon-bring-data:before {
  content: "";
}

.bp4-icon-tractor:before {
  content: "";
}

.bp4-icon-truck:before {
  content: "";
}

.bp4-icon-diagnosis:before {
  content: "";
}

.bp4-icon-lab-test:before {
  content: "";
}

.bp4-icon-virus:before {
  content: "";
}

.bp4-icon-inherited-group:before {
  content: "";
}

.bp4-icon-hat:before {
  content: "";
}

.bp4-icon-cycle:before {
  content: "";
}

.bp4-icon-route:before {
  content: "";
}

.bp4-icon-modal:before {
  content: "";
}

.bp4-icon-modal-filled:before {
  content: "";
}

.bp4-icon-drawer-left:before {
  content: "";
}

.bp4-icon-drawer-left-filled:before {
  content: "";
}

.bp4-icon-drawer-right:before {
  content: "";
}

.bp4-icon-drawer-right-filled:before {
  content: "";
}

.bp4-icon-app-header:before {
  content: "";
}

.bp4-icon-send-message:before {
  content: "";
}

.bp4-icon-backlink:before {
  content: "";
}

.bp4-icon-geofence:before {
  content: "";
}

.bp4-icon-data-connection:before {
  content: "";
}

.bp4-icon-switch:before {
  content: "";
}

.bp4-icon-array:before {
  content: "";
}

.bp4-icon-array-boolean:before {
  content: "";
}

.bp4-icon-array-date:before {
  content: "";
}

.bp4-icon-array-numeric:before {
  content: "";
}

.bp4-icon-array-string:before {
  content: "";
}

.bp4-icon-array-timestamp:before {
  content: "";
}

.bp4-icon-layer-outline:before {
  content: "";
}

.bp4-icon-notifications-snooze:before {
  content: "";
}

.bp4-icon-high-priority:before {
  content: "";
}

.bp4-icon-emoji:before {
  content: "";
}

.bp4-icon-add-location:before {
  content: "";
}

.bp4-icon-shapes:before {
  content: "";
}

.bp4-icon-shared-filter:before {
  content: "";
}

.bp4-icon-one-to-one:before {
  content: "";
}

.bp4-icon-one-to-many:before {
  content: "";
}

.bp4-icon-many-to-one:before {
  content: "";
}

.bp4-icon-many-to-many:before {
  content: "";
}

.bp4-icon-stadium-geometry:before {
  content: "";
}

.bp4-icon-area-of-interest:before {
  content: "";
}

.bp4-icon-buggy:before {
  content: "";
}

.bp4-icon-antenna:before {
  content: "";
}

.bp4-icon-tank:before {
  content: "";
}

.bp4-icon-third-party:before {
  content: "";
}

.bp4-icon-rocket:before {
  content: "";
}

.bp4-icon-rocket-slant:before {
  content: "";
}

.bp4-icon-header-three:before {
  content: "";
}

.bp4-icon-helicopter:before {
  content: "";
}

.bp4-icon-syringe:before {
  content: "";
}

.bp4-icon-temperature:before {
  content: "";
}

.bp4-icon-waves:before {
  content: "";
}

.bp4-icon-rain:before {
  content: "";
}

.bp4-icon-lightning:before {
  content: "";
}

.bp4-icon-wind:before {
  content: "";
}

.bp4-icon-hurricane:before {
  content: "";
}

.bp4-icon-nest:before {
  content: "";
}

.bp4-icon-target:before {
  content: "";
}

.bp4-icon-small-square:before {
  content: "";
}

.bp4-icon-ship:before {
  content: "";
}

.bp4-icon-cargo-ship:before {
  content: "";
}

.bp4-icon-clip:before {
  content: "";
}

.bp4-icon-add-clip:before {
  content: "";
}

.bp4-icon-rectangle:before {
  content: "";
}

.bp4-icon-symbol-rectangle:before {
  content: "";
}

.bp4-icon-fuel:before {
  content: "";
}

.bp4-icon-playbook:before {
  content: "";
}

.bp4-icon-rect-height:before {
  content: "";
}

.bp4-icon-rect-width:before {
  content: "";
}

.bp4-icon-divide:before {
  content: "";
}

.bp4-icon-color-fill:before {
  content: "";
}

.bp4-icon-horizontal-inbetween:before {
  content: "";
}

.bp4-icon-vertical-inbetween:before {
  content: "";
}

.bp4-icon-open-application:before {
  content: "";
}

.bp4-heading {
  color: #1c2127;
  margin: 0 0 10px;
  padding: 0;
  font-weight: 600;
}

.bp4-dark .bp4-heading {
  color: #f6f7f9;
}

.bp4-running-text h1, h1.bp4-heading {
  font-size: 36px;
  line-height: 40px;
}

.bp4-running-text h2, h2.bp4-heading {
  font-size: 28px;
  line-height: 32px;
}

.bp4-running-text h3, h3.bp4-heading {
  font-size: 22px;
  line-height: 25px;
}

.bp4-running-text h4, h4.bp4-heading {
  font-size: 18px;
  line-height: 21px;
}

.bp4-running-text h5, h5.bp4-heading {
  font-size: 16px;
  line-height: 19px;
}

.bp4-running-text h6, h6.bp4-heading {
  font-size: 14px;
  line-height: 16px;
}

.bp4-ui-text {
  letter-spacing: 0;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.28581;
}

.bp4-monospace-text {
  text-transform: none;
  font-family: monospace;
}

.bp4-text-muted {
  color: #5f6b7c;
}

.bp4-dark .bp4-text-muted {
  color: #abb3bf;
}

.bp4-text-disabled {
  color: #5f6b7c99;
}

.bp4-dark .bp4-text-disabled {
  color: #abb3bf99;
}

.bp4-text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.bp4-running-text {
  font-size: 14px;
  line-height: 1.5;
}

.bp4-running-text h1 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp4-dark .bp4-running-text h1 {
  color: #f6f7f9;
}

.bp4-running-text h2 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp4-dark .bp4-running-text h2 {
  color: #f6f7f9;
}

.bp4-running-text h3 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp4-dark .bp4-running-text h3 {
  color: #f6f7f9;
}

.bp4-running-text h4 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp4-dark .bp4-running-text h4 {
  color: #f6f7f9;
}

.bp4-running-text h5 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp4-dark .bp4-running-text h5 {
  color: #f6f7f9;
}

.bp4-running-text h6 {
  color: #1c2127;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 600;
}

.bp4-dark .bp4-running-text h6 {
  color: #f6f7f9;
}

.bp4-running-text hr {
  border: none;
  border-bottom: 1px solid #11141826;
  margin: 20px 0;
}

.bp4-dark .bp4-running-text hr {
  border-color: #ffffff26;
}

.bp4-running-text p {
  margin: 0 0 10px;
  padding: 0;
}

.bp4-text-large {
  font-size: 16px;
}

.bp4-text-small {
  font-size: 12px;
}

a {
  color: #215db0;
  text-decoration: none;
}

a:hover {
  color: #215db0;
  cursor: pointer;
  text-decoration: underline;
}

a .bp4-icon, a .bp4-icon-standard, a .bp4-icon-large, a code, .bp4-dark a code {
  color: inherit;
}

.bp4-dark a, .bp4-dark a:hover {
  color: #8abbff;
}

.bp4-dark a .bp4-icon, .bp4-dark a .bp4-icon-standard, .bp4-dark a .bp4-icon-large, .bp4-dark a:hover .bp4-icon, .bp4-dark a:hover .bp4-icon-standard, .bp4-dark a:hover .bp4-icon-large {
  color: inherit;
}

.bp4-code, .bp4-running-text code {
  text-transform: none;
  color: #5f6b7c;
  background: #ffffffb3;
  border-radius: 3px;
  padding: 2px 5px;
  font-family: monospace;
  font-size: smaller;
  box-shadow: inset 0 0 0 1px #11141833;
}

.bp4-dark .bp4-code, .bp4-dark .bp4-running-text code, .bp4-running-text .bp4-dark code {
  color: #abb3bf;
  background: #1114184d;
  box-shadow: inset 0 0 0 1px #11141866;
}

a > .bp4-code, .bp4-running-text a > code {
  color: #2d72d2;
}

.bp4-dark a > .bp4-code, .bp4-dark .bp4-running-text a > code, .bp4-running-text .bp4-dark a > code {
  color: inherit;
}

.bp4-code-block, .bp4-running-text pre {
  text-transform: none;
  color: #1c2127;
  word-break: break-all;
  word-wrap: break-word;
  background: #ffffffb3;
  border-radius: 3px;
  margin: 10px 0;
  padding: 13px 15px 12px;
  font-family: monospace;
  font-size: 13px;
  line-height: 1.4;
  display: block;
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp4-dark .bp4-code-block, .bp4-dark .bp4-running-text pre, .bp4-running-text .bp4-dark pre {
  color: #f6f7f9;
  background: #1114184d;
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp4-code-block > code, .bp4-running-text pre > code {
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  background: none;
  padding: 0;
}

.bp4-key, .bp4-running-text kbd {
  color: #5f6b7c;
  height: 24px;
  min-width: 24px;
  vertical-align: middle;
  background: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
  font-family: inherit;
  font-size: 12px;
  line-height: 24px;
  display: inline-flex;
  box-shadow: 0 0 0 1px #1114181a, 0 0 #11141800, 0 1px 1px #11141833;
}

.bp4-key .bp4-icon, .bp4-running-text kbd .bp4-icon, .bp4-key .bp4-icon-standard, .bp4-running-text kbd .bp4-icon-standard, .bp4-key .bp4-icon-large, .bp4-running-text kbd .bp4-icon-large {
  margin-right: 5px;
}

.bp4-dark .bp4-key, .bp4-dark .bp4-running-text kbd, .bp4-running-text .bp4-dark kbd {
  color: #abb3bf;
  background: #404854;
  box-shadow: 0 0 0 1px #11141833, 0 0 #11141800, 0 1px 1px #11141866;
}

.bp4-blockquote, .bp4-running-text blockquote {
  border-left: 4px solid #abb3bf80;
  margin: 0 0 10px;
  padding: 0 20px;
}

.bp4-dark .bp4-blockquote, .bp4-dark .bp4-running-text blockquote, .bp4-running-text .bp4-dark blockquote {
  border-color: #73809180;
}

.bp4-list, .bp4-running-text ul, .bp4-running-text ol {
  margin: 10px 0;
  padding-left: 30px;
}

.bp4-list li:not(:last-child), .bp4-running-text ul li:not(:last-child), .bp4-running-text ol li:not(:last-child) {
  margin-bottom: 5px;
}

.bp4-list ol, .bp4-running-text ul ol, .bp4-running-text ol ol, .bp4-list ul, .bp4-running-text ul ul, .bp4-running-text ol ul {
  margin-top: 5px;
}

.bp4-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bp4-list-unstyled li {
  padding: 0;
}

.bp4-rtl {
  text-align: right;
}

.bp4-dark {
  color: #f6f7f9;
}

:focus {
  outline-offset: 2px;
  -moz-outline-radius: 6px;
  outline: 2px auto #2d72d299;
}

.bp4-focus-disabled :focus:not(.bp4-focus-style-manager-ignore *), .bp4-focus-disabled :focus:not(.bp4-focus-style-manager-ignore *) ~ .bp4-control-indicator {
  outline: none !important;
}

.bp4-dark {
  color-scheme: dark;
}

.bp4-alert {
  max-width: 400px;
  padding: 20px;
}

.bp4-alert-body {
  display: flex;
}

.bp4-alert-body .bp4-icon {
  margin-top: 0;
  margin-right: 20px;
  font-size: 40px;
}

.bp4-alert-contents {
  word-break: break-word;
}

.bp4-alert-footer {
  flex-direction: row-reverse;
  margin-top: 10px;
  display: flex;
}

.bp4-alert-footer .bp4-button {
  margin-left: 10px;
}

.bp4-breadcrumbs {
  cursor: default;
  height: 30px;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.bp4-breadcrumbs > li {
  align-items: center;
  display: flex;
}

.bp4-breadcrumbs > li:after {
  content: "";
  height: 16px;
  width: 16px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%235F6B7C'/%3e%3c/svg%3e");
  margin: 0 5px;
  display: block;
}

.bp4-breadcrumbs > li:last-of-type:after {
  display: none;
}

.bp4-breadcrumb, .bp4-breadcrumb-current, .bp4-breadcrumbs-collapsed {
  align-items: center;
  font-size: 16px;
  display: inline-flex;
}

.bp4-breadcrumb, .bp4-breadcrumbs-collapsed {
  color: #5f6b7c;
}

.bp4-breadcrumb:hover {
  text-decoration: none;
}

.bp4-breadcrumb.bp4-disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp4-breadcrumb .bp4-icon {
  margin-right: 5px;
}

.bp4-breadcrumb-current {
  color: inherit;
  font-weight: 600;
}

.bp4-breadcrumb-current .bp4-input {
  font-size: inherit;
  font-weight: inherit;
  vertical-align: baseline;
}

.bp4-breadcrumbs-collapsed {
  cursor: pointer;
  vertical-align: text-bottom;
  background: #d3d8de;
  border: none;
  border-radius: 3px;
  margin-right: 2px;
  padding: 1px 5px;
}

.bp4-breadcrumbs-collapsed:before {
  content: "";
  height: 16px;
  width: 16px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%235F6B7C'%3e%3ccircle cx='2' cy='8.03' r='2'/%3e%3ccircle cx='14' cy='8.03' r='2'/%3e%3ccircle cx='8' cy='8.03' r='2'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
  display: block;
}

.bp4-breadcrumbs-collapsed:hover {
  color: #1c2127;
  background: #c5cbd3;
  text-decoration: none;
}

.bp4-dark .bp4-breadcrumb, .bp4-dark .bp4-breadcrumbs-collapsed, .bp4-dark .bp4-breadcrumbs > li:after {
  color: #abb3bf;
}

.bp4-dark .bp4-breadcrumb.bp4-disabled {
  color: #abb3bf99;
}

.bp4-dark .bp4-breadcrumb-current {
  color: #f6f7f9;
}

.bp4-dark .bp4-breadcrumbs-collapsed {
  background: #11141866;
}

.bp4-dark .bp4-breadcrumbs-collapsed:hover {
  color: #f6f7f9;
  background: #11141899;
}

.bp4-button {
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  min-height: 30px;
  min-width: 30px;
  border: none;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  font-size: 14px;
  display: inline-flex;
}

.bp4-button > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-button > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-button:before, .bp4-button > * {
  margin-right: 7px;
}

.bp4-button:empty:before, .bp4-button > :last-child {
  margin-right: 0;
}

.bp4-button:empty {
  padding: 0 !important;
}

.bp4-button:disabled, .bp4-button.bp4-disabled {
  cursor: not-allowed;
}

.bp4-button.bp4-fill {
  width: 100%;
  display: flex;
}

.bp4-button.bp4-align-right, .bp4-align-right .bp4-button {
  text-align: right;
}

.bp4-button.bp4-align-left, .bp4-align-left .bp4-button {
  text-align: left;
}

.bp4-button:not([class*="bp4-intent-"]) {
  color: #1c2127;
  background-color: #f6f7f9;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-button:not([class*="bp4-intent-"]):hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-button:not([class*="bp4-intent-"]):active, .bp4-button:not([class*="bp4-intent-"]).bp4-active {
  background-color: #dce0e5;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 1px 2px #11141833;
}

.bp4-button:not([class*="bp4-intent-"]):disabled, .bp4-button:not([class*="bp4-intent-"]).bp4-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  background-image: none;
  outline: none;
}

.bp4-button:not([class*="bp4-intent-"]):disabled.bp4-active, .bp4-button:not([class*="bp4-intent-"]):disabled.bp4-active:hover, .bp4-button:not([class*="bp4-intent-"]).bp4-disabled.bp4-active, .bp4-button:not([class*="bp4-intent-"]).bp4-disabled.bp4-active:hover {
  background: #d3d8deb3;
}

.bp4-button.bp4-intent-primary {
  color: #fff;
  background-color: #2d72d2;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-primary:hover, .bp4-button.bp4-intent-primary:active, .bp4-button.bp4-intent-primary.bp4-active {
  color: #fff;
}

.bp4-button.bp4-intent-primary:hover {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-primary:active, .bp4-button.bp4-intent-primary.bp4-active {
  background-color: #184a90;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 1px 2px #11141833;
}

.bp4-button.bp4-intent-primary:disabled, .bp4-button.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #2d72d280;
  background-image: none;
  border-color: #0000;
}

.bp4-button.bp4-intent-success {
  color: #fff;
  background-color: #238551;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-success:hover, .bp4-button.bp4-intent-success:active, .bp4-button.bp4-intent-success.bp4-active {
  color: #fff;
}

.bp4-button.bp4-intent-success:hover {
  background-color: #1c6e42;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-success:active, .bp4-button.bp4-intent-success.bp4-active {
  background-color: #165a36;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 1px 2px #11141833;
}

.bp4-button.bp4-intent-success:disabled, .bp4-button.bp4-intent-success.bp4-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #23855180;
  background-image: none;
  border-color: #0000;
}

.bp4-button.bp4-intent-warning {
  color: #fff;
  background-color: #c87619;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-warning:hover, .bp4-button.bp4-intent-warning:active, .bp4-button.bp4-intent-warning.bp4-active {
  color: #fff;
}

.bp4-button.bp4-intent-warning:hover {
  background-color: #935610;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-warning:active, .bp4-button.bp4-intent-warning.bp4-active {
  background-color: #77450d;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 1px 2px #11141833;
}

.bp4-button.bp4-intent-warning:disabled, .bp4-button.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #c8761980;
  background-image: none;
  border-color: #0000;
}

.bp4-button.bp4-intent-danger {
  color: #fff;
  background-color: #cd4246;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-danger:hover, .bp4-button.bp4-intent-danger:active, .bp4-button.bp4-intent-danger.bp4-active {
  color: #fff;
}

.bp4-button.bp4-intent-danger:hover {
  background-color: #ac2f33;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 -1px #11141833;
}

.bp4-button.bp4-intent-danger:active, .bp4-button.bp4-intent-danger.bp4-active {
  background-color: #8e292c;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141866, inset 0 1px 2px #11141833;
}

.bp4-button.bp4-intent-danger:disabled, .bp4-button.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
  color: #fff9;
  background-color: #cd424680;
  background-image: none;
  border-color: #0000;
}

.bp4-button[class*="bp4-intent-"] .bp4-button-spinner .bp4-spinner-head {
  stroke: #fff;
}

.bp4-button.bp4-large, .bp4-large .bp4-button {
  min-height: 40px;
  min-width: 40px;
  padding: 5px 15px;
  font-size: 16px;
}

.bp4-button.bp4-large:before, .bp4-button.bp4-large > *, .bp4-large .bp4-button:before, .bp4-large .bp4-button > * {
  margin-right: 10px;
}

.bp4-button.bp4-large:empty:before, .bp4-button.bp4-large > :last-child, .bp4-large .bp4-button:empty:before, .bp4-large .bp4-button > :last-child {
  margin-right: 0;
}

.bp4-button.bp4-small, .bp4-small .bp4-button {
  min-height: 24px;
  min-width: 24px;
  padding: 0 7px;
}

.bp4-button.bp4-loading {
  position: relative;
}

.bp4-button.bp4-loading[class*="bp4-icon-"]:before {
  visibility: hidden;
}

.bp4-button.bp4-loading .bp4-button-spinner {
  margin: 0;
  position: absolute;
}

.bp4-button.bp4-loading > :not(.bp4-button-spinner) {
  visibility: hidden;
}

.bp4-button[class*="bp4-icon-"]:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5f6b7c;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp4-button .bp4-icon, .bp4-button .bp4-icon-standard, .bp4-button .bp4-icon-large {
  color: #5f6b7c;
}

.bp4-button .bp4-icon.bp4-align-right, .bp4-button .bp4-icon-standard.bp4-align-right, .bp4-button .bp4-icon-large.bp4-align-right {
  margin-left: 7px;
}

.bp4-button .bp4-icon:first-child:last-child, .bp4-button .bp4-spinner + .bp4-icon:last-child {
  margin: 0 -7px;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]) {
  color: #f6f7f9;
  background-color: #404854;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):hover, .bp4-dark .bp4-button:not([class*="bp4-intent-"]):active, .bp4-dark .bp4-button:not([class*="bp4-intent-"]).bp4-active {
  color: #f6f7f9;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):hover {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):active, .bp4-dark .bp4-button:not([class*="bp4-intent-"]).bp4-active {
  background-color: #252a31;
  background-image: none;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):disabled, .bp4-dark .bp4-button:not([class*="bp4-intent-"]).bp4-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
  background-image: none;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]):disabled.bp4-active, .bp4-dark .bp4-button:not([class*="bp4-intent-"]).bp4-disabled.bp4-active {
  background: #404854b3;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"]) .bp4-button-spinner .bp4-spinner-head {
  stroke: #8f99a8;
  background: #11141880;
}

.bp4-dark .bp4-button:not([class*="bp4-intent-"])[class*="bp4-icon-"]:before, .bp4-dark .bp4-button:not([class*="bp4-intent-"]) .bp4-icon:not([class*="bp4-intent-"]), .bp4-dark .bp4-button:not([class*="bp4-intent-"]) .bp4-icon-standard:not([class*="bp4-intent-"]), .bp4-dark .bp4-button:not([class*="bp4-intent-"]) .bp4-icon-large:not([class*="bp4-intent-"]) {
  color: #abb3bf;
}

.bp4-dark .bp4-button[class*="bp4-intent-"], .bp4-dark .bp4-button[class*="bp4-intent-"]:hover, .bp4-dark .bp4-button[class*="bp4-intent-"]:active, .bp4-dark .bp4-button[class*="bp4-intent-"].bp4-active {
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-button[class*="bp4-intent-"]:disabled, .bp4-dark .bp4-button[class*="bp4-intent-"].bp4-disabled {
  box-shadow: none;
  color: #ffffff4d;
  background-image: none;
}

.bp4-dark .bp4-button[class*="bp4-intent-"] .bp4-button-spinner .bp4-spinner-head {
  stroke: #8f99a8;
}

.bp4-button:disabled:before, .bp4-button:disabled .bp4-icon, .bp4-button:disabled .bp4-icon-standard, .bp4-button:disabled .bp4-icon-large, .bp4-button.bp4-disabled:before, .bp4-button.bp4-disabled .bp4-icon, .bp4-button.bp4-disabled .bp4-icon-standard, .bp4-button.bp4-disabled .bp4-icon-large, .bp4-button[class*="bp4-intent-"]:before, .bp4-button[class*="bp4-intent-"] .bp4-icon, .bp4-button[class*="bp4-intent-"] .bp4-icon-standard, .bp4-button[class*="bp4-intent-"] .bp4-icon-large {
  color: inherit !important;
}

.bp4-button.bp4-minimal {
  box-shadow: none;
  background: none;
}

.bp4-button.bp4-minimal:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp4-button.bp4-minimal:active, .bp4-button.bp4-minimal.bp4-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp4-button.bp4-minimal:disabled, .bp4-button.bp4-minimal:disabled:hover, .bp4-button.bp4-minimal.bp4-disabled, .bp4-button.bp4-minimal.bp4-disabled:hover {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp4-button.bp4-minimal:disabled.bp4-active, .bp4-button.bp4-minimal:disabled:hover.bp4-active, .bp4-button.bp4-minimal.bp4-disabled.bp4-active, .bp4-button.bp4-minimal.bp4-disabled:hover.bp4-active {
  background: #8f99a84d;
}

.bp4-dark .bp4-button.bp4-minimal {
  box-shadow: none;
  color: inherit;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal:hover, .bp4-dark .bp4-button.bp4-minimal:active, .bp4-dark .bp4-button.bp4-minimal.bp4-active {
  box-shadow: none;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal:hover {
  background: #8f99a826;
}

.bp4-dark .bp4-button.bp4-minimal:active, .bp4-dark .bp4-button.bp4-minimal.bp4-active {
  color: #f6f7f9;
  background: #8f99a84d;
}

.bp4-dark .bp4-button.bp4-minimal:disabled, .bp4-dark .bp4-button.bp4-minimal:disabled:hover, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled:hover {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal:disabled:hover.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-disabled:hover.bp4-active {
  background: #8f99a84d;
}

.bp4-button.bp4-minimal.bp4-intent-primary {
  color: #215db0;
}

.bp4-button.bp4-minimal.bp4-intent-primary:hover, .bp4-button.bp4-minimal.bp4-intent-primary:active, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp4-button.bp4-minimal.bp4-intent-primary:active, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-active {
  color: #215db0;
  background: #2d72d24d;
}

.bp4-button.bp4-minimal.bp4-intent-primary:disabled, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled {
  color: #215db080;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-primary:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-button.bp4-minimal.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary {
  color: #8abbff;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary.bp4-active {
  color: #8abbff;
  background: #2d72d24d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled {
  color: #8abbff80;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-button.bp4-minimal.bp4-intent-success {
  color: #1c6e42;
}

.bp4-button.bp4-minimal.bp4-intent-success:hover, .bp4-button.bp4-minimal.bp4-intent-success:active, .bp4-button.bp4-minimal.bp4-intent-success.bp4-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp4-button.bp4-minimal.bp4-intent-success:active, .bp4-button.bp4-minimal.bp4-intent-success.bp4-active {
  color: #1c6e42;
  background: #2385514d;
}

.bp4-button.bp4-minimal.bp4-intent-success:disabled, .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled {
  color: #1c6e4280;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-success:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-button.bp4-minimal.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success {
  color: #72ca9b;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-success.bp4-active {
  color: #72ca9b;
  background: #2385514d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled {
  color: #72ca9b80;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-button.bp4-minimal.bp4-intent-warning {
  color: #935610;
}

.bp4-button.bp4-minimal.bp4-intent-warning:hover, .bp4-button.bp4-minimal.bp4-intent-warning:active, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp4-button.bp4-minimal.bp4-intent-warning:active, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-active {
  color: #935610;
  background: #c876194d;
}

.bp4-button.bp4-minimal.bp4-intent-warning:disabled, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled {
  color: #93561080;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-warning:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-button.bp4-minimal.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning {
  color: #fbb360;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning.bp4-active {
  color: #fbb360;
  background: #c876194d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled {
  color: #fbb36080;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-button.bp4-minimal.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-button.bp4-minimal.bp4-intent-danger:hover, .bp4-button.bp4-minimal.bp4-intent-danger:active, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp4-button.bp4-minimal.bp4-intent-danger:active, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-active {
  color: #ac2f33;
  background: #cd42464d;
}

.bp4-button.bp4-minimal.bp4-intent-danger:disabled, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled {
  color: #ac2f3380;
  background: none;
}

.bp4-button.bp4-minimal.bp4-intent-danger:disabled.bp4-active, .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-button.bp4-minimal.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger {
  color: #fa999c;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger.bp4-active {
  color: #fa999c;
  background: #cd42464d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:disabled, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled {
  color: #fa999c80;
  background: none;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-button.bp4-outlined {
  box-shadow: none;
  box-sizing: border-box;
  background: none;
  border: 1px solid #1c212733;
}

.bp4-button.bp4-outlined:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp4-button.bp4-outlined:active, .bp4-button.bp4-outlined.bp4-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp4-button.bp4-outlined:disabled, .bp4-button.bp4-outlined:disabled:hover, .bp4-button.bp4-outlined.bp4-disabled, .bp4-button.bp4-outlined.bp4-disabled:hover {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp4-button.bp4-outlined:disabled.bp4-active, .bp4-button.bp4-outlined:disabled:hover.bp4-active, .bp4-button.bp4-outlined.bp4-disabled.bp4-active, .bp4-button.bp4-outlined.bp4-disabled:hover.bp4-active {
  background: #8f99a84d;
}

.bp4-dark .bp4-button.bp4-outlined {
  box-shadow: none;
  color: inherit;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined:hover, .bp4-dark .bp4-button.bp4-outlined:active, .bp4-dark .bp4-button.bp4-outlined.bp4-active {
  box-shadow: none;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined:hover {
  background: #8f99a826;
}

.bp4-dark .bp4-button.bp4-outlined:active, .bp4-dark .bp4-button.bp4-outlined.bp4-active {
  color: #f6f7f9;
  background: #8f99a84d;
}

.bp4-dark .bp4-button.bp4-outlined:disabled, .bp4-dark .bp4-button.bp4-outlined:disabled:hover, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled:hover {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined:disabled:hover.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled:hover.bp4-active {
  background: #8f99a84d;
}

.bp4-button.bp4-outlined.bp4-intent-primary {
  color: #215db0;
}

.bp4-button.bp4-outlined.bp4-intent-primary:hover, .bp4-button.bp4-outlined.bp4-intent-primary:active, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp4-button.bp4-outlined.bp4-intent-primary:active, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-active {
  color: #215db0;
  background: #2d72d24d;
}

.bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  color: #215db080;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-primary:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-button.bp4-outlined.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary {
  color: #8abbff;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-active {
  color: #8abbff;
  background: #2d72d24d;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  color: #8abbff80;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-button.bp4-outlined.bp4-intent-success {
  color: #1c6e42;
}

.bp4-button.bp4-outlined.bp4-intent-success:hover, .bp4-button.bp4-outlined.bp4-intent-success:active, .bp4-button.bp4-outlined.bp4-intent-success.bp4-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp4-button.bp4-outlined.bp4-intent-success:active, .bp4-button.bp4-outlined.bp4-intent-success.bp4-active {
  color: #1c6e42;
  background: #2385514d;
}

.bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  color: #1c6e4280;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-success:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-button.bp4-outlined.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success {
  color: #72ca9b;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-active {
  color: #72ca9b;
  background: #2385514d;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  color: #72ca9b80;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-button.bp4-outlined.bp4-intent-warning {
  color: #935610;
}

.bp4-button.bp4-outlined.bp4-intent-warning:hover, .bp4-button.bp4-outlined.bp4-intent-warning:active, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp4-button.bp4-outlined.bp4-intent-warning:active, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-active {
  color: #935610;
  background: #c876194d;
}

.bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  color: #93561080;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-warning:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-button.bp4-outlined.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning {
  color: #fbb360;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-active {
  color: #fbb360;
  background: #c876194d;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  color: #fbb36080;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-button.bp4-outlined.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-button.bp4-outlined.bp4-intent-danger:hover, .bp4-button.bp4-outlined.bp4-intent-danger:active, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp4-button.bp4-outlined.bp4-intent-danger:active, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-active {
  color: #ac2f33;
  background: #cd42464d;
}

.bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  color: #ac2f3380;
  background: none;
}

.bp4-button.bp4-outlined.bp4-intent-danger:disabled.bp4-active, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-button.bp4-outlined.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger {
  color: #fa999c;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-active {
  color: #fa999c;
  background: #cd42464d;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  color: #fa999c80;
  background: none;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-button.bp4-outlined:disabled, .bp4-button.bp4-outlined.bp4-disabled, .bp4-button.bp4-outlined:disabled:hover, .bp4-button.bp4-outlined.bp4-disabled:hover {
  border-color: #5f6b7c1a;
}

.bp4-dark .bp4-button.bp4-outlined {
  border-color: #fff6;
}

.bp4-dark .bp4-button.bp4-outlined:disabled, .bp4-dark .bp4-button.bp4-outlined:disabled:hover, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-disabled:hover {
  border-color: #fff3;
}

.bp4-button.bp4-outlined.bp4-intent-primary {
  border-color: #215db099;
}

.bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  border-color: #215db033;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary {
  border-color: #8abbff99;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-primary.bp4-disabled {
  border-color: #8abbff33;
}

.bp4-button.bp4-outlined.bp4-intent-success {
  border-color: #1c6e4299;
}

.bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  border-color: #1c6e4233;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success {
  border-color: #72ca9b99;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-success:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-success.bp4-disabled {
  border-color: #72ca9b33;
}

.bp4-button.bp4-outlined.bp4-intent-warning {
  border-color: #93561099;
}

.bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  border-color: #93561033;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning {
  border-color: #fbb36099;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-warning.bp4-disabled {
  border-color: #fbb36033;
}

.bp4-button.bp4-outlined.bp4-intent-danger {
  border-color: #ac2f3399;
}

.bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  border-color: #ac2f3333;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger {
  border-color: #fa999c99;
}

.bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger:disabled, .bp4-dark .bp4-button.bp4-outlined.bp4-intent-danger.bp4-disabled {
  border-color: #fa999c33;
}

a.bp4-button {
  text-align: center;
  text-decoration: none;
  transition: none;
}

a.bp4-button, a.bp4-button:hover, a.bp4-button:active {
  color: #1c2127;
}

a.bp4-button.bp4-disabled {
  color: #5f6b7c99;
}

.bp4-button-text {
  flex: 0 auto;
}

.bp4-button.bp4-align-left .bp4-button-text, .bp4-button.bp4-align-right .bp4-button-text, .bp4-button-group.bp4-align-left .bp4-button-text, .bp4-button-group.bp4-align-right .bp4-button-text {
  flex: auto;
}

.bp4-button-group {
  display: inline-flex;
}

.bp4-button-group .bp4-button {
  z-index: 4;
  flex: none;
  position: relative;
}

.bp4-button-group .bp4-button:focus {
  z-index: 5;
}

.bp4-button-group .bp4-button:hover {
  z-index: 6;
}

.bp4-button-group .bp4-button:active, .bp4-button-group .bp4-button.bp4-active {
  z-index: 7;
}

.bp4-button-group .bp4-button:disabled, .bp4-button-group .bp4-button.bp4-disabled {
  z-index: 3;
}

.bp4-button-group .bp4-button[class*="bp4-intent-"] {
  z-index: 9;
}

.bp4-button-group .bp4-button[class*="bp4-intent-"]:focus {
  z-index: 10;
}

.bp4-button-group .bp4-button[class*="bp4-intent-"]:hover {
  z-index: 11;
}

.bp4-button-group .bp4-button[class*="bp4-intent-"]:active, .bp4-button-group .bp4-button[class*="bp4-intent-"].bp4-active {
  z-index: 12;
}

.bp4-button-group .bp4-button[class*="bp4-intent-"]:disabled, .bp4-button-group .bp4-button[class*="bp4-intent-"].bp4-disabled {
  z-index: 8;
}

.bp4-button-group:not(.bp4-minimal) > .bp4-popover-wrapper:not(:first-child) .bp4-button, .bp4-button-group:not(.bp4-minimal) > .bp4-button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp4-button-group:not(.bp4-minimal) > .bp4-popover-wrapper:not(:last-child) .bp4-button, .bp4-button-group:not(.bp4-minimal) > .bp4-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.bp4-button-group.bp4-minimal .bp4-button {
  box-shadow: none;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp4-button-group.bp4-minimal .bp4-button:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp4-button-group.bp4-minimal .bp4-button:disabled, .bp4-button-group.bp4-minimal .bp4-button:disabled:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button:disabled:hover.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover.bp4-active {
  background: #8f99a84d;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button {
  box-shadow: none;
  color: inherit;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:hover, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-active {
  box-shadow: none;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:hover {
  background: #8f99a826;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-active {
  color: #f6f7f9;
  background: #8f99a84d;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled:hover, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button:disabled:hover.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-disabled:hover.bp4-active {
  background: #8f99a84d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary {
  color: #215db0;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-active {
  color: #215db0;
  background: #2d72d24d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled {
  color: #215db080;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary {
  color: #8abbff;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-active {
  color: #8abbff;
  background: #2d72d24d;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled {
  color: #8abbff80;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success {
  color: #1c6e42;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-active {
  color: #1c6e42;
  background: #2385514d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled {
  color: #1c6e4280;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success {
  color: #72ca9b;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-active {
  color: #72ca9b;
  background: #2385514d;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled {
  color: #72ca9b80;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning {
  color: #935610;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-active {
  color: #935610;
  background: #c876194d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled {
  color: #93561080;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning {
  color: #fbb360;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-active {
  color: #fbb360;
  background: #c876194d;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled {
  color: #fbb36080;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:hover, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-active {
  color: #ac2f33;
  background: #cd42464d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled {
  color: #ac2f3380;
  background: none;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled.bp4-active, .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger {
  color: #fa999c;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-active {
  color: #fa999c;
  background: #cd42464d;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled {
  color: #fa999c80;
  background: none;
}

.bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-button-group.bp4-minimal .bp4-button.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-button-group .bp4-popover-wrapper, .bp4-button-group .bp4-popover-target {
  flex: auto;
  display: flex;
}

.bp4-button-group.bp4-fill {
  width: 100%;
  display: flex;
}

.bp4-button-group .bp4-button.bp4-fill, .bp4-button-group.bp4-fill .bp4-button:not(.bp4-fixed) {
  flex: auto;
}

.bp4-button-group.bp4-vertical {
  vertical-align: top;
  flex-direction: column;
  align-items: stretch;
}

.bp4-button-group.bp4-vertical.bp4-fill {
  height: 100%;
  width: unset;
}

.bp4-button-group.bp4-vertical .bp4-button {
  width: 100%;
  margin-right: 0 !important;
}

.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover-wrapper:first-child .bp4-button, .bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-button:first-child {
  border-radius: 3px 3px 0 0;
}

.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover-wrapper:last-child .bp4-button, .bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-button:last-child {
  border-radius: 0 0 3px 3px;
}

.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover-wrapper:not(:last-child) .bp4-button, .bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-button:not(:last-child) {
  margin-bottom: -1px;
}

.bp4-button-group.bp4-align-left .bp4-button {
  text-align: left;
}

.bp4-callout {
  width: 100%;
  background-color: #8f99a826;
  border-radius: 3px;
  padding: 10px 12px 9px;
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}

.bp4-callout[class*="bp4-icon-"] {
  padding-left: 40px;
}

.bp4-callout[class*="bp4-icon-"]:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #5f6b7c;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: 10px;
  left: 10px;
}

.bp4-callout.bp4-callout-icon {
  padding-left: 40px;
}

.bp4-callout.bp4-callout-icon > .bp4-icon:first-child {
  color: #5f6b7c;
  position: absolute;
  top: 10px;
  left: 10px;
}

.bp4-callout .bp4-heading {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 20px;
}

.bp4-callout .bp4-heading:last-child {
  margin-bottom: 0;
}

.bp4-dark .bp4-callout {
  background-color: #8f99a833;
}

.bp4-dark .bp4-callout[class*="bp4-icon-"]:before {
  color: #abb3bf;
}

.bp4-callout.bp4-intent-primary {
  background-color: #2d72d226;
}

.bp4-callout.bp4-intent-primary[class*="bp4-icon-"]:before, .bp4-callout.bp4-intent-primary > .bp4-icon:first-child, .bp4-callout.bp4-intent-primary .bp4-heading {
  color: #215db0;
}

.bp4-dark .bp4-callout.bp4-intent-primary {
  background-color: #2d72d240;
}

.bp4-dark .bp4-callout.bp4-intent-primary[class*="bp4-icon-"]:before, .bp4-dark .bp4-callout.bp4-intent-primary > .bp4-icon:first-child, .bp4-dark .bp4-callout.bp4-intent-primary .bp4-heading {
  color: #8abbff;
}

.bp4-callout.bp4-intent-success {
  background-color: #23855126;
}

.bp4-callout.bp4-intent-success[class*="bp4-icon-"]:before, .bp4-callout.bp4-intent-success > .bp4-icon:first-child, .bp4-callout.bp4-intent-success .bp4-heading {
  color: #1c6e42;
}

.bp4-dark .bp4-callout.bp4-intent-success {
  background-color: #23855140;
}

.bp4-dark .bp4-callout.bp4-intent-success[class*="bp4-icon-"]:before, .bp4-dark .bp4-callout.bp4-intent-success > .bp4-icon:first-child, .bp4-dark .bp4-callout.bp4-intent-success .bp4-heading {
  color: #72ca9b;
}

.bp4-callout.bp4-intent-warning {
  background-color: #c8761926;
}

.bp4-callout.bp4-intent-warning[class*="bp4-icon-"]:before, .bp4-callout.bp4-intent-warning > .bp4-icon:first-child, .bp4-callout.bp4-intent-warning .bp4-heading {
  color: #935610;
}

.bp4-dark .bp4-callout.bp4-intent-warning {
  background-color: #c8761940;
}

.bp4-dark .bp4-callout.bp4-intent-warning[class*="bp4-icon-"]:before, .bp4-dark .bp4-callout.bp4-intent-warning > .bp4-icon:first-child, .bp4-dark .bp4-callout.bp4-intent-warning .bp4-heading {
  color: #fbb360;
}

.bp4-callout.bp4-intent-danger {
  background-color: #cd424626;
}

.bp4-callout.bp4-intent-danger[class*="bp4-icon-"]:before, .bp4-callout.bp4-intent-danger > .bp4-icon:first-child, .bp4-callout.bp4-intent-danger .bp4-heading {
  color: #ac2f33;
}

.bp4-dark .bp4-callout.bp4-intent-danger {
  background-color: #cd424640;
}

.bp4-dark .bp4-callout.bp4-intent-danger[class*="bp4-icon-"]:before, .bp4-dark .bp4-callout.bp4-intent-danger > .bp4-icon:first-child, .bp4-dark .bp4-callout.bp4-intent-danger .bp4-heading {
  color: #fa999c;
}

.bp4-running-text .bp4-callout {
  margin: 20px 0;
}

.bp4-card {
  background-color: #fff;
  border-radius: 3px;
  padding: 20px;
  transition: transform .2s cubic-bezier(.4, 1, .75, .9), box-shadow .2s cubic-bezier(.4, 1, .75, .9);
  box-shadow: 0 0 0 1px #11141826, 0 0 #11141800, 0 0 #11141800;
}

.bp4-card.bp4-dark, .bp4-dark .bp4-card {
  background-color: #383e47;
  box-shadow: 0 0 0 1px #11141866, 0 0 #11141800, 0 0 #11141800;
}

.bp4-elevation-0 {
  box-shadow: 0 0 0 1px #11141826, 0 0 #11141800, 0 0 #11141800;
}

.bp4-elevation-0.bp4-dark, .bp4-dark .bp4-elevation-0 {
  box-shadow: 0 0 0 1px #11141866, 0 0 #11141800, 0 0 #11141800;
}

.bp4-elevation-1 {
  box-shadow: 0 0 0 1px #1114181a, 0 0 #11141800, 0 1px 1px #11141833;
}

.bp4-elevation-1.bp4-dark, .bp4-dark .bp4-elevation-1 {
  box-shadow: 0 0 0 1px #11141833, 0 0 #11141800, 0 1px 1px #11141866;
}

.bp4-elevation-2 {
  box-shadow: 0 0 0 1px #1114181a, 0 1px 1px #11141833, 0 2px 6px #11141833;
}

.bp4-elevation-2.bp4-dark, .bp4-dark .bp4-elevation-2 {
  box-shadow: 0 0 0 1px #11141833, 0 1px 1px #11141866, 0 2px 6px #11141866;
}

.bp4-elevation-3 {
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-elevation-3.bp4-dark, .bp4-dark .bp4-elevation-3 {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-elevation-4 {
  box-shadow: 0 0 0 1px #1114181a, 0 4px 8px #11141833, 0 18px 46px 6px #11141833;
}

.bp4-elevation-4.bp4-dark, .bp4-dark .bp4-elevation-4 {
  box-shadow: 0 0 0 1px #11141833, 0 4px 8px #11141866, 0 18px 46px 6px #11141866;
}

.bp4-card.bp4-interactive:hover {
  cursor: pointer;
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-card.bp4-interactive:hover.bp4-dark, .bp4-dark .bp4-card.bp4-interactive:hover {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-card.bp4-interactive:active {
  opacity: .9;
  transition-duration: 0;
  box-shadow: 0 0 0 1px #1114181a, 0 0 #11141800, 0 1px 1px #11141833;
}

.bp4-card.bp4-interactive:active.bp4-dark, .bp4-dark .bp4-card.bp4-interactive:active {
  box-shadow: 0 0 0 1px #11141833, 0 0 #11141800, 0 1px 1px #11141866;
}

.bp4-collapse {
  height: 0;
  transition: height .2s cubic-bezier(.4, 1, .75, .9);
  overflow-y: hidden;
}

.bp4-collapse .bp4-collapse-body {
  transition: transform .2s cubic-bezier(.4, 1, .75, .9);
}

.bp4-collapse .bp4-collapse-body[aria-hidden="true"] {
  display: none;
}

.bp4-context-menu .bp4-popover-target {
  display: block;
}

.bp4-context-menu-popover-target {
  position: fixed;
}

.bp4-divider {
  border-bottom: 1px solid #11141826;
  border-right: 1px solid #11141826;
  margin: 5px;
}

.bp4-dark .bp4-divider {
  border-color: #11141866;
}

.bp4-dialog-container {
  opacity: 1;
  min-height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  transform: scale(1);
}

.bp4-dialog-container.bp4-overlay-enter > .bp4-dialog, .bp4-dialog-container.bp4-overlay-appear > .bp4-dialog {
  opacity: 0;
  transform: scale(.5);
}

.bp4-dialog-container.bp4-overlay-enter-active > .bp4-dialog, .bp4-dialog-container.bp4-overlay-appear-active > .bp4-dialog {
  opacity: 1;
  transition-delay: 0;
  transition-property: opacity, transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(1);
}

.bp4-dialog-container.bp4-overlay-exit > .bp4-dialog {
  opacity: 1;
  transform: scale(1);
}

.bp4-dialog-container.bp4-overlay-exit-active > .bp4-dialog {
  opacity: 0;
  transition-delay: 0;
  transition-property: opacity, transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.5);
}

.bp4-dialog {
  pointer-events: all;
  -webkit-user-select: text;
  user-select: text;
  width: 500px;
  background: #edeff2;
  border-radius: 6px;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
  display: flex;
  box-shadow: 0 0 0 1px #1114181a, 0 4px 8px #11141833, 0 18px 46px 6px #11141833;
}

.bp4-dialog:focus {
  outline: none;
}

.bp4-dialog.bp4-dark, .bp4-dark .bp4-dialog {
  color: #f6f7f9;
  background: #2f343c;
  box-shadow: 0 0 0 1px #11141833, 0 4px 8px #11141866, 0 18px 46px 6px #11141866;
}

.bp4-dialog-header {
  min-height: 40px;
  z-index: 0;
  background: #fff;
  border-radius: 6px 6px 0 0;
  flex: none;
  align-items: center;
  padding-left: 20px;
  padding-right: 5px;
  display: flex;
  box-shadow: 0 1px #11141826;
}

.bp4-dialog-header .bp4-icon-large, .bp4-dialog-header .bp4-icon {
  color: #5f6b7c;
  flex: none;
  margin-right: 10px;
}

.bp4-dialog-header .bp4-heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: inherit;
  flex: auto;
  margin: 0;
  overflow: hidden;
}

.bp4-dialog-header .bp4-heading:last-child {
  margin-right: 20px;
}

.bp4-dark .bp4-dialog-header {
  background: #383e47;
  box-shadow: 0 1px #11141866;
}

.bp4-dark .bp4-dialog-header .bp4-icon-large, .bp4-dark .bp4-dialog-header .bp4-icon {
  color: #abb3bf;
}

.bp4-dialog-body {
  flex: auto;
  margin: 20px;
  line-height: 18px;
}

.bp4-dialog-footer {
  flex: none;
  margin: 0 20px;
}

.bp4-dialog-footer-actions {
  justify-content: flex-end;
  display: flex;
}

.bp4-dialog-footer-actions .bp4-button {
  margin-left: 10px;
}

.bp4-multistep-dialog-panels {
  display: flex;
}

.bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:first-child {
  border-radius: 6px 0 0;
}

.bp4-multistep-dialog-panels:first-child .bp4-multistep-dialog-right-panel {
  border-top-right-radius: 6px;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels {
  flex-direction: column;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:first-child {
  border-radius: 6px 0 0;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:last-child {
  border-radius: 0 6px 0 0;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-left-panel {
  flex-direction: row;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-dialog-step-container {
  flex-grow: 1;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-dialog-step-container:not(:first-child) {
  border-left: 1px solid #11141826;
}

.bp4-dark .bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-dialog-step-container {
  border-color: #11141866;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel {
  border-left: none;
}

.bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel, .bp4-multistep-dialog-nav-top .bp4-multistep-dialog-panels .bp4-multistep-dialog-footer {
  border-radius: 0 0 6px 6px;
}

.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels {
  flex-direction: row-reverse;
}

.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels:first-child .bp4-multistep-dialog-right-panel {
  border-radius: 6px 0 0 6px;
}

.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels:first-child .bp4-dialog-step-container:first-child {
  border-radius: 0 6px 0 0;
}

.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-left-panel {
  border-radius: 0 0 6px;
}

.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel {
  border-left: none;
  border-right: 1px solid #11141826;
  border-radius: 6px 0 0 6px;
}

.bp4-dark .bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-right-panel {
  border-color: #11141866;
}

.bp4-multistep-dialog-nav-right .bp4-multistep-dialog-panels .bp4-multistep-dialog-footer {
  border-radius: 0 0 0 6px;
}

.bp4-multistep-dialog-left-panel {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.bp4-dark .bp4-multistep-dialog-left-panel {
  background: #252a31;
}

.bp4-multistep-dialog-right-panel {
  min-width: 0;
  background-color: #f6f7f9;
  border-left: 1px solid #11141826;
  border-radius: 0 0 6px;
  flex: 3;
}

.bp4-dark .bp4-multistep-dialog-right-panel {
  background-color: #2f343c;
  border-left: 1px solid #11141866;
}

.bp4-multistep-dialog-footer {
  background-color: #fff;
  border-top: 1px solid #11141826;
  border-radius: 0 0 6px;
  justify-content: space-between;
  padding: 10px;
  display: flex;
}

.bp4-dark .bp4-multistep-dialog-footer {
  background: #383e47;
  border-top: 1px solid #11141866;
}

.bp4-multistep-dialog-footer .bp4-dialog-footer-actions {
  flex-grow: 1;
}

.bp4-dialog-step-container {
  background-color: #f6f7f9;
  border-bottom: 1px solid #11141826;
}

.bp4-dark .bp4-dialog-step-container {
  background: #2f343c;
  border-bottom: 1px solid #11141866;
}

.bp4-dialog-step-container.bp4-dialog-step-viewed {
  background-color: #fff;
}

.bp4-dark .bp4-dialog-step-container.bp4-dialog-step-viewed {
  background: #383e47;
}

.bp4-dialog-step {
  cursor: not-allowed;
  background-color: #f6f7f9;
  border-radius: 6px;
  align-items: center;
  margin: 4px;
  padding: 6px 14px;
  display: flex;
}

.bp4-dark .bp4-dialog-step {
  background: #2f343c;
}

.bp4-dialog-step-viewed .bp4-dialog-step {
  cursor: pointer;
  background-color: #fff;
}

.bp4-dark .bp4-dialog-step-viewed .bp4-dialog-step {
  background: #383e47;
}

.bp4-dialog-step:hover {
  background-color: #f6f7f9;
}

.bp4-dark .bp4-dialog-step:hover {
  background: #2f343c;
}

.bp4-dialog-step-icon {
  color: #fff;
  height: 25px;
  width: 25px;
  background-color: #5f6b7c99;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bp4-dark .bp4-dialog-step-icon {
  background-color: #abb3bf99;
}

.bp4-active.bp4-dialog-step-viewed .bp4-dialog-step-icon {
  background-color: #4c90f0;
}

.bp4-dialog-step-viewed .bp4-dialog-step-icon {
  background-color: #8f99a8;
}

.bp4-dialog-step-title {
  color: #5f6b7c99;
  flex: 1;
  padding-left: 10px;
}

.bp4-dark .bp4-dialog-step-title {
  color: #abb3bf99;
}

.bp4-active.bp4-dialog-step-viewed .bp4-dialog-step-title {
  color: #4c90f0;
}

.bp4-dialog-step-viewed:not(.bp4-active) .bp4-dialog-step-title {
  color: #1c2127;
}

.bp4-dark .bp4-dialog-step-viewed:not(.bp4-active) .bp4-dialog-step-title {
  color: #f6f7f9;
}

.bp4-drawer {
  background: #fff;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  box-shadow: 0 0 0 1px #1114181a, 0 4px 8px #11141833, 0 18px 46px 6px #11141833;
}

.bp4-drawer:focus {
  outline: none;
}

.bp4-drawer.bp4-position-top {
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
}

.bp4-drawer.bp4-position-top.bp4-overlay-enter, .bp4-drawer.bp4-position-top.bp4-overlay-appear {
  transform: translateY(-100%);
}

.bp4-drawer.bp4-position-top.bp4-overlay-enter-active, .bp4-drawer.bp4-position-top.bp4-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(0);
}

.bp4-drawer.bp4-position-top.bp4-overlay-exit {
  transform: translateY(0);
}

.bp4-drawer.bp4-position-top.bp4-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(-100%);
}

.bp4-drawer.bp4-position-bottom {
  height: 50%;
  bottom: 0;
  left: 0;
  right: 0;
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-enter, .bp4-drawer.bp4-position-bottom.bp4-overlay-appear {
  transform: translateY(100%);
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-enter-active, .bp4-drawer.bp4-position-bottom.bp4-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(0);
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-exit {
  transform: translateY(0);
}

.bp4-drawer.bp4-position-bottom.bp4-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateY(100%);
}

.bp4-drawer.bp4-position-left {
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
}

.bp4-drawer.bp4-position-left.bp4-overlay-enter, .bp4-drawer.bp4-position-left.bp4-overlay-appear {
  transform: translateX(-100%);
}

.bp4-drawer.bp4-position-left.bp4-overlay-enter-active, .bp4-drawer.bp4-position-left.bp4-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(0);
}

.bp4-drawer.bp4-position-left.bp4-overlay-exit {
  transform: translateX(0);
}

.bp4-drawer.bp4-position-left.bp4-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(-100%);
}

.bp4-drawer.bp4-position-right {
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
}

.bp4-drawer.bp4-position-right.bp4-overlay-enter, .bp4-drawer.bp4-position-right.bp4-overlay-appear {
  transform: translateX(100%);
}

.bp4-drawer.bp4-position-right.bp4-overlay-enter-active, .bp4-drawer.bp4-position-right.bp4-overlay-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(0);
}

.bp4-drawer.bp4-position-right.bp4-overlay-exit {
  transform: translateX(0);
}

.bp4-drawer.bp4-position-right.bp4-overlay-exit-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: translateX(100%);
}

.bp4-drawer.bp4-dark, .bp4-dark .bp4-drawer {
  color: #f6f7f9;
  background: #383e47;
  box-shadow: 0 0 0 1px #11141833, 0 4px 8px #11141866, 0 18px 46px 6px #11141866;
}

.bp4-drawer-header {
  min-height: 40px;
  border-radius: 0;
  flex: none;
  align-items: center;
  padding: 5px 5px 5px 20px;
  display: flex;
  position: relative;
  box-shadow: 0 1px #11141826;
}

.bp4-drawer-header .bp4-icon-large, .bp4-drawer-header .bp4-icon {
  color: #5f6b7c;
  flex: none;
  margin-right: 10px;
}

.bp4-drawer-header .bp4-heading {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  line-height: inherit;
  flex: auto;
  margin: 0;
  overflow: hidden;
}

.bp4-drawer-header .bp4-heading:last-child {
  margin-right: 20px;
}

.bp4-dark .bp4-drawer-header {
  box-shadow: 0 1px #11141866;
}

.bp4-dark .bp4-drawer-header .bp4-icon-large, .bp4-dark .bp4-drawer-header .bp4-icon {
  color: #abb3bf;
}

.bp4-drawer-body {
  flex: auto;
  line-height: 18px;
  overflow: auto;
}

.bp4-drawer-footer {
  flex: none;
  padding: 10px 20px;
  position: relative;
  box-shadow: inset 0 1px #11141826;
}

.bp4-dark .bp4-drawer-footer {
  box-shadow: inset 0 1px #11141866;
}

.bp4-editable-text {
  cursor: text;
  max-width: 100%;
  vertical-align: top;
  white-space: nowrap;
  display: inline-block;
  position: relative;
}

.bp4-editable-text:before {
  content: "";
  border-radius: 3px;
  transition: background-color .1s cubic-bezier(.4, 1, .75, .9), box-shadow .1s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  inset: -3px;
}

.bp4-editable-text:hover:before {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #11141826;
}

.bp4-editable-text.bp4-editable-text-editing:before {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-editable-text.bp4-disabled:before {
  box-shadow: none;
}

.bp4-editable-text.bp4-intent-primary .bp4-editable-text-input, .bp4-editable-text.bp4-intent-primary .bp4-editable-text-content {
  color: #2d72d2;
}

.bp4-editable-text.bp4-intent-primary:hover:before {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #2d72d266;
}

.bp4-editable-text.bp4-intent-primary.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-editable-text.bp4-intent-success .bp4-editable-text-input, .bp4-editable-text.bp4-intent-success .bp4-editable-text-content {
  color: #238551;
}

.bp4-editable-text.bp4-intent-success:hover:before {
  box-shadow: 0 0 #23855100, 0 0 #23855100, inset 0 0 0 1px #23855166;
}

.bp4-editable-text.bp4-intent-success.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp4-editable-text.bp4-intent-warning .bp4-editable-text-input, .bp4-editable-text.bp4-intent-warning .bp4-editable-text-content {
  color: #c87619;
}

.bp4-editable-text.bp4-intent-warning:hover:before {
  box-shadow: 0 0 #c8761900, 0 0 #c8761900, inset 0 0 0 1px #c8761966;
}

.bp4-editable-text.bp4-intent-warning.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp4-editable-text.bp4-intent-danger .bp4-editable-text-input, .bp4-editable-text.bp4-intent-danger .bp4-editable-text-content {
  color: #cd4246;
}

.bp4-editable-text.bp4-intent-danger:hover:before {
  box-shadow: 0 0 #cd424600, 0 0 #cd424600, inset 0 0 0 1px #cd424666;
}

.bp4-editable-text.bp4-intent-danger.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp4-dark .bp4-editable-text:hover:before {
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #ffffff26;
}

.bp4-dark .bp4-editable-text.bp4-editable-text-editing:before {
  background-color: #1114184d;
  box-shadow: inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-editable-text.bp4-disabled:before {
  box-shadow: none;
}

.bp4-dark .bp4-editable-text.bp4-intent-primary .bp4-editable-text-content {
  color: #8abbff;
}

.bp4-dark .bp4-editable-text.bp4-intent-primary:hover:before {
  box-shadow: 0 0 #8abbff00, 0 0 #8abbff00, inset 0 0 0 1px #8abbff66;
}

.bp4-dark .bp4-editable-text.bp4-intent-primary.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #8abbff, 0 0 0 2px #8abbff4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-editable-text.bp4-intent-success .bp4-editable-text-content {
  color: #72ca9b;
}

.bp4-dark .bp4-editable-text.bp4-intent-success:hover:before {
  box-shadow: 0 0 #72ca9b00, 0 0 #72ca9b00, inset 0 0 0 1px #72ca9b66;
}

.bp4-dark .bp4-editable-text.bp4-intent-success.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #72ca9b, 0 0 0 2px #72ca9b4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-editable-text.bp4-intent-warning .bp4-editable-text-content {
  color: #fbb360;
}

.bp4-dark .bp4-editable-text.bp4-intent-warning:hover:before {
  box-shadow: 0 0 #fbb36000, 0 0 #fbb36000, inset 0 0 0 1px #fbb36066;
}

.bp4-dark .bp4-editable-text.bp4-intent-warning.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #fbb360, 0 0 0 2px #fbb3604d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-editable-text.bp4-intent-danger .bp4-editable-text-content {
  color: #fa999c;
}

.bp4-dark .bp4-editable-text.bp4-intent-danger:hover:before {
  box-shadow: 0 0 #fa999c00, 0 0 #fa999c00, inset 0 0 0 1px #fa999c66;
}

.bp4-dark .bp4-editable-text.bp4-intent-danger.bp4-editable-text-editing:before {
  box-shadow: inset 0 0 0 1px #fa999c, 0 0 0 2px #fa999c4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-editable-text-input, .bp4-editable-text-content {
  color: inherit;
  display: inherit;
  font: inherit;
  letter-spacing: inherit;
  max-width: inherit;
  min-width: inherit;
  resize: none;
  text-transform: inherit;
  vertical-align: top;
  position: relative;
}

.bp4-editable-text-input {
  box-shadow: none;
  white-space: pre-wrap;
  width: 100%;
  background: none;
  border: none;
  padding: 0;
}

.bp4-editable-text-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp4-editable-text-input:focus {
  outline: none;
}

.bp4-editable-text-input::-ms-clear {
  display: none;
}

.bp4-editable-text-content {
  text-overflow: ellipsis;
  white-space: pre;
  padding-right: 2px;
  overflow: hidden;
}

.bp4-editable-text-editing > .bp4-editable-text-content {
  visibility: hidden;
  position: absolute;
  left: 0;
}

.bp4-editable-text-placeholder > .bp4-editable-text-content {
  color: #5f6b7c;
}

.bp4-dark .bp4-editable-text-placeholder > .bp4-editable-text-content {
  color: #abb3bf;
}

.bp4-editable-text.bp4-multiline {
  display: block;
}

.bp4-editable-text.bp4-multiline .bp4-editable-text-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}

.bp4-divider {
  border-bottom: 1px solid #11141826;
  border-right: 1px solid #11141826;
  margin: 5px;
}

.bp4-dark .bp4-divider {
  border-color: #11141866;
}

.bp4-control-group {
  flex-direction: row;
  align-items: stretch;
  display: flex;
  transform: translateZ(0);
}

.bp4-control-group > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-control-group > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-control-group .bp4-button, .bp4-control-group .bp4-html-select, .bp4-control-group .bp4-input, .bp4-control-group .bp4-select {
  position: relative;
}

.bp4-control-group .bp4-input {
  z-index: 2;
}

.bp4-control-group .bp4-input:focus {
  z-index: 14;
}

.bp4-control-group .bp4-input[class*="bp4-intent"] {
  z-index: 13;
}

.bp4-control-group .bp4-input[class*="bp4-intent"]:focus {
  z-index: 15;
}

.bp4-control-group .bp4-input[readonly], .bp4-control-group .bp4-input:disabled, .bp4-control-group .bp4-input.bp4-disabled {
  z-index: 1;
}

.bp4-control-group .bp4-input-group[class*="bp4-intent"] .bp4-input {
  z-index: 13;
}

.bp4-control-group .bp4-input-group[class*="bp4-intent"] .bp4-input:focus {
  z-index: 15;
}

.bp4-control-group .bp4-button, .bp4-control-group .bp4-html-select select, .bp4-control-group .bp4-select select {
  z-index: 4;
  transform: translateZ(0);
}

.bp4-control-group .bp4-button:focus, .bp4-control-group .bp4-html-select select:focus, .bp4-control-group .bp4-select select:focus {
  z-index: 5;
}

.bp4-control-group .bp4-button:hover, .bp4-control-group .bp4-html-select select:hover, .bp4-control-group .bp4-select select:hover {
  z-index: 6;
}

.bp4-control-group .bp4-button:active, .bp4-control-group .bp4-html-select select:active, .bp4-control-group .bp4-select select:active {
  z-index: 7;
}

.bp4-control-group .bp4-button[readonly], .bp4-control-group .bp4-button:disabled, .bp4-control-group .bp4-button.bp4-disabled, .bp4-control-group .bp4-html-select select[readonly], .bp4-control-group .bp4-html-select select:disabled, .bp4-control-group .bp4-html-select select.bp4-disabled, .bp4-control-group .bp4-select select[readonly], .bp4-control-group .bp4-select select:disabled, .bp4-control-group .bp4-select select.bp4-disabled {
  z-index: 3;
}

.bp4-control-group .bp4-button[class*="bp4-intent"], .bp4-control-group .bp4-html-select select[class*="bp4-intent"], .bp4-control-group .bp4-select select[class*="bp4-intent"] {
  z-index: 9;
}

.bp4-control-group .bp4-button[class*="bp4-intent"]:focus, .bp4-control-group .bp4-html-select select[class*="bp4-intent"]:focus, .bp4-control-group .bp4-select select[class*="bp4-intent"]:focus {
  z-index: 10;
}

.bp4-control-group .bp4-button[class*="bp4-intent"]:hover, .bp4-control-group .bp4-html-select select[class*="bp4-intent"]:hover, .bp4-control-group .bp4-select select[class*="bp4-intent"]:hover {
  z-index: 11;
}

.bp4-control-group .bp4-button[class*="bp4-intent"]:active, .bp4-control-group .bp4-html-select select[class*="bp4-intent"]:active, .bp4-control-group .bp4-select select[class*="bp4-intent"]:active {
  z-index: 12;
}

.bp4-control-group .bp4-button[class*="bp4-intent"][readonly], .bp4-control-group .bp4-button[class*="bp4-intent"]:disabled, .bp4-control-group .bp4-button[class*="bp4-intent"].bp4-disabled, .bp4-control-group .bp4-html-select select[class*="bp4-intent"][readonly], .bp4-control-group .bp4-html-select select[class*="bp4-intent"]:disabled, .bp4-control-group .bp4-html-select select[class*="bp4-intent"].bp4-disabled, .bp4-control-group .bp4-select select[class*="bp4-intent"][readonly], .bp4-control-group .bp4-select select[class*="bp4-intent"]:disabled, .bp4-control-group .bp4-select select[class*="bp4-intent"].bp4-disabled {
  z-index: 8;
}

.bp4-control-group .bp4-input-group > .bp4-icon, .bp4-control-group .bp4-input-group > .bp4-button, .bp4-control-group .bp4-input-group > .bp4-input-left-container, .bp4-control-group .bp4-input-group > .bp4-input-action {
  z-index: 16;
}

.bp4-control-group .bp4-select:after, .bp4-control-group .bp4-html-select:after, .bp4-control-group .bp4-select > .bp4-icon, .bp4-control-group .bp4-html-select > .bp4-icon {
  z-index: 17;
}

.bp4-control-group .bp4-select:focus-within {
  z-index: 5;
}

.bp4-control-group:not(.bp4-vertical) > :not(:last-child) {
  margin-right: 2px;
}

.bp4-control-group .bp4-numeric-input:not(:first-child) .bp4-input-group {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp4-control-group.bp4-fill {
  width: 100%;
}

.bp4-control-group > .bp4-fill, .bp4-control-group.bp4-fill > :not(.bp4-fixed) {
  flex: auto;
}

.bp4-control-group.bp4-vertical {
  flex-direction: column;
}

.bp4-control-group.bp4-vertical > :not(:last-child) {
  margin-bottom: 2px;
}

.bp4-control {
  cursor: pointer;
  text-transform: none;
  margin-bottom: 10px;
  display: block;
  position: relative;
}

.bp4-control input:checked ~ .bp4-control-indicator {
  color: #fff;
  background-color: #2d72d2;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141833;
}

.bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: #215db0;
}

.bp4-control input:not(:disabled):active:checked ~ .bp4-control-indicator {
  background: #184a90;
}

.bp4-control input:disabled:checked ~ .bp4-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

.bp4-dark .bp4-control input:checked ~ .bp4-control-indicator {
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp4-dark .bp4-control:hover input:checked ~ .bp4-control-indicator {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp4-dark .bp4-control input:not(:disabled):active:checked ~ .bp4-control-indicator {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp4-dark .bp4-control input:disabled:checked ~ .bp4-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

.bp4-control:not(.bp4-align-right) {
  padding-left: 26px;
}

.bp4-control:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -26px;
}

.bp4-control.bp4-align-right {
  padding-right: 26px;
}

.bp4-control.bp4-align-right .bp4-control-indicator {
  margin-right: -26px;
}

.bp4-control.bp4-disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp4-control.bp4-inline {
  margin-right: 20px;
  display: inline-block;
}

.bp4-control input {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.bp4-control .bp4-control-indicator {
  cursor: pointer;
  height: 1em;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1em;
  background-color: #0000;
  background-image: linear-gradient(#11141800, #1114180d);
  background-clip: padding-box;
  border: none;
  margin-top: -3px;
  margin-right: 10px;
  font-size: 16px;
  display: inline-block;
  position: relative;
  box-shadow: inset 0 0 0 1px #738091;
}

.bp4-control .bp4-control-indicator:before {
  content: "";
  height: 1em;
  width: 1em;
  display: block;
}

.bp4-control:hover .bp4-control-indicator {
  background-color: #8f99a826;
}

.bp4-control input:not(:disabled):active ~ .bp4-control-indicator {
  background: #8f99a84d;
  box-shadow: inset 0 0 0 1px #738091;
}

.bp4-control input:disabled ~ .bp4-control-indicator {
  box-shadow: none;
  cursor: not-allowed;
  background: #8f99a826;
}

.bp4-control input:focus ~ .bp4-control-indicator {
  outline-offset: 2px;
  -moz-outline-radius: 6px;
  outline: 2px auto #2d72d2;
}

.bp4-control.bp4-align-right .bp4-control-indicator {
  float: right;
  margin-top: 1px;
  margin-left: 10px;
}

.bp4-control.bp4-large {
  font-size: 16px;
}

.bp4-control.bp4-large:not(.bp4-align-right) {
  padding-left: 30px;
}

.bp4-control.bp4-large:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -30px;
}

.bp4-control.bp4-large.bp4-align-right {
  padding-right: 30px;
}

.bp4-control.bp4-large.bp4-align-right .bp4-control-indicator {
  margin-right: -30px;
}

.bp4-control.bp4-large .bp4-control-indicator {
  font-size: 20px;
}

.bp4-control.bp4-large.bp4-align-right .bp4-control-indicator {
  margin-top: 0;
}

.bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator {
  color: #fff;
  background-color: #2d72d2;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #11141833;
}

.bp4-control.bp4-checkbox:hover input:indeterminate ~ .bp4-control-indicator {
  background-color: #215db0;
}

.bp4-control.bp4-checkbox input:not(:disabled):active:indeterminate ~ .bp4-control-indicator {
  background: #184a90;
}

.bp4-control.bp4-checkbox input:disabled:indeterminate ~ .bp4-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

.bp4-dark .bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator {
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp4-dark .bp4-control.bp4-checkbox:hover input:indeterminate ~ .bp4-control-indicator {
  background-color: #215db0;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp4-dark .bp4-control.bp4-checkbox input:not(:disabled):active:indeterminate ~ .bp4-control-indicator {
  background-color: #184a90;
  box-shadow: inset 0 0 0 1px #ffffff1a;
}

.bp4-dark .bp4-control.bp4-checkbox input:disabled:indeterminate ~ .bp4-control-indicator {
  box-shadow: none;
  color: #fff9;
  background: #2d72d280;
}

.bp4-control.bp4-checkbox .bp4-control-indicator {
  border-radius: 3px;
}

.bp4-control.bp4-checkbox input:checked ~ .bp4-control-indicator:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='white'/%3e%3c/svg%3e");
}

.bp4-control.bp4-checkbox input:indeterminate ~ .bp4-control-indicator:before {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 7H5c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1z' fill='white'/%3e%3c/svg%3e");
}

.bp4-control.bp4-radio .bp4-control-indicator {
  border-radius: 50%;
}

.bp4-control.bp4-radio input:checked ~ .bp4-control-indicator:before {
  background-image: radial-gradient(#fff, #fff 28%, #0000 32%);
}

.bp4-control.bp4-radio input:checked:disabled ~ .bp4-control-indicator:before {
  opacity: .5;
}

.bp4-control.bp4-radio input:focus ~ .bp4-control-indicator {
  -moz-outline-radius: 16px;
}

.bp4-control.bp4-switch input ~ .bp4-control-indicator {
  color: #1c2127;
  background: #8f99a84d;
}

.bp4-control.bp4-switch:hover input ~ .bp4-control-indicator {
  background: #8f99a866;
}

.bp4-control.bp4-switch input:not(:disabled):active ~ .bp4-control-indicator {
  background: #8f99a880;
}

.bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator {
  color: #5f6b7c99;
  background: #8f99a826;
}

.bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator:before {
  box-shadow: none;
  background: #fffc;
}

.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator {
  color: #fff;
  background: #2d72d2;
}

.bp4-control.bp4-switch:hover input:checked ~ .bp4-control-indicator {
  background: #215db0;
}

.bp4-control.bp4-switch input:checked:not(:disabled):active ~ .bp4-control-indicator {
  background: #184a90;
}

.bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator {
  color: #fff9;
  background: #2d72d280;
}

.bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator:before {
  box-shadow: none;
  background: #ffffff80;
}

.bp4-control.bp4-switch:not(.bp4-align-right) {
  padding-left: 38px;
}

.bp4-control.bp4-switch:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -38px;
}

.bp4-control.bp4-switch.bp4-align-right {
  padding-right: 38px;
}

.bp4-control.bp4-switch.bp4-align-right .bp4-control-indicator {
  margin-right: -38px;
}

.bp4-control.bp4-switch .bp4-control-indicator {
  min-width: 1.75em;
  width: auto;
  border: none;
  border-radius: 1.75em;
  transition: background-color .1s cubic-bezier(.4, 1, .75, .9);
  box-shadow: none !important;
}

.bp4-control.bp4-switch .bp4-control-indicator:before {
  height: calc(1em - 4px);
  width: calc(1em - 4px);
  background: #fff;
  border-radius: 50%;
  margin: 2px;
  transition: left .1s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  left: 0;
  box-shadow: 0 0 0 1px #11141880;
}

.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator:before {
  left: calc(100% - 1em);
}

.bp4-control.bp4-switch.bp4-large:not(.bp4-align-right) {
  padding-left: 45px;
}

.bp4-control.bp4-switch.bp4-large:not(.bp4-align-right) .bp4-control-indicator {
  margin-left: -45px;
}

.bp4-control.bp4-switch.bp4-large.bp4-align-right {
  padding-right: 45px;
}

.bp4-control.bp4-switch.bp4-large.bp4-align-right .bp4-control-indicator {
  margin-right: -45px;
}

.bp4-dark .bp4-control.bp4-switch input ~ .bp4-control-indicator {
  color: #f6f7f9;
  background: #11141880;
}

.bp4-dark .bp4-control.bp4-switch:hover input ~ .bp4-control-indicator {
  background: #111418cc;
}

.bp4-dark .bp4-control.bp4-switch input:not(:disabled):active ~ .bp4-control-indicator {
  background: #111418e6;
}

.bp4-dark .bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator {
  color: #abb3bf99;
  background: #8f99a826;
}

.bp4-dark .bp4-control.bp4-switch input:disabled ~ .bp4-control-indicator:before {
  box-shadow: none;
  background: #abb3bf80;
}

.bp4-dark .bp4-control.bp4-switch input:checked ~ .bp4-control-indicator {
  color: #fff;
  background: #2d72d2;
}

.bp4-dark .bp4-control.bp4-switch:hover input:checked ~ .bp4-control-indicator {
  background: #215db0;
}

.bp4-dark .bp4-control.bp4-switch input:checked:not(:disabled):active ~ .bp4-control-indicator {
  background: #184a90;
}

.bp4-dark .bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator {
  color: #abb3bf99;
  background: #2d72d280;
}

.bp4-dark .bp4-control.bp4-switch input:checked:disabled ~ .bp4-control-indicator:before {
  box-shadow: none;
  background: #ffffff4d;
}

.bp4-dark .bp4-control.bp4-switch .bp4-control-indicator:before {
  background: #abb3bf;
}

.bp4-dark .bp4-control.bp4-switch input:checked ~ .bp4-control-indicator:before {
  background: #fff;
}

.bp4-control.bp4-switch .bp4-switch-inner-text {
  text-align: center;
  font-size: .7em;
}

.bp4-control.bp4-switch .bp4-control-indicator-child:first-child {
  visibility: hidden;
  margin-left: .5em;
  margin-right: 1.2em;
  line-height: 0;
}

.bp4-control.bp4-switch .bp4-control-indicator-child:last-child {
  visibility: visible;
  margin-left: 1.2em;
  margin-right: .5em;
  line-height: 1em;
}

.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator .bp4-control-indicator-child:first-child {
  visibility: visible;
  line-height: 1em;
}

.bp4-control.bp4-switch input:checked ~ .bp4-control-indicator .bp4-control-indicator-child:last-child {
  visibility: hidden;
  line-height: 0;
}

.bp4-dark .bp4-control {
  color: #f6f7f9;
}

.bp4-dark .bp4-control.bp4-disabled {
  color: #abb3bf99;
}

.bp4-dark .bp4-control .bp4-control-indicator {
  background-color: #0000;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #8f99a8;
}

.bp4-dark .bp4-control:hover .bp4-control-indicator {
  background-color: #8f99a826;
}

.bp4-dark .bp4-control input:focus ~ .bp4-control-indicator {
  outline: 2px auto #8abbff;
}

.bp4-dark .bp4-control input:not(:disabled):active ~ .bp4-control-indicator {
  background: #8f99a84d;
  box-shadow: inset 0 0 0 1px #8f99a8;
}

.bp4-dark .bp4-control input:disabled ~ .bp4-control-indicator {
  box-shadow: none;
  cursor: not-allowed;
  background: #8f99a826;
}

.bp4-dark .bp4-control.bp4-checkbox input:disabled:checked ~ .bp4-control-indicator, .bp4-dark .bp4-control.bp4-checkbox input:disabled:indeterminate ~ .bp4-control-indicator {
  background: #2d72d280;
}

.bp4-file-input {
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: relative;
}

.bp4-file-input input {
  min-width: 200px;
  opacity: 0;
  margin: 0;
}

.bp4-file-input input:disabled + .bp4-file-upload-input, .bp4-file-input input.bp4-disabled + .bp4-file-upload-input {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  resize: none;
  background: #d3d8de80;
}

.bp4-file-input input:disabled + .bp4-file-upload-input::placeholder, .bp4-file-input input.bp4-disabled + .bp4-file-upload-input::placeholder {
  color: #5f6b7c99;
}

.bp4-file-input input:disabled + .bp4-file-upload-input:after, .bp4-file-input input.bp4-disabled + .bp4-file-upload-input:after {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  background-image: none;
  outline: none;
}

.bp4-dark .bp4-file-input input:disabled + .bp4-file-upload-input, .bp4-dark .bp4-file-input input.bp4-disabled + .bp4-file-upload-input {
  box-shadow: none;
  color: #abb3bf99;
  background: #40485480;
}

.bp4-dark .bp4-file-input input:disabled + .bp4-file-upload-input:after, .bp4-dark .bp4-file-input input.bp4-disabled + .bp4-file-upload-input:after {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
  background-image: none;
}

.bp4-file-input.bp4-file-input-has-selection .bp4-file-upload-input {
  color: #1c2127;
}

.bp4-dark .bp4-file-input.bp4-file-input-has-selection .bp4-file-upload-input {
  color: #f6f7f9;
}

.bp4-file-input.bp4-fill {
  width: 100%;
}

.bp4-file-input.bp4-large, .bp4-large .bp4-file-input {
  height: 40px;
}

.bp4-file-input .bp4-file-upload-input-custom-text:after {
  content: attr(bp4-button-text);
}

.bp4-file-upload-input {
  appearance: none;
  color: #1c2127;
  height: 30px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #5f6b7c99;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
  border: none;
  border-radius: 3px;
  outline: none;
  padding: 0 80px 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  transition: box-shadow .1s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-file-upload-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp4-file-upload-input:focus, .bp4-file-upload-input.bp4-active {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-file-upload-input[type="search"], .bp4-file-upload-input.bp4-round {
  box-sizing: border-box;
  border-radius: 30px;
  padding-left: 10px;
}

.bp4-file-upload-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp4-file-upload-input:disabled, .bp4-file-upload-input.bp4-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  resize: none;
  background: #d3d8de80;
}

.bp4-file-upload-input:disabled::placeholder, .bp4-file-upload-input.bp4-disabled::placeholder {
  color: #5f6b7c99;
}

.bp4-file-upload-input:after {
  color: #1c2127;
  min-height: 24px;
  min-width: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  content: "Browse";
  text-align: center;
  width: 70px;
  background-color: #f6f7f9;
  background-image: linear-gradient(#11141800, #1114180d);
  border-radius: 3px;
  margin: 3px;
  line-height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-file-upload-input:after:hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-file-upload-input:after:active, .bp4-file-upload-input.bp4-active:after {
  background-color: #dce0e5;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 1px 2px #11141833;
}

.bp4-file-upload-input:disabled:after, .bp4-disabled.bp4-file-upload-input:after {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  background-image: none;
  outline: none;
}

.bp4-active.bp4-file-upload-input:disabled:after, .bp4-active:hover.bp4-file-upload-input:disabled:after, .bp4-disabled.bp4-active.bp4-file-upload-input:after, .bp4-disabled.bp4-active:hover.bp4-file-upload-input:after {
  background: #d3d8deb3;
}

.bp4-file-upload-input:hover:after {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-file-upload-input:active:after {
  background-color: #dce0e5;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 1px 2px #11141833;
}

.bp4-large .bp4-file-upload-input {
  height: 40px;
  padding-right: 95px;
  font-size: 16px;
  line-height: 40px;
}

.bp4-large .bp4-file-upload-input[type="search"], .bp4-large .bp4-file-upload-input.bp4-round {
  padding: 0 15px;
}

.bp4-large .bp4-file-upload-input:after {
  min-height: 30px;
  min-width: 30px;
  width: 85px;
  margin: 5px;
  line-height: 30px;
}

.bp4-dark .bp4-file-upload-input {
  color: #f6f7f9;
  color: #abb3bf99;
  background: #1114184d;
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-file-upload-input::placeholder {
  color: #abb3bf;
}

.bp4-dark .bp4-file-upload-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d;
}

.bp4-dark .bp4-file-upload-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp4-dark .bp4-file-upload-input:disabled, .bp4-dark .bp4-file-upload-input.bp4-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background: #40485480;
}

.bp4-dark .bp4-file-upload-input:after {
  color: #f6f7f9;
  background-color: #404854;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-file-upload-input:after:hover, .bp4-dark .bp4-file-upload-input:after:active, .bp4-dark .bp4-active.bp4-file-upload-input:after {
  color: #f6f7f9;
}

.bp4-dark .bp4-file-upload-input:after:hover {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-file-upload-input:after:active, .bp4-dark .bp4-active.bp4-file-upload-input:after {
  background-color: #252a31;
  background-image: none;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-file-upload-input:disabled:after, .bp4-dark .bp4-disabled.bp4-file-upload-input:after {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
  background-image: none;
}

.bp4-dark .bp4-active.bp4-file-upload-input:disabled:after, .bp4-dark .bp4-disabled.bp4-active.bp4-file-upload-input:after {
  background: #404854b3;
}

.bp4-dark .bp4-file-upload-input .bp4-button-spinner .bp4-spinner-head {
  stroke: #8f99a8;
  background: #11141880;
}

.bp4-dark .bp4-file-upload-input:hover:after {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-file-upload-input:active:after {
  background-color: #252a31;
  background-image: none;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-file-upload-input:after {
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-form-group {
  flex-direction: column;
  margin: 0 0 15px;
  display: flex;
}

.bp4-form-group label.bp4-label {
  margin-bottom: 5px;
}

.bp4-form-group .bp4-control {
  margin-top: 7px;
}

.bp4-form-group .bp4-form-group-sub-label, .bp4-form-group .bp4-form-helper-text {
  color: #5f6b7c;
  font-size: 12px;
}

.bp4-form-group .bp4-form-group-sub-label {
  margin-bottom: 5px;
}

.bp4-form-group .bp4-form-helper-text {
  margin-top: 5px;
}

.bp4-form-group.bp4-intent-primary .bp4-form-group-sub-label, .bp4-form-group.bp4-intent-primary .bp4-form-helper-text {
  color: #215db0;
}

.bp4-form-group.bp4-intent-success .bp4-form-group-sub-label, .bp4-form-group.bp4-intent-success .bp4-form-helper-text {
  color: #1c6e42;
}

.bp4-form-group.bp4-intent-warning .bp4-form-group-sub-label, .bp4-form-group.bp4-intent-warning .bp4-form-helper-text {
  color: #935610;
}

.bp4-form-group.bp4-intent-danger .bp4-form-group-sub-label, .bp4-form-group.bp4-intent-danger .bp4-form-helper-text {
  color: #ac2f33;
}

.bp4-form-group.bp4-inline {
  flex-direction: row;
  align-items: flex-start;
}

.bp4-form-group.bp4-inline.bp4-large label.bp4-label {
  margin: 0 10px 0 0;
  line-height: 40px;
}

.bp4-form-group.bp4-inline label.bp4-label {
  margin: 0 10px 0 0;
  line-height: 30px;
}

.bp4-form-group.bp4-disabled .bp4-label, .bp4-form-group.bp4-disabled .bp4-text-muted, .bp4-form-group.bp4-disabled .bp4-form-group-sub-label, .bp4-form-group.bp4-disabled .bp4-form-helper-text {
  color: #5f6b7c99 !important;
}

.bp4-dark .bp4-form-group.bp4-intent-primary .bp4-form-group-sub-label, .bp4-dark .bp4-form-group.bp4-intent-primary .bp4-form-helper-text {
  color: #8abbff;
}

.bp4-dark .bp4-form-group.bp4-intent-success .bp4-form-group-sub-label, .bp4-dark .bp4-form-group.bp4-intent-success .bp4-form-helper-text {
  color: #72ca9b;
}

.bp4-dark .bp4-form-group.bp4-intent-warning .bp4-form-group-sub-label, .bp4-dark .bp4-form-group.bp4-intent-warning .bp4-form-helper-text {
  color: #fbb360;
}

.bp4-dark .bp4-form-group.bp4-intent-danger .bp4-form-group-sub-label, .bp4-dark .bp4-form-group.bp4-intent-danger .bp4-form-helper-text {
  color: #fa999c;
}

.bp4-dark .bp4-form-group .bp4-form-helper-text {
  color: #abb3bf;
}

.bp4-dark .bp4-form-group.bp4-disabled .bp4-label, .bp4-dark .bp4-form-group.bp4-disabled .bp4-text-muted, .bp4-dark .bp4-form-group.bp4-disabled .bp4-form-group-sub-label, .bp4-dark .bp4-form-group.bp4-disabled .bp4-form-helper-text {
  color: #abb3bf99 !important;
}

.bp4-input-group {
  display: block;
  position: relative;
}

.bp4-input-group .bp4-input {
  width: 100%;
  position: relative;
}

.bp4-input-group .bp4-input:not(:first-child) {
  padding-left: 30px;
}

.bp4-input-group .bp4-input:not(:last-child) {
  padding-right: 30px;
}

.bp4-input-group .bp4-input-action, .bp4-input-group > .bp4-input-left-container, .bp4-input-group > .bp4-button, .bp4-input-group > .bp4-icon {
  position: absolute;
  top: 0;
}

.bp4-input-group .bp4-input-action:first-child, .bp4-input-group > .bp4-input-left-container:first-child, .bp4-input-group > .bp4-button:first-child, .bp4-input-group > .bp4-icon:first-child {
  left: 0;
}

.bp4-input-group .bp4-input-action:last-child, .bp4-input-group > .bp4-input-left-container:last-child, .bp4-input-group > .bp4-button:last-child, .bp4-input-group > .bp4-icon:last-child {
  right: 0;
}

.bp4-input-group .bp4-button {
  min-height: 24px;
  min-width: 24px;
  margin: 3px;
  padding: 0 7px;
}

.bp4-input-group .bp4-button:empty {
  padding: 0;
}

.bp4-input-group > .bp4-input-left-container, .bp4-input-group > .bp4-icon {
  z-index: 1;
}

.bp4-input-group > .bp4-input-left-container > .bp4-icon, .bp4-input-group > .bp4-icon {
  color: #5f6b7c;
}

.bp4-input-group > .bp4-input-left-container > .bp4-icon:empty, .bp4-input-group > .bp4-icon:empty {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp4-input-group > .bp4-input-left-container > .bp4-icon, .bp4-input-group > .bp4-icon, .bp4-input-group .bp4-input-action > .bp4-spinner {
  margin: 7px;
}

.bp4-input-group .bp4-tag {
  margin: 5px;
}

.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus), .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) {
  color: #5f6b7c;
}

.bp4-dark .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus), .bp4-dark .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) {
  color: #abb3bf;
}

.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-standard, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-large, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-standard, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:not(:hover):not(:focus) .bp4-icon-large {
  color: #5f6b7c;
}

.bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled .bp4-icon, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled .bp4-icon-standard, .bp4-input-group .bp4-input:not(:focus) + .bp4-button.bp4-minimal:disabled .bp4-icon-large, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled .bp4-icon, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled .bp4-icon-standard, .bp4-input-group .bp4-input:not(:focus) + .bp4-input-action .bp4-button.bp4-minimal:disabled .bp4-icon-large {
  color: #5f6b7c99 !important;
}

.bp4-input-group.bp4-disabled {
  cursor: not-allowed;
}

.bp4-input-group.bp4-disabled .bp4-icon {
  color: #5f6b7c99;
}

.bp4-input-group.bp4-large .bp4-button {
  min-height: 30px;
  min-width: 30px;
  margin: 5px;
}

.bp4-input-group.bp4-large > .bp4-input-left-container > .bp4-icon, .bp4-input-group.bp4-large > .bp4-icon, .bp4-input-group.bp4-large .bp4-input-action > .bp4-spinner {
  margin: 12px;
}

.bp4-input-group.bp4-large .bp4-input {
  height: 40px;
  font-size: 16px;
  line-height: 40px;
}

.bp4-input-group.bp4-large .bp4-input[type="search"], .bp4-input-group.bp4-large .bp4-input.bp4-round {
  padding: 0 15px;
}

.bp4-input-group.bp4-large .bp4-input:not(:first-child) {
  padding-left: 40px;
}

.bp4-input-group.bp4-large .bp4-input:not(:last-child) {
  padding-right: 40px;
}

.bp4-input-group.bp4-small .bp4-button, .bp4-input-group.bp4-small .bp4-tag {
  min-height: 20px;
  min-width: 20px;
  margin: 2px;
}

.bp4-input-group.bp4-small > .bp4-input-left-container > .bp4-icon, .bp4-input-group.bp4-small > .bp4-icon, .bp4-input-group.bp4-small .bp4-input-action > .bp4-spinner {
  margin: 4px;
}

.bp4-input-group.bp4-small .bp4-input {
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 24px;
}

.bp4-input-group.bp4-small .bp4-input[type="search"], .bp4-input-group.bp4-small .bp4-input.bp4-round {
  padding: 0 12px;
}

.bp4-input-group.bp4-small .bp4-input:not(:first-child) {
  padding-left: 24px;
}

.bp4-input-group.bp4-small .bp4-input:not(:last-child) {
  padding-right: 24px;
}

.bp4-input-group.bp4-fill {
  width: 100%;
  flex: auto;
}

.bp4-input-group.bp4-round .bp4-button, .bp4-input-group.bp4-round .bp4-input, .bp4-input-group.bp4-round .bp4-tag {
  border-radius: 30px;
}

.bp4-dark .bp4-input-group .bp4-icon {
  color: #abb3bf;
}

.bp4-dark .bp4-input-group.bp4-disabled .bp4-icon {
  color: #abb3bf99;
}

.bp4-input-group.bp4-intent-primary .bp4-input {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #2d72d2, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input-group.bp4-intent-primary .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-input-group.bp4-intent-primary .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}

.bp4-input-group.bp4-intent-primary .bp4-input:disabled, .bp4-input-group.bp4-intent-primary .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input {
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #4c90f0, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}

.bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-primary .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-input-group.bp4-intent-primary > .bp4-icon {
  color: #215db0;
}

.bp4-dark .bp4-input-group.bp4-intent-primary > .bp4-icon {
  color: #8abbff;
}

.bp4-input-group.bp4-intent-success .bp4-input {
  box-shadow: 0 0 #23855100, 0 0 #23855100, inset 0 0 0 1px #238551, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input-group.bp4-intent-success .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp4-input-group.bp4-intent-success .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}

.bp4-input-group.bp4-intent-success .bp4-input:disabled, .bp4-input-group.bp4-intent-success .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input {
  box-shadow: 0 0 #32a46700, 0 0 #32a46700, 0 0 #32a46700, inset 0 0 0 1px #32a467, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px #32a4674d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}

.bp4-dark .bp4-input-group.bp4-intent-success .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-success .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-input-group.bp4-intent-success > .bp4-icon {
  color: #1c6e42;
}

.bp4-dark .bp4-input-group.bp4-intent-success > .bp4-icon {
  color: #72ca9b;
}

.bp4-input-group.bp4-intent-warning .bp4-input {
  box-shadow: 0 0 #c8761900, 0 0 #c8761900, inset 0 0 0 1px #c87619, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input-group.bp4-intent-warning .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp4-input-group.bp4-intent-warning .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}

.bp4-input-group.bp4-intent-warning .bp4-input:disabled, .bp4-input-group.bp4-intent-warning .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input {
  box-shadow: 0 0 #ec9a3c00, 0 0 #ec9a3c00, 0 0 #ec9a3c00, inset 0 0 0 1px #ec9a3c, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px #ec9a3c4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}

.bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-warning .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-input-group.bp4-intent-warning > .bp4-icon {
  color: #935610;
}

.bp4-dark .bp4-input-group.bp4-intent-warning > .bp4-icon {
  color: #fbb360;
}

.bp4-input-group.bp4-intent-danger .bp4-input {
  box-shadow: 0 0 #cd424600, 0 0 #cd424600, inset 0 0 0 1px #cd4246, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input-group.bp4-intent-danger .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp4-input-group.bp4-intent-danger .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}

.bp4-input-group.bp4-intent-danger .bp4-input:disabled, .bp4-input-group.bp4-intent-danger .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input {
  box-shadow: 0 0 #e76a6e00, 0 0 #e76a6e00, 0 0 #e76a6e00, inset 0 0 0 1px #e76a6e, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px #e76a6e4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}

.bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input:disabled, .bp4-dark .bp4-input-group.bp4-intent-danger .bp4-input.bp4-disabled {
  box-shadow: none;
}

.bp4-input-group.bp4-intent-danger > .bp4-icon {
  color: #ac2f33;
}

.bp4-dark .bp4-input-group.bp4-intent-danger > .bp4-icon {
  color: #fa999c;
}

.bp4-input {
  appearance: none;
  color: #1c2127;
  height: 30px;
  vertical-align: middle;
  background: #fff;
  border: none;
  border-radius: 3px;
  outline: none;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  transition: box-shadow .1s cubic-bezier(.4, 1, .75, .9);
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp4-input:focus, .bp4-input.bp4-active {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-input[type="search"], .bp4-input.bp4-round {
  box-sizing: border-box;
  border-radius: 30px;
  padding-left: 10px;
}

.bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141826;
}

.bp4-input:disabled, .bp4-input.bp4-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  resize: none;
  background: #d3d8de80;
}

.bp4-input:disabled::placeholder, .bp4-input.bp4-disabled::placeholder {
  color: #5f6b7c99;
}

.bp4-input.bp4-large {
  height: 40px;
  font-size: 16px;
  line-height: 40px;
}

.bp4-input.bp4-large[type="search"], .bp4-input.bp4-large.bp4-round {
  padding: 0 15px;
}

.bp4-input.bp4-small {
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 12px;
  line-height: 24px;
}

.bp4-input.bp4-small[type="search"], .bp4-input.bp4-small.bp4-round {
  padding: 0 12px;
}

.bp4-input.bp4-fill {
  width: 100%;
  flex: auto;
}

.bp4-dark .bp4-input {
  color: #f6f7f9;
  background: #1114184d;
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input::placeholder {
  color: #abb3bf;
}

.bp4-dark .bp4-input:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d;
}

.bp4-dark .bp4-input[readonly] {
  box-shadow: inset 0 0 0 1px #11141866;
}

.bp4-dark .bp4-input:disabled, .bp4-dark .bp4-input.bp4-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background: #40485480;
}

.bp4-input.bp4-intent-primary {
  box-shadow: 0 0 #2d72d200, 0 0 #2d72d200, inset 0 0 0 1px #2d72d2, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input.bp4-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-input.bp4-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #2d72d2;
}

.bp4-input.bp4-intent-primary:disabled, .bp4-input.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input.bp4-intent-primary {
  box-shadow: 0 0 #4c90f000, 0 0 #4c90f000, 0 0 #4c90f000, inset 0 0 0 1px #4c90f0, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-primary:focus {
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-primary[readonly] {
  box-shadow: inset 0 0 0 1px #4c90f0;
}

.bp4-dark .bp4-input.bp4-intent-primary:disabled, .bp4-dark .bp4-input.bp4-intent-primary.bp4-disabled {
  box-shadow: none;
}

.bp4-input.bp4-intent-success {
  box-shadow: 0 0 #23855100, 0 0 #23855100, inset 0 0 0 1px #238551, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input.bp4-intent-success:focus {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp4-input.bp4-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #238551;
}

.bp4-input.bp4-intent-success:disabled, .bp4-input.bp4-intent-success.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input.bp4-intent-success {
  box-shadow: 0 0 #32a46700, 0 0 #32a46700, 0 0 #32a46700, inset 0 0 0 1px #32a467, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-success:focus {
  box-shadow: inset 0 0 0 1px #32a467, inset 0 0 0 1px #32a467, 0 0 0 2px #32a4674d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-success[readonly] {
  box-shadow: inset 0 0 0 1px #32a467;
}

.bp4-dark .bp4-input.bp4-intent-success:disabled, .bp4-dark .bp4-input.bp4-intent-success.bp4-disabled {
  box-shadow: none;
}

.bp4-input.bp4-intent-warning {
  box-shadow: 0 0 #c8761900, 0 0 #c8761900, inset 0 0 0 1px #c87619, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input.bp4-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp4-input.bp4-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #c87619;
}

.bp4-input.bp4-intent-warning:disabled, .bp4-input.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input.bp4-intent-warning {
  box-shadow: 0 0 #ec9a3c00, 0 0 #ec9a3c00, 0 0 #ec9a3c00, inset 0 0 0 1px #ec9a3c, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-warning:focus {
  box-shadow: inset 0 0 0 1px #ec9a3c, inset 0 0 0 1px #ec9a3c, 0 0 0 2px #ec9a3c4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-warning[readonly] {
  box-shadow: inset 0 0 0 1px #ec9a3c;
}

.bp4-dark .bp4-input.bp4-intent-warning:disabled, .bp4-dark .bp4-input.bp4-intent-warning.bp4-disabled {
  box-shadow: none;
}

.bp4-input.bp4-intent-danger {
  box-shadow: 0 0 #cd424600, 0 0 #cd424600, inset 0 0 0 1px #cd4246, inset 0 0 0 1px #11141833, inset 0 1px 1px #11141880;
}

.bp4-input.bp4-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp4-input.bp4-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #cd4246;
}

.bp4-input.bp4-intent-danger:disabled, .bp4-input.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
}

.bp4-dark .bp4-input.bp4-intent-danger {
  box-shadow: 0 0 #e76a6e00, 0 0 #e76a6e00, 0 0 #e76a6e00, inset 0 0 0 1px #e76a6e, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-danger:focus {
  box-shadow: inset 0 0 0 1px #e76a6e, inset 0 0 0 1px #e76a6e, 0 0 0 2px #e76a6e4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-input.bp4-intent-danger[readonly] {
  box-shadow: inset 0 0 0 1px #e76a6e;
}

.bp4-dark .bp4-input.bp4-intent-danger:disabled, .bp4-dark .bp4-input.bp4-intent-danger.bp4-disabled {
  box-shadow: none;
}

.bp4-input::-ms-clear {
  display: none;
}

textarea.bp4-input {
  max-width: 100%;
  padding: 10px;
}

textarea.bp4-input, textarea.bp4-input.bp4-large, textarea.bp4-input.bp4-small {
  height: auto;
  line-height: inherit;
}

textarea.bp4-input.bp4-small {
  padding: 8px;
}

label.bp4-label {
  margin-top: 0;
  margin-bottom: 15px;
  display: block;
}

label.bp4-label .bp4-html-select, label.bp4-label .bp4-input, label.bp4-label .bp4-select, label.bp4-label .bp4-slider, label.bp4-label .bp4-popover-wrapper {
  text-transform: none;
  margin-top: 5px;
  display: block;
}

label.bp4-label .bp4-button-group {
  margin-top: 5px;
}

label.bp4-label .bp4-select select, label.bp4-label .bp4-html-select select {
  vertical-align: top;
  width: 100%;
  font-weight: 400;
}

label.bp4-label.bp4-disabled, label.bp4-label.bp4-disabled .bp4-text-muted {
  color: #5f6b7c99;
}

label.bp4-label.bp4-inline {
  line-height: 30px;
}

label.bp4-label.bp4-inline .bp4-html-select, label.bp4-label.bp4-inline .bp4-input, label.bp4-label.bp4-inline .bp4-input-group, label.bp4-label.bp4-inline .bp4-select, label.bp4-label.bp4-inline .bp4-popover-wrapper {
  vertical-align: top;
  margin: 0 0 0 5px;
  display: inline-block;
}

label.bp4-label.bp4-inline .bp4-button-group {
  margin: 0 0 0 5px;
}

label.bp4-label.bp4-inline .bp4-input-group .bp4-input {
  margin-left: 0;
}

label.bp4-label.bp4-inline.bp4-large {
  line-height: 40px;
}

label.bp4-label:not(.bp4-inline) .bp4-popover-target {
  display: block;
}

.bp4-dark label.bp4-label {
  color: #f6f7f9;
}

.bp4-dark label.bp4-label.bp4-disabled, .bp4-dark label.bp4-label.bp4-disabled .bp4-text-muted {
  color: #abb3bf99;
}

.bp4-numeric-input .bp4-button-group.bp4-vertical > .bp4-button {
  min-height: 0;
  width: 30px;
  flex: 14px;
  padding: 0;
}

.bp4-numeric-input.bp4-large .bp4-button-group.bp4-vertical > .bp4-button {
  width: 40px;
}

form {
  display: block;
}

.bp4-html-select select, .bp4-select select {
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  color: #1c2127;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 30px;
  width: 100%;
  background-color: #f6f7f9;
  background-image: linear-gradient(#11141800, #1114180d);
  border: none;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 25px 0 10px;
  font-size: 14px;
  display: inline-flex;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-html-select select > *, .bp4-select select > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-html-select select > .bp4-fill, .bp4-select select > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-html-select select:before, .bp4-select select:before, .bp4-html-select select > *, .bp4-select select > * {
  margin-right: 7px;
}

.bp4-html-select select:empty:before, .bp4-select select:empty:before, .bp4-html-select select > :last-child, .bp4-select select > :last-child {
  margin-right: 0;
}

.bp4-html-select select:hover, .bp4-select select:hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-html-select select:active, .bp4-select select:active, .bp4-html-select select.bp4-active, .bp4-select select.bp4-active {
  background-color: #dce0e5;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 1px 2px #11141833;
}

.bp4-html-select select:disabled, .bp4-select select:disabled, .bp4-html-select select.bp4-disabled, .bp4-select select.bp4-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  background-image: none;
  outline: none;
}

.bp4-html-select select:disabled.bp4-active, .bp4-select select:disabled.bp4-active, .bp4-html-select select.bp4-disabled.bp4-active, .bp4-select select.bp4-disabled.bp4-active {
  background: #d3d8deb3;
}

.bp4-html-select.bp4-minimal select, .bp4-select.bp4-minimal select {
  box-shadow: none;
  background: none;
}

.bp4-html-select.bp4-minimal select:hover, .bp4-select.bp4-minimal select:hover {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a826;
  text-decoration: none;
}

.bp4-html-select.bp4-minimal select:active, .bp4-select.bp4-minimal select:active, .bp4-html-select.bp4-minimal select.bp4-active, .bp4-select.bp4-minimal select.bp4-active {
  box-shadow: none;
  color: #1c2127;
  background: #8f99a84d;
}

.bp4-html-select.bp4-minimal select:disabled, .bp4-select.bp4-minimal select:disabled, .bp4-html-select.bp4-minimal select.bp4-disabled, .bp4-select.bp4-minimal select.bp4-disabled {
  color: #5f6b7c99;
  cursor: not-allowed;
  background: none;
}

.bp4-html-select.bp4-minimal select:disabled.bp4-active, .bp4-select.bp4-minimal select:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-disabled.bp4-active {
  background: #8f99a84d;
}

.bp4-dark .bp4-html-select.bp4-minimal select, .bp4-html-select.bp4-minimal .bp4-dark select, .bp4-dark .bp4-select.bp4-minimal select, .bp4-select.bp4-minimal .bp4-dark select {
  box-shadow: none;
  color: inherit;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select:hover, .bp4-html-select.bp4-minimal .bp4-dark select:hover, .bp4-dark .bp4-select.bp4-minimal select:hover, .bp4-select.bp4-minimal .bp4-dark select:hover, .bp4-dark .bp4-html-select.bp4-minimal select:active, .bp4-html-select.bp4-minimal .bp4-dark select:active, .bp4-dark .bp4-select.bp4-minimal select:active, .bp4-select.bp4-minimal .bp4-dark select:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-active {
  box-shadow: none;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select:hover, .bp4-html-select.bp4-minimal .bp4-dark select:hover, .bp4-dark .bp4-select.bp4-minimal select:hover, .bp4-select.bp4-minimal .bp4-dark select:hover {
  background: #8f99a826;
}

.bp4-dark .bp4-html-select.bp4-minimal select:active, .bp4-html-select.bp4-minimal .bp4-dark select:active, .bp4-dark .bp4-select.bp4-minimal select:active, .bp4-select.bp4-minimal .bp4-dark select:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-active {
  color: #f6f7f9;
  background: #8f99a84d;
}

.bp4-dark .bp4-html-select.bp4-minimal select:disabled, .bp4-html-select.bp4-minimal .bp4-dark select:disabled, .bp4-dark .bp4-select.bp4-minimal select:disabled, .bp4-select.bp4-minimal .bp4-dark select:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-disabled {
  color: #abb3bf99;
  cursor: not-allowed;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select:disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select:disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-disabled.bp4-active {
  background: #8f99a84d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary, .bp4-select.bp4-minimal select.bp4-intent-primary {
  color: #215db0;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-html-select.bp4-minimal select.bp4-intent-primary:active, .bp4-select.bp4-minimal select.bp4-intent-primary:active, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-active {
  box-shadow: none;
  color: #215db0;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-select.bp4-minimal select.bp4-intent-primary:hover {
  color: #215db0;
  background: #2d72d226;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary:active, .bp4-select.bp4-minimal select.bp4-intent-primary:active, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-active {
  color: #215db0;
  background: #2d72d24d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled, .bp4-select.bp4-minimal select.bp4-intent-primary:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled, .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled {
  color: #215db080;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head, .bp4-select.bp4-minimal select.bp4-intent-primary .bp4-button-spinner .bp4-spinner-head {
  stroke: #215db0;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary {
  color: #8abbff;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:hover, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:hover, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:hover {
  color: #8abbff;
  background: #2d72d233;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-active {
  color: #8abbff;
  background: #2d72d24d;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled {
  color: #8abbff80;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary:disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-primary.bp4-disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-primary.bp4-disabled.bp4-active {
  background: #2d72d24d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success, .bp4-select.bp4-minimal select.bp4-intent-success {
  color: #1c6e42;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success:hover, .bp4-select.bp4-minimal select.bp4-intent-success:hover, .bp4-html-select.bp4-minimal select.bp4-intent-success:active, .bp4-select.bp4-minimal select.bp4-intent-success:active, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-success.bp4-active {
  box-shadow: none;
  color: #1c6e42;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success:hover, .bp4-select.bp4-minimal select.bp4-intent-success:hover {
  color: #1c6e42;
  background: #23855126;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success:active, .bp4-select.bp4-minimal select.bp4-intent-success:active, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-success.bp4-active {
  color: #1c6e42;
  background: #2385514d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success:disabled, .bp4-select.bp4-minimal select.bp4-intent-success:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled, .bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled {
  color: #1c6e4280;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-success .bp4-button-spinner .bp4-spinner-head, .bp4-select.bp4-minimal select.bp4-intent-success .bp4-button-spinner .bp4-spinner-head {
  stroke: #1c6e42;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success {
  color: #72ca9b;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:hover, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:hover, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:hover {
  color: #72ca9b;
  background: #23855133;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-active {
  color: #72ca9b;
  background: #2385514d;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled {
  color: #72ca9b80;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success:disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-success.bp4-disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-success.bp4-disabled.bp4-active {
  background: #2385514d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning, .bp4-select.bp4-minimal select.bp4-intent-warning {
  color: #935610;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-html-select.bp4-minimal select.bp4-intent-warning:active, .bp4-select.bp4-minimal select.bp4-intent-warning:active, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-active {
  box-shadow: none;
  color: #935610;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-select.bp4-minimal select.bp4-intent-warning:hover {
  color: #935610;
  background: #c8761926;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning:active, .bp4-select.bp4-minimal select.bp4-intent-warning:active, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-active {
  color: #935610;
  background: #c876194d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled, .bp4-select.bp4-minimal select.bp4-intent-warning:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled, .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled {
  color: #93561080;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head, .bp4-select.bp4-minimal select.bp4-intent-warning .bp4-button-spinner .bp4-spinner-head {
  stroke: #935610;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning {
  color: #fbb360;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:hover, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:hover, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:hover {
  color: #fbb360;
  background: #c8761933;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-active {
  color: #fbb360;
  background: #c876194d;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled {
  color: #fbb36080;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning:disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-warning.bp4-disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-warning.bp4-disabled.bp4-active {
  background: #c876194d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger, .bp4-select.bp4-minimal select.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-html-select.bp4-minimal select.bp4-intent-danger:active, .bp4-select.bp4-minimal select.bp4-intent-danger:active, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-active {
  box-shadow: none;
  color: #ac2f33;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-select.bp4-minimal select.bp4-intent-danger:hover {
  color: #ac2f33;
  background: #cd424626;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger:active, .bp4-select.bp4-minimal select.bp4-intent-danger:active, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-active {
  color: #ac2f33;
  background: #cd42464d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled, .bp4-select.bp4-minimal select.bp4-intent-danger:disabled, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled, .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled {
  color: #ac2f3380;
  background: none;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active, .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active, .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-html-select.bp4-minimal select.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head, .bp4-select.bp4-minimal select.bp4-intent-danger .bp4-button-spinner .bp4-spinner-head {
  stroke: #ac2f33;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger {
  color: #fa999c;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:hover, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:hover, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:hover {
  color: #fa999c;
  background: #cd424633;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-active {
  color: #fa999c;
  background: #cd42464d;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled {
  color: #fa999c80;
  background: none;
}

.bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger:disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger:disabled.bp4-active, .bp4-dark .bp4-html-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active, .bp4-html-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled.bp4-active, .bp4-dark .bp4-select.bp4-minimal select.bp4-intent-danger.bp4-disabled.bp4-active, .bp4-select.bp4-minimal .bp4-dark select.bp4-intent-danger.bp4-disabled.bp4-active {
  background: #cd42464d;
}

.bp4-html-select.bp4-large select, .bp4-select.bp4-large select {
  height: 40px;
  padding-right: 35px;
  font-size: 16px;
}

.bp4-dark .bp4-html-select select, .bp4-dark .bp4-select select {
  color: #f6f7f9;
  background-color: #404854;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-html-select select:hover, .bp4-dark .bp4-select select:hover, .bp4-dark .bp4-html-select select:active, .bp4-dark .bp4-select select:active, .bp4-dark .bp4-html-select select.bp4-active, .bp4-dark .bp4-select select.bp4-active {
  color: #f6f7f9;
}

.bp4-dark .bp4-html-select select:hover, .bp4-dark .bp4-select select:hover {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-html-select select:active, .bp4-dark .bp4-select select:active, .bp4-dark .bp4-html-select select.bp4-active, .bp4-dark .bp4-select select.bp4-active {
  background-color: #252a31;
  background-image: none;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-html-select select:disabled, .bp4-dark .bp4-select select:disabled, .bp4-dark .bp4-html-select select.bp4-disabled, .bp4-dark .bp4-select select.bp4-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
  background-image: none;
}

.bp4-dark .bp4-html-select select:disabled.bp4-active, .bp4-dark .bp4-select select:disabled.bp4-active, .bp4-dark .bp4-html-select select.bp4-disabled.bp4-active, .bp4-dark .bp4-select select.bp4-disabled.bp4-active {
  background: #404854b3;
}

.bp4-dark .bp4-html-select select .bp4-button-spinner .bp4-spinner-head, .bp4-dark .bp4-select select .bp4-button-spinner .bp4-spinner-head {
  stroke: #8f99a8;
  background: #11141880;
}

.bp4-html-select select:disabled, .bp4-select select:disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
}

.bp4-select:after, .bp4-html-select .bp4-icon, .bp4-select .bp4-icon {
  color: #5f6b7c;
  pointer-events: none;
  position: absolute;
  top: 7px;
  right: 7px;
}

.bp4-disabled.bp4-select:after, .bp4-html-select .bp4-disabled.bp4-icon, .bp4-select .bp4-disabled.bp4-icon {
  color: #5f6b7c99;
}

.bp4-html-select, .bp4-select {
  letter-spacing: normal;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.bp4-html-select select::-ms-expand, .bp4-select select::-ms-expand {
  display: none;
}

.bp4-html-select .bp4-icon, .bp4-select .bp4-icon {
  color: #5f6b7c;
}

.bp4-html-select .bp4-icon:hover, .bp4-select .bp4-icon:hover {
  color: #1c2127;
}

.bp4-dark .bp4-html-select .bp4-icon, .bp4-dark .bp4-select .bp4-icon {
  color: #abb3bf;
}

.bp4-dark .bp4-html-select .bp4-icon:hover, .bp4-dark .bp4-select .bp4-icon:hover {
  color: #f6f7f9;
}

.bp4-html-select.bp4-large:after, .bp4-html-select.bp4-large .bp4-icon, .bp4-select.bp4-large:after, .bp4-select.bp4-large .bp4-icon {
  top: 12px;
  right: 12px;
}

.bp4-html-select.bp4-fill, .bp4-html-select.bp4-fill select, .bp4-select.bp4-fill, .bp4-select.bp4-fill select {
  width: 100%;
}

.bp4-dark .bp4-html-select option, .bp4-dark .bp4-select option {
  color: #f6f7f9;
  background-color: #383e47;
}

.bp4-dark .bp4-html-select option:disabled, .bp4-dark .bp4-select option:disabled {
  color: #abb3bf99;
}

.bp4-dark .bp4-html-select:after, .bp4-dark .bp4-select:after {
  color: #abb3bf;
}

.bp4-select:after {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

table.bp4-html-table, .bp4-running-text table {
  border-spacing: 0;
  font-size: 14px;
}

table.bp4-html-table th, .bp4-running-text table th, table.bp4-html-table td, .bp4-running-text table td {
  text-align: left;
  vertical-align: top;
  padding: 11px;
}

table.bp4-html-table th, .bp4-running-text table th {
  color: #1c2127;
  font-weight: 600;
}

table.bp4-html-table td, .bp4-running-text table td {
  color: #1c2127;
}

table.bp4-html-table tbody tr:first-child th, .bp4-running-text table tbody tr:first-child th, table.bp4-html-table tbody tr:first-child td, .bp4-running-text table tbody tr:first-child td, table.bp4-html-table tfoot tr:first-child th, .bp4-running-text table tfoot tr:first-child th, table.bp4-html-table tfoot tr:first-child td, .bp4-running-text table tfoot tr:first-child td {
  box-shadow: inset 0 1px #11141826;
}

.bp4-dark table.bp4-html-table th, .bp4-dark .bp4-running-text table th, .bp4-running-text .bp4-dark table th, .bp4-dark table.bp4-html-table td, .bp4-dark .bp4-running-text table td, .bp4-running-text .bp4-dark table td {
  color: #f6f7f9;
}

.bp4-dark table.bp4-html-table tbody tr:first-child th, .bp4-dark .bp4-running-text table tbody tr:first-child th, .bp4-running-text .bp4-dark table tbody tr:first-child th, .bp4-dark table.bp4-html-table tbody tr:first-child td, .bp4-dark .bp4-running-text table tbody tr:first-child td, .bp4-running-text .bp4-dark table tbody tr:first-child td, .bp4-dark table.bp4-html-table tfoot tr:first-child th, .bp4-dark .bp4-running-text table tfoot tr:first-child th, .bp4-running-text .bp4-dark table tfoot tr:first-child th, .bp4-dark table.bp4-html-table tfoot tr:first-child td, .bp4-dark .bp4-running-text table tfoot tr:first-child td, .bp4-running-text .bp4-dark table tfoot tr:first-child td {
  box-shadow: inset 0 1px #ffffff26;
}

table.bp4-html-table.bp4-html-table-condensed th, table.bp4-html-table.bp4-html-table-condensed td {
  padding-top: 6px;
  padding-bottom: 6px;
}

table.bp4-html-table.bp4-html-table-striped tbody tr:nth-child(2n+1) td {
  background: #c5cbd326;
}

table.bp4-html-table.bp4-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 #11141826;
}

table.bp4-html-table.bp4-html-table-bordered tbody tr td, table.bp4-html-table.bp4-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px #11141826;
}

table.bp4-html-table.bp4-html-table-bordered tbody tr td:not(:first-child), table.bp4-html-table.bp4-html-table-bordered tfoot tr td:not(:first-child) {
  box-shadow: inset 1px 1px #11141826;
}

table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: none;
}

table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td:not(:first-child) {
  box-shadow: inset 1px 0 #11141826;
}

table.bp4-html-table.bp4-interactive tbody tr:hover td {
  cursor: pointer;
  background-color: #c5cbd34d;
}

table.bp4-html-table.bp4-interactive tbody tr:active td {
  background-color: #c5cbd366;
}

.bp4-dark table.bp4-html-table.bp4-html-table-striped tbody tr:nth-child(2n+1) td {
  background: #5f6b7c26;
}

.bp4-dark table.bp4-html-table.bp4-html-table-bordered th:not(:first-child) {
  box-shadow: inset 1px 0 #ffffff26;
}

.bp4-dark table.bp4-html-table.bp4-html-table-bordered tbody tr td, .bp4-dark table.bp4-html-table.bp4-html-table-bordered tfoot tr td {
  box-shadow: inset 0 1px #ffffff26;
}

.bp4-dark table.bp4-html-table.bp4-html-table-bordered tbody tr td:not(:first-child), .bp4-dark table.bp4-html-table.bp4-html-table-bordered tfoot tr td:not(:first-child) {
  box-shadow: inset 1px 1px #ffffff26;
}

.bp4-dark table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td {
  box-shadow: inset 1px 0 #ffffff26;
}

.bp4-dark table.bp4-html-table.bp4-html-table-bordered.bp4-html-table-striped tbody tr:not(:first-child) td:first-child {
  box-shadow: none;
}

.bp4-dark table.bp4-html-table.bp4-interactive tbody tr:hover td {
  cursor: pointer;
  background-color: #5f6b7c4d;
}

.bp4-dark table.bp4-html-table.bp4-interactive tbody tr:active td {
  background-color: #5f6b7c66;
}

.bp4-key-combo {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.bp4-key-combo > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-key-combo > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-key-combo:before, .bp4-key-combo > * {
  margin-right: 5px;
}

.bp4-key-combo:empty:before, .bp4-key-combo > :last-child {
  margin-right: 0;
}

.bp4-hotkey-dialog {
  padding-bottom: 0;
  top: 40px;
}

.bp4-hotkey-dialog .bp4-dialog-body {
  margin: 0;
  padding: 0;
}

.bp4-hotkey-dialog .bp4-hotkey-label {
  flex-grow: 1;
}

.bp4-hotkey-column {
  max-height: 80vh;
  margin: auto;
  padding: 30px;
  overflow-y: auto;
}

.bp4-hotkey-column .bp4-heading {
  margin-bottom: 20px;
}

.bp4-hotkey-column .bp4-heading:not(:first-child) {
  margin-top: 40px;
}

.bp4-hotkey {
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.bp4-hotkey:not(:last-child) {
  margin-bottom: 10px;
}

.bp4-icon {
  vertical-align: text-bottom;
  flex: none;
  display: inline-block;
}

.bp4-icon:not(:empty):before {
  content: "" !important;
  content: unset !important;
}

.bp4-icon > svg {
  display: block;
}

.bp4-icon > svg:not([fill]) {
  fill: currentColor;
}

.bp4-icon.bp4-intent-primary, .bp4-icon-standard.bp4-intent-primary, .bp4-icon-large.bp4-intent-primary {
  color: #215db0;
}

.bp4-dark .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-icon-large.bp4-intent-primary {
  color: #8abbff;
}

.bp4-icon.bp4-intent-success, .bp4-icon-standard.bp4-intent-success, .bp4-icon-large.bp4-intent-success {
  color: #1c6e42;
}

.bp4-dark .bp4-icon.bp4-intent-success, .bp4-dark .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-icon-large.bp4-intent-success {
  color: #72ca9b;
}

.bp4-icon.bp4-intent-warning, .bp4-icon-standard.bp4-intent-warning, .bp4-icon-large.bp4-intent-warning {
  color: #935610;
}

.bp4-dark .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-icon-large.bp4-intent-warning {
  color: #fbb360;
}

.bp4-icon.bp4-intent-danger, .bp4-icon-standard.bp4-intent-danger, .bp4-icon-large.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-dark .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-icon-large.bp4-intent-danger {
  color: #fa999c;
}

span.bp4-icon-standard {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

span.bp4-icon-large {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

span.bp4-icon:empty {
  font-family: blueprint-icons-20;
  font-size: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

span.bp4-icon:empty:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.bp4-icon-blank:before {
  content: "";
}

.bp4-icon-style:before {
  content: "";
}

.bp4-icon-align-left:before {
  content: "";
}

.bp4-icon-align-center:before {
  content: "";
}

.bp4-icon-align-right:before {
  content: "";
}

.bp4-icon-align-justify:before {
  content: "";
}

.bp4-icon-bold:before {
  content: "";
}

.bp4-icon-italic:before {
  content: "";
}

.bp4-icon-underline:before {
  content: "";
}

.bp4-icon-search-around:before {
  content: "";
}

.bp4-icon-graph-remove:before {
  content: "";
}

.bp4-icon-group-objects:before {
  content: "";
}

.bp4-icon-merge-links:before {
  content: "";
}

.bp4-icon-layout:before {
  content: "";
}

.bp4-icon-layout-auto:before {
  content: "";
}

.bp4-icon-layout-circle:before {
  content: "";
}

.bp4-icon-layout-hierarchy:before {
  content: "";
}

.bp4-icon-layout-grid:before {
  content: "";
}

.bp4-icon-layout-group-by:before {
  content: "";
}

.bp4-icon-layout-skew-grid:before {
  content: "";
}

.bp4-icon-geosearch:before {
  content: "";
}

.bp4-icon-heatmap:before {
  content: "";
}

.bp4-icon-drive-time:before {
  content: "";
}

.bp4-icon-select:before {
  content: "";
}

.bp4-icon-predictive-analysis:before {
  content: "";
}

.bp4-icon-layers:before {
  content: "";
}

.bp4-icon-locate:before {
  content: "";
}

.bp4-icon-bookmark:before {
  content: "";
}

.bp4-icon-citation:before {
  content: "";
}

.bp4-icon-tag:before {
  content: "";
}

.bp4-icon-clipboard:before {
  content: "";
}

.bp4-icon-selection:before {
  content: "";
}

.bp4-icon-timeline-events:before {
  content: "";
}

.bp4-icon-timeline-line-chart:before {
  content: "";
}

.bp4-icon-timeline-bar-chart:before {
  content: "";
}

.bp4-icon-applications:before {
  content: "";
}

.bp4-icon-projects:before {
  content: "";
}

.bp4-icon-changes:before {
  content: "";
}

.bp4-icon-notifications:before {
  content: "";
}

.bp4-icon-lock:before {
  content: "";
}

.bp4-icon-unlock:before {
  content: "";
}

.bp4-icon-user:before {
  content: "";
}

.bp4-icon-search-template:before {
  content: "";
}

.bp4-icon-inbox:before {
  content: "";
}

.bp4-icon-more:before {
  content: "";
}

.bp4-icon-help:before {
  content: "";
}

.bp4-icon-calendar:before {
  content: "";
}

.bp4-icon-media:before {
  content: "";
}

.bp4-icon-link:before {
  content: "";
}

.bp4-icon-share:before {
  content: "";
}

.bp4-icon-download:before {
  content: "";
}

.bp4-icon-document:before {
  content: "";
}

.bp4-icon-properties:before {
  content: "";
}

.bp4-icon-import:before {
  content: "";
}

.bp4-icon-export:before {
  content: "";
}

.bp4-icon-minimize:before {
  content: "";
}

.bp4-icon-maximize:before {
  content: "";
}

.bp4-icon-tick:before {
  content: "";
}

.bp4-icon-cross:before {
  content: "";
}

.bp4-icon-plus:before {
  content: "";
}

.bp4-icon-minus:before {
  content: "";
}

.bp4-icon-arrow-left:before {
  content: "";
}

.bp4-icon-arrow-right:before {
  content: "";
}

.bp4-icon-exchange:before {
  content: "";
}

.bp4-icon-comparison:before {
  content: "";
}

.bp4-icon-list:before {
  content: "";
}

.bp4-icon-filter:before {
  content: "";
}

.bp4-icon-confirm:before {
  content: "";
}

.bp4-icon-fork:before {
  content: "";
}

.bp4-icon-trash:before {
  content: "";
}

.bp4-icon-person:before {
  content: "";
}

.bp4-icon-people:before {
  content: "";
}

.bp4-icon-add:before {
  content: "";
}

.bp4-icon-remove:before {
  content: "";
}

.bp4-icon-geolocation:before {
  content: "";
}

.bp4-icon-zoom-in:before {
  content: "";
}

.bp4-icon-zoom-out:before {
  content: "";
}

.bp4-icon-refresh:before {
  content: "";
}

.bp4-icon-delete:before {
  content: "";
}

.bp4-icon-cog:before {
  content: "";
}

.bp4-icon-flag:before {
  content: "";
}

.bp4-icon-pin:before {
  content: "";
}

.bp4-icon-warning-sign:before {
  content: "";
}

.bp4-icon-error:before {
  content: "";
}

.bp4-icon-info-sign:before {
  content: "";
}

.bp4-icon-credit-card:before {
  content: "";
}

.bp4-icon-edit:before {
  content: "";
}

.bp4-icon-history:before {
  content: "";
}

.bp4-icon-search:before {
  content: "";
}

.bp4-icon-log-out:before {
  content: "";
}

.bp4-icon-star:before {
  content: "";
}

.bp4-icon-star-empty:before {
  content: "";
}

.bp4-icon-sort-alphabetical:before {
  content: "";
}

.bp4-icon-sort-numerical:before {
  content: "";
}

.bp4-icon-sort:before {
  content: "";
}

.bp4-icon-folder-open:before {
  content: "";
}

.bp4-icon-folder-close:before {
  content: "";
}

.bp4-icon-folder-shared:before {
  content: "";
}

.bp4-icon-caret-up:before {
  content: "";
}

.bp4-icon-caret-right:before {
  content: "";
}

.bp4-icon-caret-down:before {
  content: "";
}

.bp4-icon-caret-left:before {
  content: "";
}

.bp4-icon-menu-open:before {
  content: "";
}

.bp4-icon-menu-closed:before {
  content: "";
}

.bp4-icon-feed:before {
  content: "";
}

.bp4-icon-two-columns:before {
  content: "";
}

.bp4-icon-one-column:before {
  content: "";
}

.bp4-icon-dot:before {
  content: "";
}

.bp4-icon-property:before {
  content: "";
}

.bp4-icon-time:before {
  content: "";
}

.bp4-icon-disable:before {
  content: "";
}

.bp4-icon-unpin:before {
  content: "";
}

.bp4-icon-flows:before {
  content: "";
}

.bp4-icon-new-text-box:before {
  content: "";
}

.bp4-icon-new-link:before {
  content: "";
}

.bp4-icon-new-object:before {
  content: "";
}

.bp4-icon-path-search:before {
  content: "";
}

.bp4-icon-automatic-updates:before {
  content: "";
}

.bp4-icon-page-layout:before {
  content: "";
}

.bp4-icon-code:before {
  content: "";
}

.bp4-icon-map:before {
  content: "";
}

.bp4-icon-search-text:before {
  content: "";
}

.bp4-icon-envelope:before {
  content: "";
}

.bp4-icon-paperclip:before {
  content: "";
}

.bp4-icon-label:before {
  content: "";
}

.bp4-icon-globe:before {
  content: "";
}

.bp4-icon-home:before {
  content: "";
}

.bp4-icon-th:before {
  content: "";
}

.bp4-icon-th-list:before {
  content: "";
}

.bp4-icon-th-derived:before {
  content: "";
}

.bp4-icon-circle:before {
  content: "";
}

.bp4-icon-draw:before {
  content: "";
}

.bp4-icon-insert:before {
  content: "";
}

.bp4-icon-helper-management:before {
  content: "";
}

.bp4-icon-send-to:before {
  content: "";
}

.bp4-icon-eye-open:before {
  content: "";
}

.bp4-icon-folder-shared-open:before {
  content: "";
}

.bp4-icon-social-media:before {
  content: "";
}

.bp4-icon-arrow-up:before {
  content: "";
}

.bp4-icon-arrow-down:before {
  content: "";
}

.bp4-icon-arrows-horizontal:before {
  content: "";
}

.bp4-icon-arrows-vertical:before {
  content: "";
}

.bp4-icon-resolve:before {
  content: "";
}

.bp4-icon-graph:before {
  content: "";
}

.bp4-icon-briefcase:before {
  content: "";
}

.bp4-icon-dollar:before {
  content: "";
}

.bp4-icon-ninja:before {
  content: "";
}

.bp4-icon-delta:before {
  content: "";
}

.bp4-icon-barcode:before {
  content: "";
}

.bp4-icon-torch:before {
  content: "";
}

.bp4-icon-widget:before {
  content: "";
}

.bp4-icon-unresolve:before {
  content: "";
}

.bp4-icon-offline:before {
  content: "";
}

.bp4-icon-zoom-to-fit:before {
  content: "";
}

.bp4-icon-add-to-artifact:before {
  content: "";
}

.bp4-icon-map-marker:before {
  content: "";
}

.bp4-icon-chart:before {
  content: "";
}

.bp4-icon-control:before {
  content: "";
}

.bp4-icon-multi-select:before {
  content: "";
}

.bp4-icon-direction-left:before {
  content: "";
}

.bp4-icon-direction-right:before {
  content: "";
}

.bp4-icon-database:before {
  content: "";
}

.bp4-icon-pie-chart:before {
  content: "";
}

.bp4-icon-full-circle:before {
  content: "";
}

.bp4-icon-square:before {
  content: "";
}

.bp4-icon-print:before {
  content: "";
}

.bp4-icon-presentation:before {
  content: "";
}

.bp4-icon-ungroup-objects:before {
  content: "";
}

.bp4-icon-chat:before {
  content: "";
}

.bp4-icon-comment:before {
  content: "";
}

.bp4-icon-circle-arrow-right:before {
  content: "";
}

.bp4-icon-circle-arrow-left:before {
  content: "";
}

.bp4-icon-circle-arrow-up:before {
  content: "";
}

.bp4-icon-circle-arrow-down:before {
  content: "";
}

.bp4-icon-upload:before {
  content: "";
}

.bp4-icon-asterisk:before {
  content: "";
}

.bp4-icon-cloud:before {
  content: "";
}

.bp4-icon-cloud-download:before {
  content: "";
}

.bp4-icon-cloud-upload:before {
  content: "";
}

.bp4-icon-repeat:before {
  content: "";
}

.bp4-icon-move:before {
  content: "";
}

.bp4-icon-chevron-left:before {
  content: "";
}

.bp4-icon-chevron-right:before {
  content: "";
}

.bp4-icon-chevron-up:before {
  content: "";
}

.bp4-icon-chevron-down:before {
  content: "";
}

.bp4-icon-random:before {
  content: "";
}

.bp4-icon-fullscreen:before {
  content: "";
}

.bp4-icon-log-in:before {
  content: "";
}

.bp4-icon-heart:before {
  content: "";
}

.bp4-icon-office:before {
  content: "";
}

.bp4-icon-duplicate:before {
  content: "";
}

.bp4-icon-ban-circle:before {
  content: "";
}

.bp4-icon-camera:before {
  content: "";
}

.bp4-icon-mobile-video:before {
  content: "";
}

.bp4-icon-video:before {
  content: "";
}

.bp4-icon-film:before {
  content: "";
}

.bp4-icon-settings:before {
  content: "";
}

.bp4-icon-volume-off:before {
  content: "";
}

.bp4-icon-volume-down:before {
  content: "";
}

.bp4-icon-volume-up:before {
  content: "";
}

.bp4-icon-music:before {
  content: "";
}

.bp4-icon-step-backward:before {
  content: "";
}

.bp4-icon-fast-backward:before {
  content: "";
}

.bp4-icon-pause:before {
  content: "";
}

.bp4-icon-stop:before {
  content: "";
}

.bp4-icon-play:before {
  content: "";
}

.bp4-icon-fast-forward:before {
  content: "";
}

.bp4-icon-step-forward:before {
  content: "";
}

.bp4-icon-eject:before {
  content: "";
}

.bp4-icon-record:before {
  content: "";
}

.bp4-icon-desktop:before {
  content: "";
}

.bp4-icon-phone:before {
  content: "";
}

.bp4-icon-lightbulb:before {
  content: "";
}

.bp4-icon-glass:before {
  content: "";
}

.bp4-icon-tint:before {
  content: "";
}

.bp4-icon-flash:before {
  content: "";
}

.bp4-icon-font:before {
  content: "";
}

.bp4-icon-header:before {
  content: "";
}

.bp4-icon-saved:before {
  content: "";
}

.bp4-icon-floppy-disk:before {
  content: "";
}

.bp4-icon-book:before {
  content: "";
}

.bp4-icon-hand-right:before {
  content: "";
}

.bp4-icon-hand-up:before {
  content: "";
}

.bp4-icon-hand-down:before {
  content: "";
}

.bp4-icon-hand-left:before {
  content: "";
}

.bp4-icon-thumbs-up:before {
  content: "";
}

.bp4-icon-thumbs-down:before {
  content: "";
}

.bp4-icon-box:before {
  content: "";
}

.bp4-icon-compressed:before {
  content: "";
}

.bp4-icon-shopping-cart:before {
  content: "";
}

.bp4-icon-shop:before {
  content: "";
}

.bp4-icon-layout-linear:before {
  content: "";
}

.bp4-icon-undo:before {
  content: "";
}

.bp4-icon-redo:before {
  content: "";
}

.bp4-icon-code-block:before {
  content: "";
}

.bp4-icon-double-caret-vertical:before {
  content: "";
}

.bp4-icon-double-caret-horizontal:before {
  content: "";
}

.bp4-icon-sort-alphabetical-desc:before {
  content: "";
}

.bp4-icon-sort-numerical-desc:before {
  content: "";
}

.bp4-icon-take-action:before {
  content: "";
}

.bp4-icon-contrast:before {
  content: "";
}

.bp4-icon-eye-off:before {
  content: "";
}

.bp4-icon-timeline-area-chart:before {
  content: "";
}

.bp4-icon-doughnut-chart:before {
  content: "";
}

.bp4-icon-layer:before {
  content: "";
}

.bp4-icon-grid:before {
  content: "";
}

.bp4-icon-polygon-filter:before {
  content: "";
}

.bp4-icon-add-to-folder:before {
  content: "";
}

.bp4-icon-layout-balloon:before {
  content: "";
}

.bp4-icon-layout-sorted-clusters:before {
  content: "";
}

.bp4-icon-sort-asc:before {
  content: "";
}

.bp4-icon-sort-desc:before {
  content: "";
}

.bp4-icon-small-cross:before {
  content: "";
}

.bp4-icon-small-tick:before {
  content: "";
}

.bp4-icon-power:before {
  content: "";
}

.bp4-icon-column-layout:before {
  content: "";
}

.bp4-icon-arrow-top-left:before {
  content: "";
}

.bp4-icon-arrow-top-right:before {
  content: "";
}

.bp4-icon-arrow-bottom-right:before {
  content: "";
}

.bp4-icon-arrow-bottom-left:before {
  content: "";
}

.bp4-icon-mugshot:before {
  content: "";
}

.bp4-icon-headset:before {
  content: "";
}

.bp4-icon-text-highlight:before {
  content: "";
}

.bp4-icon-hand:before {
  content: "";
}

.bp4-icon-chevron-backward:before {
  content: "";
}

.bp4-icon-chevron-forward:before {
  content: "";
}

.bp4-icon-rotate-document:before {
  content: "";
}

.bp4-icon-rotate-page:before {
  content: "";
}

.bp4-icon-badge:before {
  content: "";
}

.bp4-icon-grid-view:before {
  content: "";
}

.bp4-icon-function:before {
  content: "";
}

.bp4-icon-waterfall-chart:before {
  content: "";
}

.bp4-icon-stacked-chart:before {
  content: "";
}

.bp4-icon-pulse:before {
  content: "";
}

.bp4-icon-new-person:before {
  content: "";
}

.bp4-icon-exclude-row:before {
  content: "";
}

.bp4-icon-pivot-table:before {
  content: "";
}

.bp4-icon-segmented-control:before {
  content: "";
}

.bp4-icon-highlight:before {
  content: "";
}

.bp4-icon-filter-list:before {
  content: "";
}

.bp4-icon-cut:before {
  content: "";
}

.bp4-icon-annotation:before {
  content: "";
}

.bp4-icon-pivot:before {
  content: "";
}

.bp4-icon-ring:before {
  content: "";
}

.bp4-icon-heat-grid:before {
  content: "";
}

.bp4-icon-gantt-chart:before {
  content: "";
}

.bp4-icon-variable:before {
  content: "";
}

.bp4-icon-manual:before {
  content: "";
}

.bp4-icon-add-row-top:before {
  content: "";
}

.bp4-icon-add-row-bottom:before {
  content: "";
}

.bp4-icon-add-column-left:before {
  content: "";
}

.bp4-icon-add-column-right:before {
  content: "";
}

.bp4-icon-remove-row-top:before {
  content: "";
}

.bp4-icon-remove-row-bottom:before {
  content: "";
}

.bp4-icon-remove-column-left:before {
  content: "";
}

.bp4-icon-remove-column-right:before {
  content: "";
}

.bp4-icon-double-chevron-left:before {
  content: "";
}

.bp4-icon-double-chevron-right:before {
  content: "";
}

.bp4-icon-double-chevron-up:before {
  content: "";
}

.bp4-icon-double-chevron-down:before {
  content: "";
}

.bp4-icon-key-control:before {
  content: "";
}

.bp4-icon-key-command:before {
  content: "";
}

.bp4-icon-key-shift:before {
  content: "";
}

.bp4-icon-key-backspace:before {
  content: "";
}

.bp4-icon-key-delete:before {
  content: "";
}

.bp4-icon-key-escape:before {
  content: "";
}

.bp4-icon-key-enter:before {
  content: "";
}

.bp4-icon-calculator:before {
  content: "";
}

.bp4-icon-horizontal-bar-chart:before {
  content: "";
}

.bp4-icon-small-plus:before {
  content: "";
}

.bp4-icon-small-minus:before {
  content: "";
}

.bp4-icon-step-chart:before {
  content: "";
}

.bp4-icon-euro:before {
  content: "";
}

.bp4-icon-drag-handle-vertical:before {
  content: "";
}

.bp4-icon-drag-handle-horizontal:before {
  content: "";
}

.bp4-icon-mobile-phone:before {
  content: "";
}

.bp4-icon-sim-card:before {
  content: "";
}

.bp4-icon-trending-up:before {
  content: "";
}

.bp4-icon-trending-down:before {
  content: "";
}

.bp4-icon-curved-range-chart:before {
  content: "";
}

.bp4-icon-vertical-bar-chart-desc:before {
  content: "";
}

.bp4-icon-horizontal-bar-chart-desc:before {
  content: "";
}

.bp4-icon-document-open:before {
  content: "";
}

.bp4-icon-document-share:before {
  content: "";
}

.bp4-icon-horizontal-distribution:before {
  content: "";
}

.bp4-icon-vertical-distribution:before {
  content: "";
}

.bp4-icon-alignment-left:before {
  content: "";
}

.bp4-icon-alignment-vertical-center:before {
  content: "";
}

.bp4-icon-alignment-right:before {
  content: "";
}

.bp4-icon-alignment-top:before {
  content: "";
}

.bp4-icon-alignment-horizontal-center:before {
  content: "";
}

.bp4-icon-alignment-bottom:before {
  content: "";
}

.bp4-icon-git-pull:before {
  content: "";
}

.bp4-icon-git-merge:before {
  content: "";
}

.bp4-icon-git-branch:before {
  content: "";
}

.bp4-icon-git-commit:before {
  content: "";
}

.bp4-icon-git-push:before {
  content: "";
}

.bp4-icon-build:before {
  content: "";
}

.bp4-icon-symbol-circle:before {
  content: "";
}

.bp4-icon-symbol-square:before {
  content: "";
}

.bp4-icon-symbol-diamond:before {
  content: "";
}

.bp4-icon-symbol-cross:before {
  content: "";
}

.bp4-icon-symbol-triangle-up:before {
  content: "";
}

.bp4-icon-symbol-triangle-down:before {
  content: "";
}

.bp4-icon-wrench:before {
  content: "";
}

.bp4-icon-application:before {
  content: "";
}

.bp4-icon-send-to-graph:before {
  content: "";
}

.bp4-icon-send-to-map:before {
  content: "";
}

.bp4-icon-join-table:before {
  content: "";
}

.bp4-icon-derive-column:before {
  content: "";
}

.bp4-icon-image-rotate-left:before {
  content: "";
}

.bp4-icon-image-rotate-right:before {
  content: "";
}

.bp4-icon-known-vehicle:before {
  content: "";
}

.bp4-icon-unknown-vehicle:before {
  content: "";
}

.bp4-icon-scatter-plot:before {
  content: "";
}

.bp4-icon-oil-field:before {
  content: "";
}

.bp4-icon-rig:before {
  content: "";
}

.bp4-icon-map-create:before {
  content: "";
}

.bp4-icon-key-option:before {
  content: "";
}

.bp4-icon-list-detail-view:before {
  content: "";
}

.bp4-icon-swap-vertical:before {
  content: "";
}

.bp4-icon-swap-horizontal:before {
  content: "";
}

.bp4-icon-numbered-list:before {
  content: "";
}

.bp4-icon-new-grid-item:before {
  content: "";
}

.bp4-icon-git-repo:before {
  content: "";
}

.bp4-icon-git-new-branch:before {
  content: "";
}

.bp4-icon-manually-entered-data:before {
  content: "";
}

.bp4-icon-airplane:before {
  content: "";
}

.bp4-icon-merge-columns:before {
  content: "";
}

.bp4-icon-split-columns:before {
  content: "";
}

.bp4-icon-dashboard:before {
  content: "";
}

.bp4-icon-publish-function:before {
  content: "";
}

.bp4-icon-path:before {
  content: "";
}

.bp4-icon-moon:before {
  content: "";
}

.bp4-icon-remove-column:before {
  content: "";
}

.bp4-icon-numerical:before {
  content: "";
}

.bp4-icon-key-tab:before {
  content: "";
}

.bp4-icon-regression-chart:before {
  content: "";
}

.bp4-icon-translate:before {
  content: "";
}

.bp4-icon-eye-on:before {
  content: "";
}

.bp4-icon-vertical-bar-chart-asc:before {
  content: "";
}

.bp4-icon-horizontal-bar-chart-asc:before {
  content: "";
}

.bp4-icon-grouped-bar-chart:before {
  content: "";
}

.bp4-icon-full-stacked-chart:before {
  content: "";
}

.bp4-icon-endorsed:before {
  content: "";
}

.bp4-icon-follower:before {
  content: "";
}

.bp4-icon-following:before {
  content: "";
}

.bp4-icon-menu:before {
  content: "";
}

.bp4-icon-collapse-all:before {
  content: "";
}

.bp4-icon-expand-all:before {
  content: "";
}

.bp4-icon-intersection:before {
  content: "";
}

.bp4-icon-blocked-person:before {
  content: "";
}

.bp4-icon-slash:before {
  content: "";
}

.bp4-icon-percentage:before {
  content: "";
}

.bp4-icon-satellite:before {
  content: "";
}

.bp4-icon-paragraph:before {
  content: "";
}

.bp4-icon-bank-account:before {
  content: "";
}

.bp4-icon-cell-tower:before {
  content: "";
}

.bp4-icon-id-number:before {
  content: "";
}

.bp4-icon-ip-address:before {
  content: "";
}

.bp4-icon-eraser:before {
  content: "";
}

.bp4-icon-issue:before {
  content: "";
}

.bp4-icon-issue-new:before {
  content: "";
}

.bp4-icon-issue-closed:before {
  content: "";
}

.bp4-icon-panel-stats:before {
  content: "";
}

.bp4-icon-panel-table:before {
  content: "";
}

.bp4-icon-tick-circle:before {
  content: "";
}

.bp4-icon-prescription:before {
  content: "";
}

.bp4-icon-new-prescription:before {
  content: "";
}

.bp4-icon-filter-keep:before {
  content: "";
}

.bp4-icon-filter-remove:before {
  content: "";
}

.bp4-icon-key:before {
  content: "";
}

.bp4-icon-feed-subscribed:before {
  content: "";
}

.bp4-icon-widget-button:before {
  content: "";
}

.bp4-icon-widget-header:before {
  content: "";
}

.bp4-icon-widget-footer:before {
  content: "";
}

.bp4-icon-header-one:before {
  content: "";
}

.bp4-icon-header-two:before {
  content: "";
}

.bp4-icon-form:before {
  content: "";
}

.bp4-icon-series-add:before {
  content: "";
}

.bp4-icon-series-search:before {
  content: "";
}

.bp4-icon-series-filtered:before {
  content: "";
}

.bp4-icon-series-derived:before {
  content: "";
}

.bp4-icon-series-configuration:before {
  content: "";
}

.bp4-icon-console:before {
  content: "";
}

.bp4-icon-compass:before {
  content: "";
}

.bp4-icon-walk:before {
  content: "";
}

.bp4-icon-taxi:before {
  content: "";
}

.bp4-icon-train:before {
  content: "";
}

.bp4-icon-heart-broken:before {
  content: "";
}

.bp4-icon-inner-join:before {
  content: "";
}

.bp4-icon-left-join:before {
  content: "";
}

.bp4-icon-right-join:before {
  content: "";
}

.bp4-icon-strikethrough:before {
  content: "";
}

.bp4-icon-updated:before {
  content: "";
}

.bp4-icon-outdated:before {
  content: "";
}

.bp4-icon-flame:before {
  content: "";
}

.bp4-icon-folder-new:before {
  content: "";
}

.bp4-icon-mountain:before {
  content: "";
}

.bp4-icon-shield:before {
  content: "";
}

.bp4-icon-diagram-tree:before {
  content: "";
}

.bp4-icon-crown:before {
  content: "";
}

.bp4-icon-globe-network:before {
  content: "";
}

.bp4-icon-snowflake:before {
  content: "";
}

.bp4-icon-tree:before {
  content: "";
}

.bp4-icon-notifications-updated:before {
  content: "";
}

.bp4-icon-list-columns:before {
  content: "";
}

.bp4-icon-flow-linear:before {
  content: "";
}

.bp4-icon-flow-branch:before {
  content: "";
}

.bp4-icon-flow-review:before {
  content: "";
}

.bp4-icon-flow-review-branch:before {
  content: "";
}

.bp4-icon-flow-end:before {
  content: "";
}

.bp4-icon-clean:before {
  content: "";
}

.bp4-icon-th-filtered:before {
  content: "";
}

.bp4-icon-lifesaver:before {
  content: "";
}

.bp4-icon-cube:before {
  content: "";
}

.bp4-icon-cube-add:before {
  content: "";
}

.bp4-icon-cube-remove:before {
  content: "";
}

.bp4-icon-inbox-filtered:before {
  content: "";
}

.bp4-icon-inbox-geo:before {
  content: "";
}

.bp4-icon-inbox-search:before {
  content: "";
}

.bp4-icon-inbox-update:before {
  content: "";
}

.bp4-icon-inheritance:before {
  content: "";
}

.bp4-icon-reset:before {
  content: "";
}

.bp4-icon-filter-open:before {
  content: "";
}

.bp4-icon-th-disconnect:before {
  content: "";
}

.bp4-icon-equals:before {
  content: "";
}

.bp4-icon-not-equal-to:before {
  content: "";
}

.bp4-icon-greater-than:before {
  content: "";
}

.bp4-icon-greater-than-or-equal-to:before {
  content: "";
}

.bp4-icon-less-than:before {
  content: "";
}

.bp4-icon-less-than-or-equal-to:before {
  content: "";
}

.bp4-icon-learning:before {
  content: "";
}

.bp4-icon-new-layer:before {
  content: "";
}

.bp4-icon-new-layers:before {
  content: "";
}

.bp4-icon-stopwatch:before {
  content: "";
}

.bp4-icon-archive:before {
  content: "";
}

.bp4-icon-unarchive:before {
  content: "";
}

.bp4-icon-data-lineage:before {
  content: "";
}

.bp4-icon-new-drawing:before {
  content: "";
}

.bp4-icon-signal-search:before {
  content: "";
}

.bp4-icon-bring-data:before {
  content: "";
}

.bp4-icon-tractor:before {
  content: "";
}

.bp4-icon-truck:before {
  content: "";
}

.bp4-icon-diagnosis:before {
  content: "";
}

.bp4-icon-lab-test:before {
  content: "";
}

.bp4-icon-virus:before {
  content: "";
}

.bp4-icon-inherited-group:before {
  content: "";
}

.bp4-icon-hat:before {
  content: "";
}

.bp4-icon-cycle:before {
  content: "";
}

.bp4-icon-route:before {
  content: "";
}

.bp4-icon-modal:before {
  content: "";
}

.bp4-icon-modal-filled:before {
  content: "";
}

.bp4-icon-drawer-left:before {
  content: "";
}

.bp4-icon-drawer-left-filled:before {
  content: "";
}

.bp4-icon-drawer-right:before {
  content: "";
}

.bp4-icon-drawer-right-filled:before {
  content: "";
}

.bp4-icon-app-header:before {
  content: "";
}

.bp4-icon-send-message:before {
  content: "";
}

.bp4-icon-backlink:before {
  content: "";
}

.bp4-icon-geofence:before {
  content: "";
}

.bp4-icon-data-connection:before {
  content: "";
}

.bp4-icon-switch:before {
  content: "";
}

.bp4-icon-array:before {
  content: "";
}

.bp4-icon-array-boolean:before {
  content: "";
}

.bp4-icon-array-date:before {
  content: "";
}

.bp4-icon-array-numeric:before {
  content: "";
}

.bp4-icon-array-string:before {
  content: "";
}

.bp4-icon-array-timestamp:before {
  content: "";
}

.bp4-icon-layer-outline:before {
  content: "";
}

.bp4-icon-notifications-snooze:before {
  content: "";
}

.bp4-icon-high-priority:before {
  content: "";
}

.bp4-icon-emoji:before {
  content: "";
}

.bp4-icon-add-location:before {
  content: "";
}

.bp4-icon-shapes:before {
  content: "";
}

.bp4-icon-shared-filter:before {
  content: "";
}

.bp4-icon-one-to-one:before {
  content: "";
}

.bp4-icon-one-to-many:before {
  content: "";
}

.bp4-icon-many-to-one:before {
  content: "";
}

.bp4-icon-many-to-many:before {
  content: "";
}

.bp4-icon-stadium-geometry:before {
  content: "";
}

.bp4-icon-area-of-interest:before {
  content: "";
}

.bp4-icon-buggy:before {
  content: "";
}

.bp4-icon-antenna:before {
  content: "";
}

.bp4-icon-tank:before {
  content: "";
}

.bp4-icon-third-party:before {
  content: "";
}

.bp4-icon-rocket:before {
  content: "";
}

.bp4-icon-rocket-slant:before {
  content: "";
}

.bp4-icon-header-three:before {
  content: "";
}

.bp4-icon-helicopter:before {
  content: "";
}

.bp4-icon-syringe:before {
  content: "";
}

.bp4-icon-temperature:before {
  content: "";
}

.bp4-icon-waves:before {
  content: "";
}

.bp4-icon-rain:before {
  content: "";
}

.bp4-icon-lightning:before {
  content: "";
}

.bp4-icon-wind:before {
  content: "";
}

.bp4-icon-hurricane:before {
  content: "";
}

.bp4-icon-nest:before {
  content: "";
}

.bp4-icon-target:before {
  content: "";
}

.bp4-icon-small-square:before {
  content: "";
}

.bp4-icon-ship:before {
  content: "";
}

.bp4-icon-cargo-ship:before {
  content: "";
}

.bp4-icon-clip:before {
  content: "";
}

.bp4-icon-add-clip:before {
  content: "";
}

.bp4-icon-rectangle:before {
  content: "";
}

.bp4-icon-symbol-rectangle:before {
  content: "";
}

.bp4-icon-fuel:before {
  content: "";
}

.bp4-icon-playbook:before {
  content: "";
}

.bp4-icon-rect-height:before {
  content: "";
}

.bp4-icon-rect-width:before {
  content: "";
}

.bp4-icon-divide:before {
  content: "";
}

.bp4-icon-color-fill:before {
  content: "";
}

.bp4-icon-horizontal-inbetween:before {
  content: "";
}

.bp4-icon-vertical-inbetween:before {
  content: "";
}

.bp4-icon-open-application:before {
  content: "";
}

.bp4-submenu > .bp4-popover-wrapper, .bp4-submenu .bp4-popover-target {
  display: block;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]) {
  color: inherit;
  cursor: pointer;
  background-color: #8f99a826;
  text-decoration: none;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected .bp4-menu-item-label {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected .bp4-submenu-icon {
  color: #215db0;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]) {
  color: inherit;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]) .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]) .bp4-submenu-icon {
  color: #abb3bf;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected .bp4-submenu-icon {
  color: #8abbff;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item:not([class*="bp4-intent-"]).ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"], .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"] .bp4-menu-item-label, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover .bp4-menu-item-label, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active .bp4-menu-item-label {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"] .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"] .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active .bp4-submenu-icon {
  color: #215db0;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger:before, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger .bp4-menu-item-icon, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"], .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"] .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"] .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active .bp4-submenu-icon {
  color: #8abbff;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"].bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger:before, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-menu-item[class*="bp4-intent-"]:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-submenu.bp4-popover {
  box-shadow: none;
  padding: 0 5px;
}

.bp4-submenu.bp4-popover > .bp4-popover-content {
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-dark .bp4-submenu.bp4-popover, .bp4-submenu.bp4-popover.bp4-dark {
  box-shadow: none;
}

.bp4-dark .bp4-submenu.bp4-popover > .bp4-popover-content, .bp4-submenu.bp4-popover.bp4-dark > .bp4-popover-content {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-menu {
  color: #1c2127;
  min-width: 180px;
  text-align: left;
  background: #fff;
  border-radius: 3px;
  margin: 0;
  padding: 5px;
  list-style: none;
}

.bp4-menu-divider {
  border-top: 1px solid #11141826;
  margin: 5px;
  display: block;
}

.bp4-dark .bp4-menu-divider {
  border-top-color: #ffffff26;
}

.bp4-menu-item {
  color: inherit;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 2px;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 7px;
  line-height: 20px;
  text-decoration: none;
  display: flex;
}

.bp4-menu-item > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-menu-item > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-menu-item:before, .bp4-menu-item > * {
  margin-right: 7px;
}

.bp4-menu-item:empty:before, .bp4-menu-item > :last-child {
  margin-right: 0;
}

.bp4-menu-item > .bp4-fill {
  word-break: break-word;
}

.bp4-menu-item .bp4-menu-item-icon {
  height: 20px;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.bp4-menu-item .bp4-menu-item-label, .bp4-menu-item:before, .bp4-menu-item .bp4-menu-item-icon, .bp4-menu-item .bp4-submenu-icon {
  color: #5f6b7c;
}

.bp4-menu-item:before, .bp4-menu-item .bp4-submenu-icon {
  margin-top: 2px;
}

.bp4-menu-item:hover {
  color: inherit;
  cursor: pointer;
  background-color: #8f99a826;
  text-decoration: none;
}

.bp4-menu-item:hover.ns-selected {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp4-menu-item:hover.ns-selected .bp4-menu-item-label {
  color: inherit;
}

.bp4-menu-item:hover.ns-selected:before, .bp4-menu-item:hover.ns-selected .bp4-menu-item-icon, .bp4-menu-item:hover.ns-selected .bp4-submenu-icon {
  color: #215db0;
}

.bp4-menu-item:hover.ns-selected.bp4-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp4-menu-item:hover.ns-selected.bp4-intent-success:before, .bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-menu-item-icon, .bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-menu-item:hover.ns-selected.bp4-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp4-menu-item:hover.ns-selected.bp4-intent-warning:before, .bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-menu-item-icon, .bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-menu-item:hover.ns-selected.bp4-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp4-menu-item:hover.ns-selected.bp4-intent-danger:before, .bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-menu-item-icon, .bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-menu-item:active, .bp4-menu-item.bp4-active {
  background-color: #8f99a84d;
}

.bp4-menu-item:active .bp4-menu-item-label, .bp4-menu-item.bp4-active .bp4-menu-item-label {
  color: #1c2127;
}

.bp4-menu-item.bp4-selected, .bp4-menu-item.bp4-selected:hover, .bp4-menu-item.bp4-selected:active {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp4-menu-item.bp4-selected .bp4-menu-item-label, .bp4-menu-item.bp4-selected:hover .bp4-menu-item-label, .bp4-menu-item.bp4-selected:active .bp4-menu-item-label {
  color: inherit;
}

.bp4-menu-item.bp4-selected:before, .bp4-menu-item.bp4-selected .bp4-menu-item-icon, .bp4-menu-item.bp4-selected .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover:before, .bp4-menu-item.bp4-selected:hover .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:hover .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active:before, .bp4-menu-item.bp4-selected:active .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:active .bp4-submenu-icon {
  color: #215db0;
}

.bp4-menu-item.bp4-selected.bp4-intent-success, .bp4-menu-item.bp4-selected:hover.bp4-intent-success, .bp4-menu-item.bp4-selected:active.bp4-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp4-menu-item.bp4-selected.bp4-intent-success:before, .bp4-menu-item.bp4-selected.bp4-intent-success .bp4-menu-item-icon, .bp4-menu-item.bp4-selected.bp4-intent-success .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-success:before, .bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-success:before, .bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-menu-item.bp4-selected.bp4-intent-warning, .bp4-menu-item.bp4-selected:hover.bp4-intent-warning, .bp4-menu-item.bp4-selected:active.bp4-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp4-menu-item.bp4-selected.bp4-intent-warning:before, .bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-menu-item-icon, .bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-warning:before, .bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-warning:before, .bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-menu-item.bp4-selected.bp4-intent-danger, .bp4-menu-item.bp4-selected:hover.bp4-intent-danger, .bp4-menu-item.bp4-selected:active.bp4-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp4-menu-item.bp4-selected.bp4-intent-danger:before, .bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-menu-item-icon, .bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-submenu-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-danger:before, .bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-danger:before, .bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-menu-item-icon, .bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-menu-item.bp4-disabled {
  background-color: inherit !important;
  color: #5f6b7c99 !important;
  cursor: not-allowed !important;
  outline: none !important;
}

.bp4-menu-item.bp4-disabled:before, .bp4-menu-item.bp4-disabled .bp4-menu-item-icon, .bp4-menu-item.bp4-disabled .bp4-submenu-icon, .bp4-menu-item.bp4-disabled .bp4-menu-item-label {
  color: #5f6b7c99 !important;
}

.bp4-menu-item.bp4-intent-primary {
  color: #215db0;
}

.bp4-menu-item.bp4-intent-primary:before, .bp4-menu-item.bp4-intent-primary .bp4-menu-item-icon, .bp4-menu-item.bp4-intent-primary .bp4-submenu-icon, .bp4-menu-item.bp4-intent-primary .bp4-menu-item-label {
  color: inherit;
}

.bp4-menu-item.bp4-intent-primary:hover {
  background-color: #2d72d21a;
}

.bp4-menu-item.bp4-intent-primary:active, .bp4-menu-item.bp4-intent-primary.bp4-active {
  color: #184a90;
  background-color: #2d72d233;
}

.bp4-menu-item.bp4-intent-success {
  color: #1c6e42;
}

.bp4-menu-item.bp4-intent-success:before, .bp4-menu-item.bp4-intent-success .bp4-menu-item-icon, .bp4-menu-item.bp4-intent-success .bp4-submenu-icon, .bp4-menu-item.bp4-intent-success .bp4-menu-item-label {
  color: inherit;
}

.bp4-menu-item.bp4-intent-success:hover {
  background-color: #2385511a;
}

.bp4-menu-item.bp4-intent-success:active, .bp4-menu-item.bp4-intent-success.bp4-active {
  color: #165a36;
  background-color: #23855133;
}

.bp4-menu-item.bp4-intent-warning {
  color: #935610;
}

.bp4-menu-item.bp4-intent-warning:before, .bp4-menu-item.bp4-intent-warning .bp4-menu-item-icon, .bp4-menu-item.bp4-intent-warning .bp4-submenu-icon, .bp4-menu-item.bp4-intent-warning .bp4-menu-item-label {
  color: inherit;
}

.bp4-menu-item.bp4-intent-warning:hover {
  background-color: #c876191a;
}

.bp4-menu-item.bp4-intent-warning:active, .bp4-menu-item.bp4-intent-warning.bp4-active {
  color: #77450d;
  background-color: #c8761933;
}

.bp4-menu-item.bp4-intent-danger {
  color: #ac2f33;
}

.bp4-menu-item.bp4-intent-danger:before, .bp4-menu-item.bp4-intent-danger .bp4-menu-item-icon, .bp4-menu-item.bp4-intent-danger .bp4-submenu-icon, .bp4-menu-item.bp4-intent-danger .bp4-menu-item-label {
  color: inherit;
}

.bp4-menu-item.bp4-intent-danger:hover {
  background-color: #cd42461a;
}

.bp4-menu-item.bp4-intent-danger:active, .bp4-menu-item.bp4-intent-danger.bp4-active {
  color: #8e292c;
  background-color: #cd424633;
}

.bp4-menu-item:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 7px;
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp4-large .bp4-menu-item {
  padding: 9px 7px;
  font-size: 16px;
  line-height: 22px;
}

.bp4-large .bp4-menu-item .bp4-menu-item-icon {
  height: 22px;
}

.bp4-large .bp4-menu-item:before, .bp4-large .bp4-menu-item .bp4-submenu-icon {
  margin-top: 3px;
}

.bp4-large .bp4-menu-item:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin-right: 10px;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

button.bp4-menu-item {
  text-align: left;
  width: 100%;
  background: none;
  border: none;
}

.bp4-menu-header {
  cursor: default;
  border-top: 1px solid #11141826;
  margin: 5px;
  padding-left: 2px;
  display: block;
}

.bp4-dark .bp4-menu-header {
  border-top-color: #ffffff26;
}

.bp4-menu-header:first-of-type {
  border-top: none;
}

.bp4-menu-header > h6 {
  color: #1c2127;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  margin: 0;
  padding: 10px 7px 0 1px;
  font-weight: 600;
  line-height: 17px;
  overflow: hidden;
}

.bp4-dark .bp4-menu-header > h6 {
  color: #f6f7f9;
}

.bp4-menu-header:first-of-type > h6 {
  padding-top: 0;
}

.bp4-large .bp4-menu-header > h6 {
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 18px;
}

.bp4-large .bp4-menu-header:first-of-type > h6 {
  padding-top: 0;
}

.bp4-dark .bp4-menu {
  color: #f6f7f9;
  background: #383e47;
}

.bp4-dark .bp4-menu-item {
  color: inherit;
}

.bp4-dark .bp4-menu-item .bp4-menu-item-label, .bp4-dark .bp4-menu-item:before, .bp4-dark .bp4-menu-item .bp4-menu-item-icon, .bp4-dark .bp4-menu-item .bp4-submenu-icon {
  color: #abb3bf;
}

.bp4-dark .bp4-menu-item:hover {
  color: inherit;
}

.bp4-dark .bp4-menu-item:hover .bp4-menu-item-icon, .bp4-dark .bp4-menu-item:hover .bp4-submenu-icon {
  color: #abb3bf;
}

.bp4-dark .bp4-menu-item:hover.ns-selected {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp4-dark .bp4-menu-item:hover.ns-selected:before, .bp4-dark .bp4-menu-item:hover.ns-selected .bp4-menu-item-icon, .bp4-dark .bp4-menu-item:hover.ns-selected .bp4-submenu-icon {
  color: #8abbff;
}

.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success:before, .bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning:before, .bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger:before, .bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-menu-item:hover.ns-selected.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-menu-item:active .bp4-menu-item-label, .bp4-dark .bp4-menu-item.bp4-active .bp4-menu-item-label {
  color: #f6f7f9;
}

.bp4-dark .bp4-menu-item.bp4-selected, .bp4-dark .bp4-menu-item.bp4-selected:hover, .bp4-dark .bp4-menu-item.bp4-selected:active {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp4-dark .bp4-menu-item.bp4-selected:before, .bp4-dark .bp4-menu-item.bp4-selected .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover:before, .bp4-dark .bp4-menu-item.bp4-selected:hover .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active:before, .bp4-dark .bp4-menu-item.bp4-selected:active .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:active .bp4-submenu-icon {
  color: #8abbff;
}

.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success:before, .bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success:before, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success:before, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-success .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning:before, .bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning:before, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning:before, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-warning .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger:before, .bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger:before, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:hover.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger:before, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-selected:active.bp4-intent-danger .bp4-submenu-icon {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-disabled, .bp4-dark .bp4-menu-item.bp4-disabled:before, .bp4-dark .bp4-menu-item.bp4-disabled .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-disabled .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-disabled .bp4-menu-item-label {
  color: #abb3bf99 !important;
}

.bp4-dark .bp4-menu-item.bp4-intent-primary {
  color: #8abbff;
}

.bp4-dark .bp4-menu-item.bp4-intent-primary:before, .bp4-dark .bp4-menu-item.bp4-intent-primary .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-intent-primary .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-intent-primary .bp4-menu-item-label {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-intent-primary:hover {
  background-color: #2d72d233;
}

.bp4-dark .bp4-menu-item.bp4-intent-primary:active, .bp4-dark .bp4-menu-item.bp4-intent-primary.bp4-active {
  color: #99c4ff;
  background-color: #2d72d24d;
}

.bp4-dark .bp4-menu-item.bp4-intent-success {
  color: #72ca9b;
}

.bp4-dark .bp4-menu-item.bp4-intent-success:before, .bp4-dark .bp4-menu-item.bp4-intent-success .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-intent-success .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-intent-success .bp4-menu-item-label {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-intent-success:hover {
  background-color: #23855133;
}

.bp4-dark .bp4-menu-item.bp4-intent-success:active, .bp4-dark .bp4-menu-item.bp4-intent-success.bp4-active {
  color: #7cd7a2;
  background-color: #2385514d;
}

.bp4-dark .bp4-menu-item.bp4-intent-warning {
  color: #fbb360;
}

.bp4-dark .bp4-menu-item.bp4-intent-warning:before, .bp4-dark .bp4-menu-item.bp4-intent-warning .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-intent-warning .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-intent-warning .bp4-menu-item-label {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-intent-warning:hover {
  background-color: #c8761933;
}

.bp4-dark .bp4-menu-item.bp4-intent-warning:active, .bp4-dark .bp4-menu-item.bp4-intent-warning.bp4-active {
  color: #f5c186;
  background-color: #c876194d;
}

.bp4-dark .bp4-menu-item.bp4-intent-danger {
  color: #fa999c;
}

.bp4-dark .bp4-menu-item.bp4-intent-danger:before, .bp4-dark .bp4-menu-item.bp4-intent-danger .bp4-menu-item-icon, .bp4-dark .bp4-menu-item.bp4-intent-danger .bp4-submenu-icon, .bp4-dark .bp4-menu-item.bp4-intent-danger .bp4-menu-item-label {
  color: inherit;
}

.bp4-dark .bp4-menu-item.bp4-intent-danger:hover {
  background-color: #cd424633;
}

.bp4-dark .bp4-menu-item.bp4-intent-danger:active, .bp4-dark .bp4-menu-item.bp4-intent-danger.bp4-active {
  color: #ffa1a4;
  background-color: #cd42464d;
}

.bp4-dark .bp4-menu-divider, .bp4-dark .bp4-menu-header {
  border-color: #ffffff26;
}

.bp4-dark .bp4-menu-header > h6 {
  color: #f6f7f9;
}

.bp4-label .bp4-menu {
  margin-top: 5px;
}

.bp4-navbar {
  height: 50px;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  box-shadow: 0 0 0 1px #1114181a, 0 0 #11141800, 0 1px 1px #11141833;
}

.bp4-navbar.bp4-dark, .bp4-dark .bp4-navbar {
  background-color: #404854;
}

.bp4-navbar.bp4-dark {
  box-shadow: inset 0 0 0 1px #11141833, 0 0 #11141800, 0 1px 1px #11141866;
}

.bp4-dark .bp4-navbar {
  box-shadow: 0 0 0 1px #11141833, 0 0 #11141800, 0 1px 1px #11141866;
}

.bp4-navbar.bp4-fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.bp4-navbar-heading {
  margin-right: 15px;
  font-size: 16px;
}

.bp4-navbar-group {
  height: 50px;
  align-items: center;
  display: flex;
}

.bp4-navbar-group.bp4-align-left {
  float: left;
}

.bp4-navbar-group.bp4-align-right {
  float: right;
}

.bp4-navbar-divider {
  height: 20px;
  border-left: 1px solid #11141826;
  margin: 0 10px;
}

.bp4-dark .bp4-navbar-divider {
  border-left-color: #ffffff26;
}

.bp4-non-ideal-state {
  height: 100%;
  text-align: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bp4-non-ideal-state > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-non-ideal-state > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-non-ideal-state:before, .bp4-non-ideal-state > * {
  margin-bottom: 20px;
}

.bp4-non-ideal-state:empty:before, .bp4-non-ideal-state > :last-child {
  margin-bottom: 0;
}

.bp4-non-ideal-state > * {
  max-width: 400px;
}

.bp4-non-ideal-state-visual {
  color: #5f6b7c99;
  font-size: 60px;
}

.bp4-dark .bp4-non-ideal-state-visual {
  color: #abb3bf99;
}

.bp4-overflow-list {
  min-width: 0;
  flex-wrap: nowrap;
  display: flex;
}

.bp4-overflow-list-spacer {
  width: 1px;
  flex-shrink: 1;
}

body.bp4-overlay-open {
  overflow: hidden;
}

.bp4-overlay {
  z-index: 20;
  position: static;
  inset: 0;
}

.bp4-overlay:not(.bp4-overlay-open) {
  pointer-events: none;
}

.bp4-overlay.bp4-overlay-container {
  position: fixed;
  overflow: hidden;
}

.bp4-overlay.bp4-overlay-container.bp4-overlay-inline {
  position: absolute;
}

.bp4-overlay.bp4-overlay-scroll-container {
  position: fixed;
  overflow: auto;
}

.bp4-overlay.bp4-overlay-scroll-container.bp4-overlay-inline {
  position: absolute;
}

.bp4-overlay.bp4-overlay-inline {
  display: inline;
  overflow: visible;
}

.bp4-overlay-content {
  z-index: 20;
  position: fixed;
}

.bp4-overlay-inline .bp4-overlay-content, .bp4-overlay-scroll-container .bp4-overlay-content {
  position: absolute;
}

.bp4-overlay-backdrop {
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
  z-index: 20;
  background-color: #111418b3;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.bp4-overlay-backdrop.bp4-overlay-enter, .bp4-overlay-backdrop.bp4-overlay-appear {
  opacity: 0;
}

.bp4-overlay-backdrop.bp4-overlay-enter-active, .bp4-overlay-backdrop.bp4-overlay-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-overlay-backdrop.bp4-overlay-exit {
  opacity: 1;
}

.bp4-overlay-backdrop.bp4-overlay-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-overlay-backdrop:focus {
  outline: none;
}

.bp4-overlay-inline .bp4-overlay-backdrop {
  position: absolute;
}

.bp4-panel-stack {
  position: relative;
  overflow: hidden;
}

.bp4-panel-stack-header {
  height: 30px;
  z-index: 1;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  box-shadow: 0 1px #11141826;
}

.bp4-dark .bp4-panel-stack-header {
  box-shadow: 0 1px #ffffff26;
}

.bp4-panel-stack-header > span {
  flex: 1;
  align-items: stretch;
  display: flex;
}

.bp4-panel-stack-header .bp4-heading {
  margin: 0 5px;
}

.bp4-button.bp4-panel-stack-header-back {
  white-space: nowrap;
  margin-left: 5px;
  padding-left: 0;
}

.bp4-button.bp4-panel-stack-header-back .bp4-icon {
  margin: 0 2px;
}

.bp4-panel-stack-view {
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #11141826;
  flex-direction: column;
  margin-right: -1px;
  display: flex;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.bp4-dark .bp4-panel-stack-view {
  background-color: #383e47;
}

.bp4-panel-stack-view:nth-last-child(n+4) {
  display: none;
}

.bp4-panel-stack-push .bp4-panel-stack-enter, .bp4-panel-stack-push .bp4-panel-stack-appear {
  opacity: 0;
  transform: translateX(100%);
}

.bp4-panel-stack-push .bp4-panel-stack-enter-active, .bp4-panel-stack-push .bp4-panel-stack-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp4-panel-stack-push .bp4-panel-stack-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp4-panel-stack-push .bp4-panel-stack-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(-50%);
}

.bp4-panel-stack-pop .bp4-panel-stack-enter, .bp4-panel-stack-pop .bp4-panel-stack-appear {
  opacity: 0;
  transform: translateX(-50%);
}

.bp4-panel-stack-pop .bp4-panel-stack-enter-active, .bp4-panel-stack-pop .bp4-panel-stack-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp4-panel-stack-pop .bp4-panel-stack-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp4-panel-stack-pop .bp4-panel-stack-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(100%);
}

.bp4-panel-stack2 {
  position: relative;
  overflow: hidden;
}

.bp4-panel-stack2-header {
  height: 30px;
  z-index: 1;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  box-shadow: 0 1px #11141826;
}

.bp4-dark .bp4-panel-stack2-header {
  box-shadow: 0 1px #ffffff26;
}

.bp4-panel-stack2-header > span {
  flex: 1;
  align-items: stretch;
  display: flex;
}

.bp4-panel-stack2-header .bp4-heading {
  margin: 0 5px;
}

.bp4-button.bp4-panel-stack2-header-back {
  white-space: nowrap;
  margin-left: 5px;
  padding-left: 0;
}

.bp4-button.bp4-panel-stack2-header-back .bp4-icon {
  margin: 0 2px;
}

.bp4-panel-stack2-view {
  z-index: 1;
  background-color: #fff;
  border-right: 1px solid #11141826;
  flex-direction: column;
  margin-right: -1px;
  display: flex;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

.bp4-dark .bp4-panel-stack2-view {
  background-color: #383e47;
}

.bp4-panel-stack2-view:nth-last-child(n+4) {
  display: none;
}

.bp4-panel-stack2-push .bp4-panel-stack2-enter, .bp4-panel-stack2-push .bp4-panel-stack2-appear {
  opacity: 0;
  transform: translateX(100%);
}

.bp4-panel-stack2-push .bp4-panel-stack2-enter-active, .bp4-panel-stack2-push .bp4-panel-stack2-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp4-panel-stack2-push .bp4-panel-stack2-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp4-panel-stack2-push .bp4-panel-stack2-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(-50%);
}

.bp4-panel-stack2-pop .bp4-panel-stack2-enter, .bp4-panel-stack2-pop .bp4-panel-stack2-appear {
  opacity: 0;
  transform: translateX(-50%);
}

.bp4-panel-stack2-pop .bp4-panel-stack2-enter-active, .bp4-panel-stack2-pop .bp4-panel-stack2-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translate(0%);
}

.bp4-panel-stack2-pop .bp4-panel-stack2-exit {
  opacity: 1;
  transform: translate(0%);
}

.bp4-panel-stack2-pop .bp4-panel-stack2-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease;
  transform: translateX(100%);
}

.bp4-popover {
  z-index: 20;
  border-radius: 3px;
  display: inline-block;
  transform: scale(1);
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-popover .bp4-popover-arrow {
  height: 30px;
  width: 30px;
  position: absolute;
}

.bp4-popover .bp4-popover-arrow:before {
  height: 20px;
  width: 20px;
  margin: 5px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-popover {
  margin-top: -17px;
  margin-bottom: 17px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-popover > .bp4-popover-arrow {
  bottom: -11px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(-90deg);
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-popover {
  margin-left: 17px;
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-popover > .bp4-popover-arrow {
  left: -11px;
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(0);
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-popover {
  margin-top: 17px;
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-popover > .bp4-popover-arrow {
  top: -11px;
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(90deg);
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-popover {
  margin-left: -17px;
  margin-right: 17px;
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-popover > .bp4-popover-arrow {
  right: -11px;
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-popover > .bp4-popover-arrow svg {
  transform: rotate(180deg);
}

.bp4-tether-element-attached-middle > .bp4-popover > .bp4-popover-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.bp4-tether-element-attached-center > .bp4-popover > .bp4-popover-arrow {
  right: 50%;
  transform: translateX(50%);
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-top > .bp4-popover > .bp4-popover-arrow {
  top: -.393398px;
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-right > .bp4-popover > .bp4-popover-arrow {
  right: -.393398px;
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-left > .bp4-popover > .bp4-popover-arrow {
  left: -.393398px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-bottom > .bp4-popover > .bp4-popover-arrow {
  bottom: -.393398px;
}

.bp4-tether-element-attached-top.bp4-tether-element-attached-left > .bp4-popover {
  transform-origin: 0 0;
}

.bp4-tether-element-attached-top.bp4-tether-element-attached-center > .bp4-popover {
  transform-origin: top;
}

.bp4-tether-element-attached-top.bp4-tether-element-attached-right > .bp4-popover {
  transform-origin: 100% 0;
}

.bp4-tether-element-attached-middle.bp4-tether-element-attached-left > .bp4-popover {
  transform-origin: 0;
}

.bp4-tether-element-attached-middle.bp4-tether-element-attached-center > .bp4-popover {
  transform-origin: center;
}

.bp4-tether-element-attached-middle.bp4-tether-element-attached-right > .bp4-popover {
  transform-origin: 100%;
}

.bp4-tether-element-attached-bottom.bp4-tether-element-attached-left > .bp4-popover {
  transform-origin: 0 100%;
}

.bp4-tether-element-attached-bottom.bp4-tether-element-attached-center > .bp4-popover {
  transform-origin: bottom;
}

.bp4-tether-element-attached-bottom.bp4-tether-element-attached-right > .bp4-popover {
  transform-origin: 100% 100%;
}

.bp4-popover .bp4-popover-content {
  background: #fff;
}

.bp4-popover .bp4-popover-content, .bp4-popover .bp4-heading {
  color: inherit;
}

.bp4-popover .bp4-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141833;
}

.bp4-popover .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: .1;
}

.bp4-popover .bp4-popover-arrow-fill {
  fill: #fff;
}

.bp4-popover-enter > .bp4-popover, .bp4-popover-appear > .bp4-popover {
  transform: scale(.3);
}

.bp4-popover-enter-active > .bp4-popover, .bp4-popover-appear-active > .bp4-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(1);
}

.bp4-popover-exit > .bp4-popover {
  transform: scale(1);
}

.bp4-popover-exit-active > .bp4-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.3);
}

.bp4-popover .bp4-popover-content {
  border-radius: 3px;
  position: relative;
}

.bp4-popover.bp4-popover-content-sizing .bp4-popover-content {
  max-width: 350px;
  padding: 20px;
}

.bp4-popover-target + .bp4-overlay .bp4-popover.bp4-popover-content-sizing {
  width: 350px;
}

.bp4-popover.bp4-minimal {
  margin: 0 !important;
}

.bp4-popover.bp4-minimal .bp4-popover-arrow {
  display: none;
}

.bp4-popover.bp4-minimal.bp4-popover, .bp4-popover-enter > .bp4-popover.bp4-minimal.bp4-popover, .bp4-popover-appear > .bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
}

.bp4-popover-enter-active > .bp4-popover.bp4-minimal.bp4-popover, .bp4-popover-appear-active > .bp4-popover.bp4-minimal.bp4-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp4-popover-exit > .bp4-popover.bp4-minimal.bp4-popover {
  transform: scale(1);
}

.bp4-popover-exit-active > .bp4-popover.bp4-minimal.bp4-popover {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp4-popover.bp4-dark, .bp4-dark .bp4-popover {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-popover.bp4-dark .bp4-popover-content, .bp4-dark .bp4-popover .bp4-popover-content {
  background: #383e47;
}

.bp4-popover.bp4-dark .bp4-popover-content, .bp4-popover.bp4-dark .bp4-heading, .bp4-dark .bp4-popover .bp4-popover-content, .bp4-dark .bp4-popover .bp4-heading {
  color: inherit;
}

.bp4-popover.bp4-dark .bp4-popover-arrow:before, .bp4-dark .bp4-popover .bp4-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141866;
}

.bp4-popover.bp4-dark .bp4-popover-arrow-border, .bp4-dark .bp4-popover .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: .2;
}

.bp4-popover.bp4-dark .bp4-popover-arrow-fill, .bp4-dark .bp4-popover .bp4-popover-arrow-fill {
  fill: #383e47;
}

.bp4-popover-arrow:before {
  content: "";
  border-radius: 2px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.bp4-tether-pinned .bp4-popover-arrow {
  display: none;
}

.bp4-popover-backdrop {
  background: #fff0;
}

.bp4-transition-container {
  opacity: 1;
  z-index: 20;
  display: flex;
}

.bp4-transition-container.bp4-popover-enter, .bp4-transition-container.bp4-popover-appear {
  opacity: 0;
}

.bp4-transition-container.bp4-popover-enter-active, .bp4-transition-container.bp4-popover-appear-active {
  opacity: 1;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-transition-container.bp4-popover-exit {
  opacity: 1;
}

.bp4-transition-container.bp4-popover-exit-active {
  opacity: 0;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-transition-container:focus {
  outline: none;
}

.bp4-transition-container.bp4-popover-leave .bp4-popover-content {
  pointer-events: none;
}

.bp4-transition-container[data-x-out-of-boundaries] {
  display: none;
}

span.bp4-popover-target {
  display: inline-block;
}

.bp4-popover-wrapper.bp4-fill {
  width: 100%;
}

.bp4-portal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@keyframes linear-progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 30px 0;
  }
}

.bp4-progress-bar {
  height: 8px;
  width: 100%;
  background: #5f6b7c33;
  border-radius: 40px;
  display: block;
  position: relative;
  overflow: hidden;
}

.bp4-progress-bar .bp4-progress-meter {
  height: 100%;
  width: 100%;
  background: #5f6b7ccc linear-gradient(-45deg, #fff3 25%, #0000 25% 50%, #fff3 50% 75%, #0000 75%) 0 0 / 30px 30px;
  border-radius: 40px;
  transition: width .2s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
}

.bp4-progress-bar:not(.bp4-no-animation):not(.bp4-no-stripes) .bp4-progress-meter {
  animation: linear-progress-bar-stripes .3s linear infinite reverse;
}

.bp4-progress-bar.bp4-no-stripes .bp4-progress-meter {
  background-image: none;
}

.bp4-dark .bp4-progress-bar {
  background: #11141880;
}

.bp4-dark .bp4-progress-bar .bp4-progress-meter {
  background-color: #8f99a8;
}

.bp4-progress-bar.bp4-intent-primary .bp4-progress-meter {
  background-color: #2d72d2;
}

.bp4-progress-bar.bp4-intent-success .bp4-progress-meter {
  background-color: #238551;
}

.bp4-progress-bar.bp4-intent-warning .bp4-progress-meter {
  background-color: #c87619;
}

.bp4-progress-bar.bp4-intent-danger .bp4-progress-meter {
  background-color: #cd4246;
}

@keyframes skeleton-glow {
  from {
    background: #d3d8de33;
    border-color: #d3d8de33;
  }

  to {
    background: #5f6b7c33;
    border-color: #5f6b7c33;
  }
}

.bp4-skeleton {
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  background: #d3d8de33;
  border-radius: 2px;
  animation: skeleton-glow 1s linear infinite alternate;
  box-shadow: none !important;
  color: #0000 !important;
  background-clip: padding-box !important;
  border-color: #d3d8de33 !important;
}

.bp4-skeleton:before, .bp4-skeleton:after, .bp4-skeleton * {
  visibility: hidden !important;
}

.bp4-slider {
  height: 40px;
  min-width: 150px;
  width: 100%;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  outline: none;
  position: relative;
}

.bp4-slider:hover {
  cursor: pointer;
}

.bp4-slider:active {
  cursor: grabbing;
}

.bp4-slider.bp4-disabled {
  cursor: not-allowed;
  opacity: .5;
}

.bp4-slider.bp4-slider-unlabeled {
  height: 16px;
}

.bp4-slider-track, .bp4-slider-progress {
  height: 6px;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
}

.bp4-slider-track {
  border-radius: 3px;
  overflow: hidden;
}

.bp4-slider-progress {
  background: #5f6b7c33;
}

.bp4-dark .bp4-slider-progress {
  background: #11141880;
}

.bp4-slider-progress.bp4-intent-primary {
  background-color: #2d72d2;
}

.bp4-slider-progress.bp4-intent-success {
  background-color: #238551;
}

.bp4-slider-progress.bp4-intent-warning {
  background-color: #c87619;
}

.bp4-slider-progress.bp4-intent-danger {
  background-color: #cd4246;
}

.bp4-slider-handle {
  color: #1c2127;
  cursor: pointer;
  height: 16px;
  width: 16px;
  background-color: #f6f7f9;
  background-image: linear-gradient(#11141800, #1114180d);
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 0 1px #11141880, 0 1px 1px #11141880;
}

.bp4-slider-handle:hover {
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 -1px #1114181a;
}

.bp4-slider-handle:active, .bp4-slider-handle.bp4-active {
  background-color: #dce0e5;
  background-image: none;
  box-shadow: inset 0 0 0 1px #11141833, inset 0 1px 2px #11141833;
}

.bp4-slider-handle:disabled, .bp4-slider-handle.bp4-disabled {
  box-shadow: none;
  color: #5f6b7c99;
  cursor: not-allowed;
  background-color: #d3d8de80;
  background-image: none;
  outline: none;
}

.bp4-slider-handle:disabled.bp4-active, .bp4-slider-handle:disabled.bp4-active:hover, .bp4-slider-handle.bp4-disabled.bp4-active, .bp4-slider-handle.bp4-disabled.bp4-active:hover {
  background: #d3d8deb3;
}

.bp4-slider-handle:focus {
  z-index: 1;
}

.bp4-slider-handle:hover {
  cursor: grab;
  z-index: 2;
  background-color: #edeff2;
  background-clip: padding-box;
  box-shadow: 0 0 0 1px #11141880, 0 1px 1px #11141880;
}

.bp4-slider-handle.bp4-active {
  cursor: grabbing;
  background-color: #dce0e5;
  background-image: none;
  box-shadow: 0 0 0 1px #11141833, inset 0 1px 1px #1114181a;
}

.bp4-disabled .bp4-slider-handle {
  box-shadow: none;
  pointer-events: none;
  background: #c5cbd3;
}

.bp4-dark .bp4-slider-handle {
  color: #f6f7f9;
  background-color: #404854;
  background-image: linear-gradient(#11141800, #1114180d);
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-slider-handle:hover, .bp4-dark .bp4-slider-handle:active, .bp4-dark .bp4-slider-handle.bp4-active {
  color: #f6f7f9;
}

.bp4-dark .bp4-slider-handle:hover {
  background-color: #383e47;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-slider-handle:active, .bp4-dark .bp4-slider-handle.bp4-active {
  background-color: #252a31;
  background-image: none;
  box-shadow: inset 0 0 0 1px #111418cc;
}

.bp4-dark .bp4-slider-handle:disabled, .bp4-dark .bp4-slider-handle.bp4-disabled {
  box-shadow: none;
  color: #abb3bf99;
  background-color: #40485480;
  background-image: none;
}

.bp4-dark .bp4-slider-handle:disabled.bp4-active, .bp4-dark .bp4-slider-handle.bp4-disabled.bp4-active {
  background: #404854b3;
}

.bp4-dark .bp4-slider-handle .bp4-button-spinner .bp4-spinner-head {
  stroke: #8f99a8;
  background: #11141880;
}

.bp4-dark .bp4-slider-handle, .bp4-dark .bp4-slider-handle:hover {
  background-color: #404854;
}

.bp4-dark .bp4-slider-handle.bp4-active {
  background-color: #2f343c;
}

.bp4-dark .bp4-disabled .bp4-slider-handle {
  box-shadow: none;
  background: #5f6b7c;
  border-color: #5f6b7c;
}

.bp4-slider-handle .bp4-slider-label {
  color: #f6f7f9;
  background: #404854;
  border-radius: 3px;
  margin-left: 8px;
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-dark .bp4-slider-handle .bp4-slider-label {
  color: #404854;
  background: #e5e8eb;
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-disabled .bp4-slider-handle .bp4-slider-label {
  box-shadow: none;
}

.bp4-slider-handle.bp4-start, .bp4-slider-handle.bp4-end {
  width: 8px;
}

.bp4-slider-handle.bp4-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bp4-slider-handle.bp4-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 8px;
}

.bp4-slider-handle.bp4-end .bp4-slider-label {
  margin-left: 0;
}

.bp4-slider-label {
  vertical-align: top;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  position: absolute;
  transform: translate(-50%, 20px);
}

.bp4-slider.bp4-vertical {
  height: 150px;
  min-width: 40px;
  width: 40px;
}

.bp4-slider.bp4-vertical .bp4-slider-track, .bp4-slider.bp4-vertical .bp4-slider-progress {
  height: auto;
  width: 6px;
  top: 0;
  bottom: 0;
  left: 5px;
}

.bp4-slider.bp4-vertical .bp4-slider-progress {
  top: auto;
}

.bp4-slider.bp4-vertical .bp4-slider-label {
  transform: translate(20px, 50%);
}

.bp4-slider.bp4-vertical .bp4-slider-handle {
  top: auto;
}

.bp4-slider.bp4-vertical .bp4-slider-handle .bp4-slider-label {
  margin-top: -8px;
  margin-left: 0;
}

.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-end, .bp4-slider.bp4-vertical .bp4-slider-handle.bp4-start {
  height: 8px;
  width: 16px;
  margin-left: 0;
}

.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-start {
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
}

.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-start .bp4-slider-label {
  transform: translate(20px);
}

.bp4-slider.bp4-vertical .bp4-slider-handle.bp4-end {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 8px;
}

@keyframes pt-spinner-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bp4-spinner {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
}

.bp4-spinner svg {
  display: block;
}

.bp4-spinner path {
  fill-opacity: 0;
}

.bp4-spinner .bp4-spinner-head {
  stroke: #5f6b7ccc;
  stroke-linecap: round;
  transform-origin: center;
  transition: stroke-dashoffset .2s cubic-bezier(.4, 1, .75, .9);
}

.bp4-spinner .bp4-spinner-track {
  stroke: #5f6b7c33;
}

.bp4-spinner-animation {
  animation: pt-spinner-animation .5s linear infinite;
}

.bp4-no-spin > .bp4-spinner-animation {
  animation: none;
}

.bp4-dark .bp4-spinner .bp4-spinner-head {
  stroke: #8f99a8;
}

.bp4-dark .bp4-spinner .bp4-spinner-track {
  stroke: #11141880;
}

.bp4-spinner.bp4-intent-primary .bp4-spinner-head {
  stroke: #2d72d2;
}

.bp4-spinner.bp4-intent-success .bp4-spinner-head {
  stroke: #238551;
}

.bp4-spinner.bp4-intent-warning .bp4-spinner-head {
  stroke: #c87619;
}

.bp4-spinner.bp4-intent-danger .bp4-spinner-head {
  stroke: #cd4246;
}

.bp4-tabs.bp4-vertical {
  display: flex;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list {
  flex-direction: column;
  align-items: flex-start;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab {
  width: 100%;
  border-radius: 3px;
  padding: 0 10px;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab[aria-selected="true"] {
  box-shadow: none;
  background-color: #2d72d233;
}

.bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab-indicator-wrapper .bp4-tab-indicator {
  height: auto;
  background-color: #2d72d233;
  border-radius: 3px;
  inset: 0;
}

.bp4-tabs.bp4-vertical > .bp4-tab-panel {
  margin-top: 0;
  padding-left: 20px;
}

.bp4-tab-list {
  border: none;
  flex: none;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.bp4-tab-list > :not(:last-child) {
  margin-right: 20px;
}

.bp4-tab {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #1c2127;
  cursor: pointer;
  max-width: 100%;
  vertical-align: top;
  flex: none;
  font-size: 14px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
}

.bp4-tab a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.bp4-tab-indicator-wrapper ~ .bp4-tab {
  box-shadow: none !important;
  background-color: #0000 !important;
}

.bp4-tab[aria-disabled="true"] {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp4-tab[aria-selected="true"] {
  border-radius: 0;
  box-shadow: inset 0 -3px #215db0;
}

.bp4-tab[aria-selected="true"], .bp4-tab:not([aria-disabled="true"]):hover {
  color: #215db0;
}

.bp4-tab:focus {
  -moz-outline-radius: 0;
}

.bp4-large > .bp4-tab {
  font-size: 16px;
  line-height: 40px;
}

.bp4-tab-panel {
  margin-top: 20px;
}

.bp4-tab-panel[aria-hidden="true"] {
  display: none;
}

.bp4-tab-indicator-wrapper {
  pointer-events: none;
  transform: translateX(0), translateY(0);
  transition: height .2s cubic-bezier(.4, 1, .75, .9), transform .2s cubic-bezier(.4, 1, .75, .9), width .2s cubic-bezier(.4, 1, .75, .9);
  position: absolute;
  top: 0;
  left: 0;
}

.bp4-tab-indicator-wrapper .bp4-tab-indicator {
  height: 3px;
  background-color: #215db0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.bp4-tab-indicator-wrapper.bp4-no-animation {
  transition: none;
}

.bp4-dark .bp4-tab {
  color: #f6f7f9;
}

.bp4-dark .bp4-tab[aria-disabled="true"] {
  color: #abb3bf99;
}

.bp4-dark .bp4-tab[aria-selected="true"] {
  box-shadow: inset 0 -3px #8abbff;
}

.bp4-dark .bp4-tab[aria-selected="true"], .bp4-dark .bp4-tab:not([aria-disabled="true"]):hover {
  color: #8abbff;
}

.bp4-dark .bp4-tab-indicator {
  background-color: #8abbff;
}

.bp4-flex-expander {
  flex: 1;
}

.bp4-tag {
  box-shadow: none;
  color: #f6f7f9;
  max-width: 100%;
  min-height: 20px;
  min-width: 20px;
  background-color: #5f6b7c;
  border: none;
  border-radius: 3px;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 16px;
  display: inline-flex;
  position: relative;
}

.bp4-tag.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-interactive:hover, .bp4-tag.bp4-interactive.bp4-active, .bp4-tag.bp4-interactive:active {
  background-color: #5f6b7c00;
}

.bp4-tag > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-tag > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-tag:before, .bp4-tag > * {
  margin-right: 4px;
}

.bp4-tag:empty:before, .bp4-tag > :last-child {
  margin-right: 0;
}

.bp4-tag:focus {
  outline-offset: 0;
  -moz-outline-radius: 6px;
  outline: 2px auto #2d72d299;
}

.bp4-tag.bp4-round {
  border-radius: 30px;
  padding-left: 8px;
  padding-right: 8px;
}

.bp4-dark .bp4-tag {
  color: #1c2127;
  background-color: #c5cbd3;
}

.bp4-dark .bp4-tag.bp4-interactive {
  cursor: pointer;
}

.bp4-dark .bp4-tag.bp4-interactive:hover, .bp4-dark .bp4-tag.bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-interactive:active {
  background-color: #c5cbd300;
}

.bp4-dark .bp4-tag > .bp4-icon, .bp4-dark .bp4-tag .bp4-icon-standard, .bp4-dark .bp4-tag .bp4-icon-large {
  fill: currentColor;
}

.bp4-tag > .bp4-icon, .bp4-tag .bp4-icon-standard, .bp4-tag .bp4-icon-large {
  fill: #fff;
}

.bp4-tag.bp4-large, .bp4-large .bp4-tag {
  min-height: 30px;
  min-width: 30px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 20px;
}

.bp4-tag.bp4-large:before, .bp4-tag.bp4-large > *, .bp4-large .bp4-tag:before, .bp4-large .bp4-tag > * {
  margin-right: 7px;
}

.bp4-tag.bp4-large:empty:before, .bp4-tag.bp4-large > :last-child, .bp4-large .bp4-tag:empty:before, .bp4-large .bp4-tag > :last-child {
  margin-right: 0;
}

.bp4-tag.bp4-large.bp4-round, .bp4-large .bp4-tag.bp4-round {
  padding-left: 12px;
  padding-right: 12px;
}

.bp4-tag.bp4-intent-primary {
  color: #fff;
  background: #2d72d2;
}

.bp4-tag.bp4-intent-primary.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-intent-primary.bp4-interactive:hover, .bp4-tag.bp4-intent-primary.bp4-interactive.bp4-active, .bp4-tag.bp4-intent-primary.bp4-interactive:active {
  background-color: #2d72d200;
}

.bp4-tag.bp4-intent-success {
  color: #fff;
  background: #238551;
}

.bp4-tag.bp4-intent-success.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-intent-success.bp4-interactive:hover, .bp4-tag.bp4-intent-success.bp4-interactive.bp4-active, .bp4-tag.bp4-intent-success.bp4-interactive:active {
  background-color: #23855100;
}

.bp4-tag.bp4-intent-warning {
  color: #fff;
  background: #c87619;
}

.bp4-tag.bp4-intent-warning.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-intent-warning.bp4-interactive:hover, .bp4-tag.bp4-intent-warning.bp4-interactive.bp4-active, .bp4-tag.bp4-intent-warning.bp4-interactive:active {
  background-color: #c8761900;
}

.bp4-tag.bp4-intent-danger {
  color: #fff;
  background: #cd4246;
}

.bp4-tag.bp4-intent-danger.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-intent-danger.bp4-interactive:hover, .bp4-tag.bp4-intent-danger.bp4-interactive.bp4-active, .bp4-tag.bp4-intent-danger.bp4-interactive:active {
  background-color: #cd424600;
}

.bp4-tag.bp4-fill {
  width: 100%;
  display: flex;
}

.bp4-tag.bp4-minimal > .bp4-icon, .bp4-tag.bp4-minimal .bp4-icon-standard, .bp4-tag.bp4-minimal .bp4-icon-large {
  fill: #5f6b7c;
}

.bp4-tag.bp4-minimal:not([class*="bp4-intent-"]) {
  color: #1c2127;
  background-color: #8f99a833;
}

.bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive:hover {
  background-color: #5f6b7c1a;
}

.bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive.bp4-active, .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive:active {
  background-color: #5f6b7c34;
}

.bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]) {
  color: #f6f7f9;
}

.bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive {
  cursor: pointer;
}

.bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive:hover {
  background-color: #c5cbd31a;
}

.bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]).bp4-interactive:active {
  background-color: #c5cbd334;
}

.bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]) > .bp4-icon, .bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]) .bp4-icon-standard, .bp4-dark .bp4-tag.bp4-minimal:not([class*="bp4-intent-"]) .bp4-icon-large {
  fill: #abb3bf;
}

.bp4-tag.bp4-minimal.bp4-intent-primary {
  color: #215db0;
  background-color: #2d72d226;
}

.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:hover {
  background-color: #2d72d21a;
}

.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive.bp4-active, .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:active {
  background-color: #2d72d233;
}

.bp4-tag.bp4-minimal.bp4-intent-primary > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-icon-large {
  fill: #2d72d2;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary {
  color: #8abbff;
  background-color: #2d72d240;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive {
  cursor: pointer;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:hover {
  background-color: #2d72d21a;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:active {
  background-color: #2d72d234;
}

.bp4-tag.bp4-minimal.bp4-intent-success {
  color: #1c6e42;
  background-color: #23855126;
}

.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:hover {
  background-color: #2385511a;
}

.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive.bp4-active, .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:active {
  background-color: #23855133;
}

.bp4-tag.bp4-minimal.bp4-intent-success > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-success .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-success .bp4-icon-large {
  fill: #238551;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success {
  color: #72ca9b;
  background-color: #23855140;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive {
  cursor: pointer;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:hover {
  background-color: #2385511a;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:active {
  background-color: #23855134;
}

.bp4-tag.bp4-minimal.bp4-intent-warning {
  color: #935610;
  background-color: #c8761926;
}

.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:hover {
  background-color: #c876191a;
}

.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive.bp4-active, .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:active {
  background-color: #c8761933;
}

.bp4-tag.bp4-minimal.bp4-intent-warning > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-icon-large {
  fill: #c87619;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning {
  color: #fbb360;
  background-color: #c8761940;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive {
  cursor: pointer;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:hover {
  background-color: #c876191a;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:active {
  background-color: #c8761934;
}

.bp4-tag.bp4-minimal.bp4-intent-danger {
  color: #ac2f33;
  background-color: #cd424626;
}

.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive {
  cursor: pointer;
}

.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:hover {
  background-color: #cd42461a;
}

.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive.bp4-active, .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:active {
  background-color: #cd424633;
}

.bp4-tag.bp4-minimal.bp4-intent-danger > .bp4-icon, .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-icon-standard, .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-icon-large {
  fill: #cd4246;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger {
  color: #fa999c;
  background-color: #cd424640;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive {
  cursor: pointer;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:hover {
  background-color: #cd42461a;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive.bp4-active, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:active {
  background-color: #cd424634;
}

.bp4-tag-remove {
  color: inherit;
  cursor: pointer;
  opacity: .5;
  background: none;
  border: none;
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 2px 2px 2px 0;
  display: flex;
  margin-right: -6px !important;
}

.bp4-tag-remove:hover {
  opacity: .8;
  background: none;
  text-decoration: none;
}

.bp4-tag-remove:active {
  opacity: 1;
}

.bp4-tag-remove:empty:before {
  font-variant: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  content: "";
  font-family: blueprint-icons-16, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp4-large .bp4-tag-remove {
  padding: 0 5px 0 0;
  margin-right: -10px !important;
}

.bp4-large .bp4-tag-remove:empty:before {
  font-variant: normal;
  font-family: blueprint-icons-20, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
}

.bp4-tag-input {
  cursor: text;
  height: auto;
  line-height: inherit;
  min-height: 30px;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 5px;
  padding-right: 0;
  display: flex;
}

.bp4-tag-input > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-tag-input > .bp4-tag-input-values {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-tag-input .bp4-tag-input-icon {
  color: #5f6b7c;
  margin-top: 7px;
  margin-left: 2px;
  margin-right: 7px;
}

.bp4-tag-input .bp4-tag-input-values {
  min-width: 0;
  flex-flow: wrap;
  align-self: stretch;
  align-items: center;
  margin-top: 5px;
  margin-right: 7px;
  display: flex;
}

.bp4-tag-input .bp4-tag-input-values > * {
  flex-grow: 0;
  flex-shrink: 0;
}

.bp4-tag-input .bp4-tag-input-values > .bp4-fill {
  flex-grow: 1;
  flex-shrink: 1;
}

.bp4-tag-input .bp4-tag-input-values:before, .bp4-tag-input .bp4-tag-input-values > * {
  margin-right: 5px;
}

.bp4-tag-input .bp4-tag-input-values:empty:before, .bp4-tag-input .bp4-tag-input-values > :last-child {
  margin-right: 0;
}

.bp4-tag-input .bp4-tag-input-values:first-child .bp4-input-ghost:first-child {
  padding-left: 5px;
}

.bp4-tag-input .bp4-tag-input-values > * {
  margin-bottom: 5px;
}

.bp4-tag-input .bp4-tag {
  overflow-wrap: break-word;
}

.bp4-tag-input .bp4-tag.bp4-active {
  outline-offset: 0;
  -moz-outline-radius: 6px;
  outline: 2px auto #2d72d299;
}

.bp4-tag-input .bp4-input-ghost {
  width: 80px;
  flex: auto;
  line-height: 20px;
}

.bp4-tag-input .bp4-input-ghost:disabled, .bp4-tag-input .bp4-input-ghost.bp4-disabled {
  cursor: not-allowed;
}

.bp4-tag-input .bp4-button, .bp4-tag-input .bp4-spinner {
  margin: 3px 3px 3px 0;
}

.bp4-tag-input .bp4-button {
  min-height: 24px;
  min-width: 24px;
  padding: 0 7px;
}

.bp4-tag-input.bp4-large {
  height: auto;
  min-height: 40px;
}

.bp4-tag-input.bp4-large:before, .bp4-tag-input.bp4-large > * {
  margin-right: 10px;
}

.bp4-tag-input.bp4-large:empty:before, .bp4-tag-input.bp4-large > :last-child {
  margin-right: 0;
}

.bp4-tag-input.bp4-large .bp4-tag-input-icon {
  margin-top: 10px;
  margin-left: 5px;
}

.bp4-tag-input.bp4-large .bp4-input-ghost {
  line-height: 30px;
}

.bp4-tag-input.bp4-large .bp4-button {
  min-height: 30px;
  min-width: 30px;
  margin: 5px 5px 5px 0;
  padding: 5px 10px;
}

.bp4-tag-input.bp4-large .bp4-spinner {
  margin: 8px 8px 8px 0;
}

.bp4-tag-input.bp4-active {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-tag-input.bp4-active.bp4-intent-primary {
  box-shadow: inset 0 0 0 1px #2d72d2, 0 0 0 2px #2d72d24d, inset 0 1px 1px #11141833;
}

.bp4-tag-input.bp4-active.bp4-intent-success {
  box-shadow: inset 0 0 0 1px #238551, 0 0 0 2px #2385514d, inset 0 1px 1px #11141833;
}

.bp4-tag-input.bp4-active.bp4-intent-warning {
  box-shadow: inset 0 0 0 1px #c87619, 0 0 0 2px #c876194d, inset 0 1px 1px #11141833;
}

.bp4-tag-input.bp4-active.bp4-intent-danger {
  box-shadow: inset 0 0 0 1px #cd4246, 0 0 0 2px #cd42464d, inset 0 1px 1px #11141833;
}

.bp4-dark .bp4-tag-input .bp4-tag-input-icon, .bp4-tag-input.bp4-dark .bp4-tag-input-icon {
  color: #abb3bf;
}

.bp4-dark .bp4-tag-input .bp4-input-ghost, .bp4-tag-input.bp4-dark .bp4-input-ghost {
  color: #f6f7f9;
}

.bp4-dark .bp4-tag-input .bp4-input-ghost::placeholder, .bp4-tag-input.bp4-dark .bp4-input-ghost::placeholder {
  color: #abb3bf;
}

.bp4-dark .bp4-tag-input.bp4-active, .bp4-tag-input.bp4-dark.bp4-active {
  background-color: #1114184d;
  box-shadow: inset 0 0 0 1px #4c90f0, inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-primary, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-primary {
  box-shadow: inset 0 0 0 1px #4c90f0, 0 0 0 2px #4c90f04d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-success, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-success {
  box-shadow: inset 0 0 0 1px #32a467, 0 0 0 2px #32a4674d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-warning, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-warning {
  box-shadow: inset 0 0 0 1px #ec9a3c, 0 0 0 2px #ec9a3c4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-dark .bp4-tag-input.bp4-active.bp4-intent-danger, .bp4-tag-input.bp4-dark.bp4-active.bp4-intent-danger {
  box-shadow: inset 0 0 0 1px #e76a6e, 0 0 0 2px #e76a6e4d, inset 0 1px #11141899, inset -1px 0 #11141899, inset 1px 0 #11141899, inset 0 -1px #8f99a8;
}

.bp4-input-ghost {
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
}

.bp4-input-ghost::placeholder {
  color: #5f6b7c;
  opacity: 1;
}

.bp4-input-ghost:focus {
  outline: none !important;
}

.bp4-toast {
  max-width: 500px;
  min-width: 300px;
  pointer-events: all;
  background-color: #fff;
  border-radius: 3px;
  align-items: flex-start;
  margin: 20px 0 0;
  display: flex;
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
  position: relative !important;
}

.bp4-toast.bp4-toast-enter, .bp4-toast.bp4-toast-appear {
  transform: translateY(-40px);
}

.bp4-toast.bp4-toast-enter-active, .bp4-toast.bp4-toast-appear-active {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: translateY(0);
}

.bp4-toast.bp4-toast-enter ~ .bp4-toast, .bp4-toast.bp4-toast-appear ~ .bp4-toast {
  transform: translateY(-40px);
}

.bp4-toast.bp4-toast-enter-active ~ .bp4-toast, .bp4-toast.bp4-toast-appear-active ~ .bp4-toast {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: translateY(0);
}

.bp4-toast.bp4-toast-exit {
  opacity: 1;
  filter: blur();
}

.bp4-toast.bp4-toast-exit-active {
  opacity: 0;
  filter: blur(10px);
  transition-delay: 0;
  transition-property: opacity, filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-toast.bp4-toast-exit ~ .bp4-toast {
  transform: translateY(0);
}

.bp4-toast.bp4-toast-exit-active ~ .bp4-toast {
  transition: transform .1s cubic-bezier(.4, 1, .75, .9) 50ms;
  transform: translateY(-40px);
}

.bp4-toast .bp4-button-group {
  flex: none;
  padding: 5px 5px 5px 0;
}

.bp4-toast > .bp4-icon {
  color: #5f6b7c;
  margin: 12px 0 12px 12px;
}

.bp4-toast.bp4-dark, .bp4-dark .bp4-toast {
  background-color: #404854;
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-toast.bp4-dark > .bp4-icon, .bp4-dark .bp4-toast > .bp4-icon {
  color: #abb3bf;
}

.bp4-toast[class*="bp4-intent-"] a {
  color: #ffffffb3;
}

.bp4-toast[class*="bp4-intent-"] a:hover, .bp4-toast[class*="bp4-intent-"] > .bp4-icon {
  color: #fff;
}

.bp4-toast[class*="bp4-intent-"] .bp4-button, .bp4-toast[class*="bp4-intent-"] .bp4-button:before, .bp4-toast[class*="bp4-intent-"] .bp4-button .bp4-icon, .bp4-toast[class*="bp4-intent-"] .bp4-button:active {
  color: #ffffffb3 !important;
}

.bp4-toast[class*="bp4-intent-"] .bp4-button:focus {
  outline-color: #ffffff80;
}

.bp4-toast[class*="bp4-intent-"] .bp4-button:hover {
  color: #fff !important;
  background-color: #ffffff26 !important;
}

.bp4-toast[class*="bp4-intent-"] .bp4-button:active {
  color: #fff !important;
  background-color: #ffffff4d !important;
}

.bp4-toast[class*="bp4-intent-"] .bp4-button:after {
  background: #ffffff4d !important;
}

.bp4-toast.bp4-intent-primary {
  color: #fff;
  background-color: #2d72d2;
}

.bp4-toast.bp4-intent-success {
  color: #fff;
  background-color: #238551;
}

.bp4-toast.bp4-intent-warning {
  color: #fff;
  background-color: #c87619;
}

.bp4-toast.bp4-intent-danger {
  color: #fff;
  background-color: #cd4246;
}

.bp4-toast-message {
  word-break: break-word;
  flex: auto;
  padding: 11px;
}

.bp4-toast-container {
  pointer-events: none;
  z-index: 40;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex !important;
}

.bp4-toast-container.bp4-toast-container-in-portal {
  position: fixed;
}

.bp4-toast-container.bp4-toast-container-inline {
  position: absolute;
}

.bp4-toast-container.bp4-toast-container-top {
  top: 0;
}

.bp4-toast-container.bp4-toast-container-bottom {
  flex-direction: column-reverse;
  top: auto;
  bottom: 0;
}

.bp4-toast-container.bp4-toast-container-left {
  align-items: flex-start;
}

.bp4-toast-container.bp4-toast-container-right {
  align-items: flex-end;
}

.bp4-toast-container-bottom .bp4-toast.bp4-toast-enter:not(.bp4-toast-enter-active), .bp4-toast-container-bottom .bp4-toast.bp4-toast-enter:not(.bp4-toast-enter-active) ~ .bp4-toast, .bp4-toast-container-bottom .bp4-toast.bp4-toast-appear:not(.bp4-toast-appear-active), .bp4-toast-container-bottom .bp4-toast.bp4-toast-appear:not(.bp4-toast-appear-active) ~ .bp4-toast, .bp4-toast-container-bottom .bp4-toast.bp4-toast-exit-active ~ .bp4-toast, .bp4-toast-container-bottom .bp4-toast.bp4-toast-leave-active ~ .bp4-toast {
  transform: translateY(60px);
}

.bp4-tooltip {
  transform: scale(1);
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-tooltip .bp4-popover-arrow {
  height: 22px;
  width: 22px;
  position: absolute;
}

.bp4-tooltip .bp4-popover-arrow:before {
  height: 14px;
  width: 14px;
  margin: 4px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-tooltip {
  margin-top: -11px;
  margin-bottom: 11px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-tooltip > .bp4-popover-arrow {
  bottom: -8px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-top > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(-90deg);
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-tooltip {
  margin-left: 11px;
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-tooltip > .bp4-popover-arrow {
  left: -8px;
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-right > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(0);
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-tooltip {
  margin-top: 11px;
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-tooltip > .bp4-popover-arrow {
  top: -8px;
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-bottom > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(90deg);
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-tooltip {
  margin-left: -11px;
  margin-right: 11px;
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-tooltip > .bp4-popover-arrow {
  right: -8px;
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-left > .bp4-tooltip > .bp4-popover-arrow svg {
  transform: rotate(180deg);
}

.bp4-tether-element-attached-middle > .bp4-tooltip > .bp4-popover-arrow {
  top: 50%;
  transform: translateY(-50%);
}

.bp4-tether-element-attached-center > .bp4-tooltip > .bp4-popover-arrow {
  right: 50%;
  transform: translateX(50%);
}

.bp4-tether-element-attached-top.bp4-tether-target-attached-top > .bp4-tooltip > .bp4-popover-arrow {
  top: -.221825px;
}

.bp4-tether-element-attached-right.bp4-tether-target-attached-right > .bp4-tooltip > .bp4-popover-arrow {
  right: -.221825px;
}

.bp4-tether-element-attached-left.bp4-tether-target-attached-left > .bp4-tooltip > .bp4-popover-arrow {
  left: -.221825px;
}

.bp4-tether-element-attached-bottom.bp4-tether-target-attached-bottom > .bp4-tooltip > .bp4-popover-arrow {
  bottom: -.221825px;
}

.bp4-tether-element-attached-top.bp4-tether-element-attached-left > .bp4-tooltip {
  transform-origin: 0 0;
}

.bp4-tether-element-attached-top.bp4-tether-element-attached-center > .bp4-tooltip {
  transform-origin: top;
}

.bp4-tether-element-attached-top.bp4-tether-element-attached-right > .bp4-tooltip {
  transform-origin: 100% 0;
}

.bp4-tether-element-attached-middle.bp4-tether-element-attached-left > .bp4-tooltip {
  transform-origin: 0;
}

.bp4-tether-element-attached-middle.bp4-tether-element-attached-center > .bp4-tooltip {
  transform-origin: center;
}

.bp4-tether-element-attached-middle.bp4-tether-element-attached-right > .bp4-tooltip {
  transform-origin: 100%;
}

.bp4-tether-element-attached-bottom.bp4-tether-element-attached-left > .bp4-tooltip {
  transform-origin: 0 100%;
}

.bp4-tether-element-attached-bottom.bp4-tether-element-attached-center > .bp4-tooltip {
  transform-origin: bottom;
}

.bp4-tether-element-attached-bottom.bp4-tether-element-attached-right > .bp4-tooltip {
  transform-origin: 100% 100%;
}

.bp4-tooltip .bp4-popover-content {
  background: #404854;
}

.bp4-tooltip .bp4-popover-content, .bp4-tooltip .bp4-heading {
  color: #f6f7f9;
}

.bp4-tooltip .bp4-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141833;
}

.bp4-tooltip .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: .1;
}

.bp4-tooltip .bp4-popover-arrow-fill {
  fill: #404854;
}

.bp4-popover-enter > .bp4-tooltip, .bp4-popover-appear > .bp4-tooltip {
  transform: scale(.8);
}

.bp4-popover-enter-active > .bp4-tooltip, .bp4-popover-appear-active > .bp4-tooltip {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp4-popover-exit > .bp4-tooltip {
  transform: scale(1);
}

.bp4-popover-exit-active > .bp4-tooltip {
  transition-delay: 0;
  transition-property: transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(.8);
}

.bp4-tooltip .bp4-popover-content {
  padding: 10px 12px;
}

.bp4-tooltip.bp4-dark, .bp4-dark .bp4-tooltip {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-tooltip.bp4-dark .bp4-popover-content, .bp4-dark .bp4-tooltip .bp4-popover-content {
  background: #e5e8eb;
}

.bp4-tooltip.bp4-dark .bp4-popover-content, .bp4-tooltip.bp4-dark .bp4-heading, .bp4-dark .bp4-tooltip .bp4-popover-content, .bp4-dark .bp4-tooltip .bp4-heading {
  color: #404854;
}

.bp4-tooltip.bp4-dark .bp4-popover-arrow:before, .bp4-dark .bp4-tooltip .bp4-popover-arrow:before {
  box-shadow: 1px 1px 6px #11141866;
}

.bp4-tooltip.bp4-dark .bp4-popover-arrow-border, .bp4-dark .bp4-tooltip .bp4-popover-arrow-border {
  fill: #111418;
  fill-opacity: .2;
}

.bp4-tooltip.bp4-dark .bp4-popover-arrow-fill, .bp4-dark .bp4-tooltip .bp4-popover-arrow-fill {
  fill: #e5e8eb;
}

.bp4-tooltip.bp4-intent-primary .bp4-popover-content {
  color: #fff;
  background: #2d72d2;
}

.bp4-tooltip.bp4-intent-primary .bp4-popover-arrow-fill {
  fill: #2d72d2;
}

.bp4-tooltip.bp4-intent-success .bp4-popover-content {
  color: #fff;
  background: #238551;
}

.bp4-tooltip.bp4-intent-success .bp4-popover-arrow-fill {
  fill: #238551;
}

.bp4-tooltip.bp4-intent-warning .bp4-popover-content {
  color: #fff;
  background: #c87619;
}

.bp4-tooltip.bp4-intent-warning .bp4-popover-arrow-fill {
  fill: #c87619;
}

.bp4-tooltip.bp4-intent-danger .bp4-popover-content {
  color: #fff;
  background: #cd4246;
}

.bp4-tooltip.bp4-intent-danger .bp4-popover-arrow-fill {
  fill: #cd4246;
}

.bp4-tooltip-indicator {
  cursor: help;
  border-bottom: 1px dotted;
}

.bp4-tree .bp4-icon, .bp4-tree .bp4-icon-standard, .bp4-tree .bp4-icon-large {
  color: #5f6b7c;
}

.bp4-tree .bp4-icon.bp4-intent-primary, .bp4-tree .bp4-icon-standard.bp4-intent-primary, .bp4-tree .bp4-icon-large.bp4-intent-primary {
  color: #2d72d2;
}

.bp4-tree .bp4-icon.bp4-intent-success, .bp4-tree .bp4-icon-standard.bp4-intent-success, .bp4-tree .bp4-icon-large.bp4-intent-success {
  color: #238551;
}

.bp4-tree .bp4-icon.bp4-intent-warning, .bp4-tree .bp4-icon-standard.bp4-intent-warning, .bp4-tree .bp4-icon-large.bp4-intent-warning {
  color: #c87619;
}

.bp4-tree .bp4-icon.bp4-intent-danger, .bp4-tree .bp4-icon-standard.bp4-intent-danger, .bp4-tree .bp4-icon-large.bp4-intent-danger {
  color: #cd4246;
}

.bp4-tree-node-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.bp4-tree-root {
  cursor: default;
  background-color: #0000;
  padding-left: 0;
  position: relative;
}

.bp4-tree-node-content-0 {
  padding-left: 0;
}

.bp4-tree-node-content-1 {
  padding-left: 23px;
}

.bp4-tree-node-content-2 {
  padding-left: 46px;
}

.bp4-tree-node-content-3 {
  padding-left: 69px;
}

.bp4-tree-node-content-4 {
  padding-left: 92px;
}

.bp4-tree-node-content-5 {
  padding-left: 115px;
}

.bp4-tree-node-content-6 {
  padding-left: 138px;
}

.bp4-tree-node-content-7 {
  padding-left: 161px;
}

.bp4-tree-node-content-8 {
  padding-left: 184px;
}

.bp4-tree-node-content-9 {
  padding-left: 207px;
}

.bp4-tree-node-content-10 {
  padding-left: 230px;
}

.bp4-tree-node-content-11 {
  padding-left: 253px;
}

.bp4-tree-node-content-12 {
  padding-left: 276px;
}

.bp4-tree-node-content-13 {
  padding-left: 299px;
}

.bp4-tree-node-content-14 {
  padding-left: 322px;
}

.bp4-tree-node-content-15 {
  padding-left: 345px;
}

.bp4-tree-node-content-16 {
  padding-left: 368px;
}

.bp4-tree-node-content-17 {
  padding-left: 391px;
}

.bp4-tree-node-content-18 {
  padding-left: 414px;
}

.bp4-tree-node-content-19 {
  padding-left: 437px;
}

.bp4-tree-node-content-20 {
  padding-left: 460px;
}

.bp4-tree-node-content {
  height: 30px;
  width: 100%;
  align-items: center;
  padding-right: 5px;
  display: flex;
}

.bp4-tree-node-content:hover {
  background-color: #c5cbd366;
}

.bp4-tree-node-caret, .bp4-tree-node-caret-none {
  min-width: 30px;
}

.bp4-tree-node-caret {
  color: #5f6b7c;
  cursor: pointer;
  padding: 7px;
  transition: transform .2s cubic-bezier(.4, 1, .75, .9);
  transform: rotate(0deg);
}

.bp4-tree-node-caret:hover {
  color: #1c2127;
}

.bp4-dark .bp4-tree-node-caret {
  color: #abb3bf;
}

.bp4-dark .bp4-tree-node-caret:hover {
  color: #f6f7f9;
}

.bp4-tree-node-caret.bp4-tree-node-caret-open {
  transform: rotate(90deg);
}

.bp4-tree-node-caret.bp4-icon-standard:before {
  content: "";
}

.bp4-tree-node-icon {
  margin-right: 7px;
  position: relative;
}

.bp4-tree-node-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-user-select: none;
  user-select: none;
  flex: auto;
  position: relative;
  overflow: hidden;
}

.bp4-tree-node-label span {
  display: inline;
}

.bp4-tree-node-secondary-label {
  -webkit-user-select: none;
  user-select: none;
  padding: 0 5px;
}

.bp4-tree-node-secondary-label .bp4-popover-wrapper, .bp4-tree-node-secondary-label .bp4-popover-target {
  align-items: center;
  display: flex;
}

.bp4-tree-node.bp4-disabled .bp4-tree-node-content {
  background-color: inherit;
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp4-tree-node.bp4-disabled .bp4-tree-node-caret, .bp4-tree-node.bp4-disabled .bp4-tree-node-icon {
  color: #5f6b7c99;
  cursor: not-allowed;
}

.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content {
  background-color: #2d72d2;
}

.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-standard, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-large {
  color: #fff;
}

.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-tree-node-caret:before {
  color: #ffffffb3;
}

.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-tree-node-caret:hover:before {
  color: #fff;
}

.bp4-dark .bp4-tree-node-content:hover {
  background-color: #5f6b7c4d;
}

.bp4-dark .bp4-tree .bp4-icon, .bp4-dark .bp4-tree .bp4-icon-standard, .bp4-dark .bp4-tree .bp4-icon-large {
  color: #abb3bf;
}

.bp4-dark .bp4-tree .bp4-icon.bp4-intent-primary, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-primary, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-primary {
  color: #2d72d2;
}

.bp4-dark .bp4-tree .bp4-icon.bp4-intent-success, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-success, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-success {
  color: #238551;
}

.bp4-dark .bp4-tree .bp4-icon.bp4-intent-warning, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-warning, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-warning {
  color: #c87619;
}

.bp4-dark .bp4-tree .bp4-icon.bp4-intent-danger, .bp4-dark .bp4-tree .bp4-icon-standard.bp4-intent-danger, .bp4-dark .bp4-tree .bp4-icon-large.bp4-intent-danger {
  color: #cd4246;
}

.bp4-dark .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content {
  background-color: #2d72d2;
}

.bp4-breadcrumbs-collapsed {
  background: #8f99a826;
}

.bp4-breadcrumbs-collapsed:hover {
  background: #8f99a84d;
}

.bp4-dark .bp4-breadcrumbs > li:after {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.71 7.29l-4-4a1.003 1.003 0 00-1.42 1.42L8.59 8 5.3 11.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4-4c.18-.18.29-.43.29-.71 0-.28-.11-.53-.29-.71z' fill='%23ABB3BF'/%3e%3c/svg%3e");
}

.bp4-dark .bp4-breadcrumbs-collapsed {
  background: #8f99a833;
}

.bp4-dark .bp4-breadcrumbs-collapsed:before {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cg fill='%23ABB3BF'%3e%3ccircle cx='2' cy='8.03' r='2'/%3e%3ccircle cx='14' cy='8.03' r='2'/%3e%3ccircle cx='8' cy='8.03' r='2'/%3e%3c/g%3e%3c/svg%3e") center no-repeat;
}

.bp4-dark .bp4-breadcrumbs-collapsed:hover {
  background: #8f99a84d;
}

.bp4-button.bp4-intent-warning {
  color: #1c2127;
  background: #fbb360;
}

.bp4-button.bp4-intent-warning:not(.bp4-disabled).bp4-icon > svg {
  fill: #1c2127b3;
}

.bp4-button.bp4-intent-warning:not(.bp4-disabled):not(.bp4-minimal):not(.bp4-outlined):hover {
  color: #1c2127;
  background: #ec9a3c;
}

.bp4-button.bp4-intent-warning:not(.bp4-disabled):not(.bp4-minimal):not(.bp4-outlined):active, .bp4-button.bp4-intent-warning:not(.bp4-disabled):not(.bp4-minimal):not(.bp4-outlined).bp4-active {
  color: #1c2127;
  background: #c87619;
}

.bp4-button.bp4-intent-warning:disabled, .bp4-button.bp4-intent-warning.bp4-disabled {
  color: #1c212759;
  background: #c8761980;
}

.bp4-dark .bp4-button.bp4-intent-warning:disabled, .bp4-dark .bp4-button.bp4-intent-warning.bp4-disabled {
  color: #1c212799;
}

.bp4-button.bp4-intent-warning.bp4-minimal, .bp4-button.bp4-intent-warning.bp4-outlined {
  background: none;
}

.bp4-dark .bp4-button.bp4-intent-warning.bp4-minimal:not(.bp4-disabled).bp4-icon > svg, .bp4-dark .bp4-button.bp4-intent-warning.bp4-outlined:not(.bp4-disabled).bp4-icon > svg {
  fill: #fbb360;
}

.bp4-button.bp4-minimal.bp4-intent-primary:active:not(:disabled):not(.bp4-disabled) {
  color: #184a90;
}

.bp4-button.bp4-minimal.bp4-intent-success:active:not(:disabled):not(.bp4-disabled) {
  color: #165a36;
}

.bp4-button.bp4-minimal.bp4-intent-warning:active:not(:disabled):not(.bp4-disabled) {
  color: #77450d;
}

.bp4-button.bp4-minimal.bp4-intent-danger:active:not(:disabled):not(.bp4-disabled) {
  color: #8e292c;
}

.bp4-dark .bp4-button.bp4-minimal:not([class*="bp4-intent-"]):not(:disabled):not(.bp4-disabled) {
  color: #fff;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:hover:not(:disabled):not(.bp4-disabled) {
  color: #8abbff;
  background: #2d72d233;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-primary:active:not(:disabled):not(.bp4-disabled) {
  color: #99c4ff;
  background: #2d72d24d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:hover:not(:disabled):not(.bp4-disabled) {
  color: #72ca9b;
  background: #23855133;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-success:active:not(:disabled):not(.bp4-disabled) {
  color: #7cd7a2;
  background: #2385514d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:hover:not(:disabled):not(.bp4-disabled) {
  color: #fbb360;
  background: #c8761933;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-warning:active:not(:disabled):not(.bp4-disabled) {
  color: #f5c186;
  background: #c876194d;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:hover:not(:disabled):not(.bp4-disabled) {
  color: #fa999c;
  background: #cd424633;
}

.bp4-dark .bp4-button.bp4-minimal.bp4-intent-danger:active:not(:disabled):not(.bp4-disabled) {
  color: #ffa1a4;
  background: #cd42464d;
}

.bp4-callout {
  background-color: #8f99a826;
}

.bp4-dark .bp4-callout {
  background-color: #8f99a833;
}

.bp4-dark .bp4-callout.bp4-callout-icon:not([class*="bp4-intent-"]) > .bp4-icon:first-child {
  color: #abb3bf;
}

.bp4-callout.bp4-intent-primary {
  background-color: #2d72d21a;
}

.bp4-dark .bp4-callout.bp4-intent-primary {
  background-color: #2d72d233;
}

.bp4-callout.bp4-intent-success {
  background-color: #2385511a;
}

.bp4-dark .bp4-callout.bp4-intent-success {
  background-color: #23855133;
}

.bp4-callout.bp4-intent-warning {
  background-color: #c876191a;
}

.bp4-dark .bp4-callout.bp4-intent-warning {
  background-color: #c8761933;
}

.bp4-callout.bp4-intent-danger {
  background-color: #cd42461a;
}

.bp4-dark .bp4-callout.bp4-intent-danger {
  background-color: #cd424633;
}

table.bp4-html-table.bp4-html-table-striped tbody tr:nth-child(2n+1) td {
  background: #8f99a826;
}

table.bp4-html-table.bp4-interactive tbody tr:hover td {
  cursor: pointer;
  background-color: #8f99a84d;
}

table.bp4-html-table.bp4-interactive tbody tr:active td {
  background-color: #8f99a859;
}

.bp4-dark .bp4-slider-handle {
  background-color: #abb3bf;
}

.bp4-dark .bp4-slider-handle:hover {
  background-color: #8f99a8;
}

.bp4-dark .bp4-slider-handle:active, .bp4-dark .bp4-slider-handle.bp4-active {
  background: linear-gradient(0deg, #ffffff1a, #ffffff1a), linear-gradient(0deg, #738091, #738091);
}

.bp4-tag {
  color: #fff;
}

.bp4-tag .bp4-tag-remove, .bp4-tag .bp4-tag-remove:hover, .bp4-tag .bp4-tag-remove:active {
  opacity: 1;
}

.bp4-tag.bp4-interactive:hover {
  background: #404854;
}

.bp4-tag.bp4-interactive:active {
  background: #383e47;
}

.bp4-tag .bp4-tag-remove > .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-tag .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #fff;
}

.bp4-dark .bp4-tag:not([class*="bp4-intent-"]) {
  color: #1c2127;
  background: #c5cbd3;
}

.bp4-dark .bp4-tag:not([class*="bp4-intent-"]).bp4-interactive:hover {
  background: #abb3bf;
}

.bp4-dark .bp4-tag:not([class*="bp4-intent-"]).bp4-interactive:active {
  background: #8f99a8;
}

.bp4-dark .bp4-tag:not([class*="bp4-intent-"]) .bp4-tag-remove > .bp4-icon:first-child {
  color: #1c2127b3;
}

.bp4-dark .bp4-tag:not([class*="bp4-intent-"]) .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-dark .bp4-tag:not([class*="bp4-intent-"]) .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #1c2127;
}

.bp4-tag.bp4-intent-primary {
  color: #fff;
  background: #2d72d2;
}

.bp4-tag.bp4-intent-primary.bp4-interactive:hover {
  background-color: #215db0;
}

.bp4-tag.bp4-intent-primary.bp4-interactive:active {
  background-color: #184a90;
}

.bp4-tag.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-tag.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #fff;
}

.bp4-tag.bp4-intent-success {
  color: #fff;
  background: #238551;
}

.bp4-tag.bp4-intent-success.bp4-interactive:hover {
  background-color: #1c6e42;
}

.bp4-tag.bp4-intent-success.bp4-interactive:active {
  background-color: #165a36;
}

.bp4-tag.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-tag.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #fff;
}

.bp4-tag.bp4-intent-warning {
  color: #1c2127;
  background: #fbb360;
}

.bp4-tag.bp4-intent-warning.bp4-interactive:hover {
  background-color: #ec9a3c;
}

.bp4-tag.bp4-intent-warning.bp4-interactive:active {
  background-color: #c87619;
}

.bp4-tag.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child {
  color: #1c2127b3;
}

.bp4-tag.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #1c2127;
}

.bp4-tag.bp4-intent-danger {
  color: #fff;
  background: #cd4246;
}

.bp4-tag.bp4-intent-danger.bp4-interactive:hover {
  background-color: #ac2f33;
}

.bp4-tag.bp4-intent-danger.bp4-interactive:active {
  background-color: #8e292c;
}

.bp4-tag.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-tag.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #fff;
}

.bp4-tag.bp4-minimal {
  color: #1c2127;
  background-color: #8f99a826;
}

.bp4-tag.bp4-minimal.bp4-interactive:hover {
  color: #111418;
  background-color: #8f99a84d;
}

.bp4-tag.bp4-minimal.bp4-interactive:active {
  color: #111418;
  background-color: #8f99a859;
}

.bp4-tag.bp4-minimal .bp4-tag-remove > .bp4-icon:first-child {
  color: #5f6b7c;
}

.bp4-tag.bp4-minimal .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-minimal .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #404854;
}

.bp4-dark .bp4-tag.bp4-minimal {
  color: #d3d8de;
  background-color: #8f99a826;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-interactive:hover {
  color: #fff;
  background-color: #8f99a84d;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-interactive:active {
  color: #fff;
  background-color: #8f99a859;
}

.bp4-dark .bp4-tag.bp4-minimal .bp4-tag-remove > .bp4-icon:first-child {
  color: #abb3bf;
}

.bp4-dark .bp4-tag.bp4-minimal .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-dark .bp4-tag.bp4-minimal .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #d3d8de;
}

.bp4-tag.bp4-minimal.bp4-intent-primary {
  color: #215db0;
  background-color: #2d72d21a;
}

.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:hover {
  color: #184a90;
  background-color: #2d72d233;
}

.bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:active {
  color: #184a90;
  background-color: #2d72d24d;
}

.bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child {
  color: #215db0;
}

.bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #184a90;
}

.bp4-tag.bp4-minimal.bp4-intent-success {
  color: #1c6e42;
  background-color: #2385511a;
}

.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:hover {
  color: #165a36;
  background-color: #23855133;
}

.bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:active {
  color: #165a36;
  background-color: #2385514d;
}

.bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child {
  color: #1c6e42;
}

.bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #165a36;
}

.bp4-tag.bp4-minimal.bp4-intent-warning {
  color: #935610;
  background-color: #c876191a;
}

.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:hover {
  color: #77450d;
  background-color: #c8761933;
}

.bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:active {
  color: #77450d;
  background-color: #c876194d;
}

.bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child {
  color: #935610;
}

.bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #77450d;
}

.bp4-tag.bp4-minimal.bp4-intent-danger {
  color: #ac2f33;
  background-color: #cd42461a;
}

.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:hover {
  color: #8e292c;
  background-color: #cd424633;
}

.bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:active {
  color: #8e292c;
  background-color: #cd42464d;
}

.bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child {
  color: #ac2f33;
}

.bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #8e292c;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary {
  color: #8abbff;
  background-color: #2d72d233;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:hover {
  color: #99c4ff;
  background-color: #2d72d24d;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary.bp4-interactive:active {
  color: #99c4ff;
  background-color: #2d72d259;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child {
  color: #8abbff;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-primary .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #99c4ff;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success {
  color: #72ca9b;
  background-color: #23855133;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:hover {
  color: #7cd7a2;
  background-color: #2385514d;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success.bp4-interactive:active {
  color: #7cd7a2;
  background-color: #23855159;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child {
  color: #72ca9b;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-success .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #7cd7a2;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning {
  color: #fbb360;
  background-color: #c8761933;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:hover {
  color: #f5c186;
  background-color: #c876194d;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning.bp4-interactive:active {
  color: #f5c186;
  background-color: #c8761959;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child {
  color: #fbb360;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-warning .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #f5c186;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger {
  color: #fa999c;
  background-color: #cd424633;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:hover {
  color: #ffa1a4;
  background-color: #cd42464d;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger.bp4-interactive:active {
  color: #ffa1a4;
  background-color: #cd424659;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child {
  color: #fa999c;
}

.bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child:hover, .bp4-dark .bp4-tag.bp4-minimal.bp4-intent-danger .bp4-tag-remove > .bp4-icon:first-child:active {
  color: #ffa1a4;
}

.bp4-toast:not([class*="bp4-intent-"]).bp4-dark .bp4-button .bp4-icon, .bp4-dark .bp4-toast:not([class*="bp4-intent-"]) .bp4-button .bp4-icon {
  color: #ffffffb3;
}

.bp4-toast.bp4-intent-warning {
  color: #1c2127;
  background-color: #fbb360;
}

.bp4-toast.bp4-intent-primary .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-toast.bp4-intent-primary .bp4-button {
  color: #fff !important;
  background-color: #2d72d2 !important;
}

.bp4-toast.bp4-intent-primary .bp4-button:hover {
  color: #fff !important;
  background-color: #215db0 !important;
}

.bp4-toast.bp4-intent-primary .bp4-button:active {
  color: #fff !important;
  background-color: #184a90 !important;
}

.bp4-toast.bp4-intent-primary .bp4-button:last-child > .bp4-icon-cross {
  color: #ffffffb3 !important;
}

.bp4-toast.bp4-intent-success .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-toast.bp4-intent-success .bp4-button {
  color: #fff !important;
  background-color: #238551 !important;
}

.bp4-toast.bp4-intent-success .bp4-button:hover {
  color: #fff !important;
  background-color: #1c6e42 !important;
}

.bp4-toast.bp4-intent-success .bp4-button:active {
  color: #fff !important;
  background-color: #165a36 !important;
}

.bp4-toast.bp4-intent-success .bp4-button:last-child > .bp4-icon-cross {
  color: #ffffffb3 !important;
}

.bp4-toast.bp4-intent-warning .bp4-icon:first-child {
  color: #1c2127b3;
}

.bp4-toast.bp4-intent-warning .bp4-button {
  color: #1c2127 !important;
  background-color: #fbb360 !important;
}

.bp4-toast.bp4-intent-warning .bp4-button:hover {
  color: #1c2127 !important;
  background-color: #ec9a3c !important;
}

.bp4-toast.bp4-intent-warning .bp4-button:active {
  color: #1c2127 !important;
  background-color: #c87619 !important;
}

.bp4-toast.bp4-intent-warning .bp4-button:last-child > .bp4-icon-cross {
  color: #1c2127b3 !important;
}

.bp4-toast.bp4-intent-danger .bp4-icon:first-child {
  color: #ffffffb3;
}

.bp4-toast.bp4-intent-danger .bp4-button {
  color: #fff !important;
  background-color: #cd4246 !important;
}

.bp4-toast.bp4-intent-danger .bp4-button:hover {
  color: #fff !important;
  background-color: #ac2f33 !important;
}

.bp4-toast.bp4-intent-danger .bp4-button:active {
  color: #fff !important;
  background-color: #8e292c !important;
}

.bp4-toast.bp4-intent-danger .bp4-button:last-child > .bp4-icon-cross {
  color: #ffffffb3 !important;
}

.bp4-tree-node-content {
  background: none;
}

.bp4-tree-node-content:hover {
  background-color: #8f99a826;
}

.bp4-tree-node-content:active {
  background-color: #8f99a84d;
}

.bp4-dark .bp4-tree-node-content .bp4-icon.bp4-intent-primary {
  color: #8abbff;
}

.bp4-dark .bp4-tree-node-content .bp4-icon.bp4-intent-success {
  color: #72ca9b;
}

.bp4-dark .bp4-tree-node-content .bp4-icon.bp4-intent-warning {
  color: #fbb360;
}

.bp4-dark .bp4-tree-node-content .bp4-icon.bp4-intent-danger {
  color: #fa999c;
}

.bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-standard, .bp4-tree-node.bp4-tree-node-selected > .bp4-tree-node-content .bp4-icon-large {
  color: #fff;
}

@font-face {
  font-family: blueprint-icons-16;
  src: url("blueprint-icons-16.ada84acc.ttf") format("truetype"), url("blueprint-icons-16.e71ff36a.eot#iefix") format("embedded-opentype"), url("blueprint-icons-16.90d57503.woff2") format("woff2"), url("blueprint-icons-16.dfa4c4d5.woff") format("woff");
}

@font-face {
  font-family: blueprint-icons-20;
  src: url("blueprint-icons-20.4c8efb3a.ttf") format("truetype"), url("blueprint-icons-20.507c2969.eot#iefix") format("embedded-opentype"), url("blueprint-icons-20.8b359e1f.woff2") format("woff2"), url("blueprint-icons-20.abf24b59.woff") format("woff");
}

.bp4-context-menu2-virtual-target {
  position: fixed;
}

.bp4-popover2 {
  z-index: 20;
  border-radius: 3px;
  display: inline-block;
  transform: scale(1);
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-popover2 .bp4-popover2-arrow {
  height: 30px;
  width: 30px;
  position: absolute;
}

.bp4-popover2 .bp4-popover2-arrow:before {
  height: 20px;
  width: 20px;
  margin: 5px;
}

.bp4-popover2 .bp4-popover2-content {
  background: #fff;
}

.bp4-popover2 .bp4-popover2-content, .bp4-popover2 .bp4-heading {
  color: inherit;
}

.bp4-popover2 .bp4-popover2-arrow:before {
  box-shadow: 1px 1px 6px #11141833;
}

.bp4-popover2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: .1;
}

.bp4-popover2 .bp4-popover2-arrow-fill {
  fill: #fff;
}

.bp4-popover2-enter > .bp4-popover2, .bp4-popover2-appear > .bp4-popover2 {
  transform: scale(.3);
}

.bp4-popover2-enter-active > .bp4-popover2, .bp4-popover2-appear-active > .bp4-popover2 {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: transform, -webkit-transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(1);
}

.bp4-popover2-exit > .bp4-popover2 {
  transform: scale(1);
}

.bp4-popover2-exit-active > .bp4-popover2 {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: transform, -webkit-transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.54, 1.12, .38, 1.11);
  transform: scale(.3);
}

.bp4-popover2 .bp4-popover2-content {
  border-radius: 3px;
  position: relative;
}

.bp4-popover2.bp4-popover2-content-sizing .bp4-popover2-content {
  max-width: 350px;
  padding: 20px;
}

.bp4-popover2-target + .bp4-overlay .bp4-popover2.bp4-popover2-content-sizing {
  width: 350px;
}

.bp4-popover2.bp4-minimal {
  margin: 0 !important;
}

.bp4-popover2.bp4-minimal .bp4-popover2-arrow {
  display: none;
}

.bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-enter > .bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-appear > .bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
}

.bp4-popover2-enter-active > .bp4-popover2.bp4-minimal.bp4-popover2, .bp4-popover2-appear-active > .bp4-popover2.bp4-minimal.bp4-popover2 {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: transform, -webkit-transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp4-popover2-exit > .bp4-popover2.bp4-minimal.bp4-popover2 {
  transform: scale(1);
}

.bp4-popover2-exit-active > .bp4-popover2.bp4-minimal.bp4-popover2 {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: transform, -webkit-transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp4-popover2.bp4-dark, .bp4-dark .bp4-popover2 {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-popover2.bp4-dark .bp4-popover2-content, .bp4-dark .bp4-popover2 .bp4-popover2-content {
  background: #383e47;
}

.bp4-popover2.bp4-dark .bp4-popover2-content, .bp4-popover2.bp4-dark .bp4-heading, .bp4-dark .bp4-popover2 .bp4-popover2-content, .bp4-dark .bp4-popover2 .bp4-heading {
  color: inherit;
}

.bp4-popover2.bp4-dark .bp4-popover2-arrow:before, .bp4-dark .bp4-popover2 .bp4-popover2-arrow:before {
  box-shadow: 1px 1px 6px #11141866;
}

.bp4-popover2.bp4-dark .bp4-popover2-arrow-border, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: .2;
}

.bp4-popover2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-popover2 .bp4-popover2-arrow-fill {
  fill: #383e47;
}

.bp4-popover2-arrow:before {
  content: "";
  border-radius: 2px;
  display: block;
  position: absolute;
  transform: rotate(45deg);
}

.bp4-overlay-backdrop.bp4-popover2-backdrop {
  background: #fff0;
}

.bp4-popover2-transition-container {
  opacity: 1;
  z-index: 20;
  display: flex;
}

.bp4-popover2-transition-container.bp4-popover2-enter, .bp4-popover2-transition-container.bp4-popover2-appear {
  opacity: 0;
}

.bp4-popover2-transition-container.bp4-popover2-enter-active, .bp4-popover2-transition-container.bp4-popover2-appear-active {
  opacity: 1;
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-popover2-transition-container.bp4-popover2-exit {
  opacity: 1;
}

.bp4-popover2-transition-container.bp4-popover2-exit-active {
  opacity: 0;
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: opacity;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
}

.bp4-popover2-transition-container:focus {
  outline: none;
}

.bp4-popover2-transition-container.bp4-popover2-leave .bp4-popover2-content {
  pointer-events: none;
}

span.bp4-popover2-target {
  display: inline-block;
}

.bp4-button-group:not(.bp4-minimal) > .bp4-popover2-target:not(:first-child) .bp4-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bp4-button-group:not(.bp4-minimal) > .bp4-popover2-target:not(:last-child) .bp4-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
}

.bp4-button-group .bp4-popover2-target {
  flex: auto;
  display: flex;
}

.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:first-child .bp4-button {
  border-radius: 3px 3px 0 0;
}

.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:last-child .bp4-button {
  border-radius: 0 0 3px 3px;
}

.bp4-button-group.bp4-vertical:not(.bp4-minimal) > .bp4-popover2-target:not(:last-child) .bp4-button {
  margin-bottom: -1px;
}

.bp4-control-group .bp4-popover2-target {
  border-radius: inherit;
}

label.bp4-label .bp4-popover2-target {
  text-transform: none;
  margin-top: 5px;
  display: block;
}

.bp4-submenu .bp4-popover2-target {
  display: block;
}

.bp4-submenu.bp4-popover2 {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 5px;
}

.bp4-submenu.bp4-popover2 > .bp4-popover2-content {
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-dark .bp4-submenu.bp4-popover2, .bp4-submenu.bp4-popover2.bp4-dark {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bp4-dark .bp4-submenu.bp4-popover2 > .bp4-popover2-content, .bp4-submenu.bp4-popover2.bp4-dark > .bp4-popover2-content {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-tree-node-secondary-label .bp4-popover2-target {
  align-items: center;
  display: flex;
}

.bp4-tooltip2 {
  transform: scale(1);
  box-shadow: 0 0 0 1px #1114181a, 0 2px 4px #11141833, 0 8px 24px #11141833;
}

.bp4-tooltip2 .bp4-popover2-arrow {
  height: 22px;
  width: 22px;
  position: absolute;
}

.bp4-tooltip2 .bp4-popover2-arrow:before {
  height: 14px;
  width: 14px;
  margin: 4px;
}

.bp4-tooltip2 .bp4-popover2-content {
  background: #404854;
}

.bp4-tooltip2 .bp4-popover2-content, .bp4-tooltip2 .bp4-heading {
  color: #f6f7f9;
}

.bp4-tooltip2 .bp4-popover2-arrow:before {
  box-shadow: 1px 1px 6px #11141833;
}

.bp4-tooltip2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: .1;
}

.bp4-tooltip2 .bp4-popover2-arrow-fill {
  fill: #404854;
}

.bp4-popover2-enter > .bp4-tooltip2, .bp4-popover2-appear > .bp4-tooltip2 {
  transform: scale(.8);
}

.bp4-popover2-enter-active > .bp4-tooltip2, .bp4-popover2-appear-active > .bp4-tooltip2 {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: transform, -webkit-transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(1);
}

.bp4-popover2-exit > .bp4-tooltip2 {
  transform: scale(1);
}

.bp4-popover2-exit-active > .bp4-tooltip2 {
  -webkit-transition-delay: 0;
  transition-delay: 0;
  transition-property: transform, -webkit-transform;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(.4, 1, .75, .9);
  transform: scale(.8);
}

.bp4-tooltip2 .bp4-popover2-content {
  padding: 10px 12px;
}

.bp4-tooltip2.bp4-popover2-placement-top .bp4-popover2-arrow {
  transform: translateY(-3px);
}

.bp4-tooltip2.bp4-popover2-placement-left .bp4-popover2-arrow {
  transform: translateX(-3px);
}

.bp4-tooltip2.bp4-popover2-placement-bottom .bp4-popover2-arrow {
  transform: translateY(3px);
}

.bp4-tooltip2.bp4-popover2-placement-right .bp4-popover2-arrow {
  transform: translateX(3px);
}

.bp4-tooltip2.bp4-dark, .bp4-dark .bp4-tooltip2 {
  box-shadow: 0 0 0 1px #11141833, 0 2px 4px #11141866, 0 8px 24px #11141866;
}

.bp4-tooltip2.bp4-dark .bp4-popover2-content, .bp4-dark .bp4-tooltip2 .bp4-popover2-content {
  background: #e5e8eb;
}

.bp4-tooltip2.bp4-dark .bp4-popover2-content, .bp4-tooltip2.bp4-dark .bp4-heading, .bp4-dark .bp4-tooltip2 .bp4-popover2-content, .bp4-dark .bp4-tooltip2 .bp4-heading {
  color: #404854;
}

.bp4-tooltip2.bp4-dark .bp4-popover2-arrow:before, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow:before {
  box-shadow: 1px 1px 6px #11141866;
}

.bp4-tooltip2.bp4-dark .bp4-popover2-arrow-border, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-border {
  fill: #111418;
  fill-opacity: .2;
}

.bp4-tooltip2.bp4-dark .bp4-popover2-arrow-fill, .bp4-dark .bp4-tooltip2 .bp4-popover2-arrow-fill {
  fill: #e5e8eb;
}

.bp4-tooltip2.bp4-intent-primary .bp4-popover2-content {
  color: #fff;
  background: #2d72d2;
}

.bp4-tooltip2.bp4-intent-primary .bp4-popover2-arrow-fill {
  fill: #2d72d2;
}

.bp4-tooltip2.bp4-intent-success .bp4-popover2-content {
  color: #fff;
  background: #238551;
}

.bp4-tooltip2.bp4-intent-success .bp4-popover2-arrow-fill {
  fill: #238551;
}

.bp4-tooltip2.bp4-intent-warning .bp4-popover2-content {
  color: #fff;
  background: #c87619;
}

.bp4-tooltip2.bp4-intent-warning .bp4-popover2-arrow-fill {
  fill: #c87619;
}

.bp4-tooltip2.bp4-intent-danger .bp4-popover2-content {
  color: #fff;
  background: #cd4246;
}

.bp4-tooltip2.bp4-intent-danger .bp4-popover2-arrow-fill {
  fill: #cd4246;
}

.bp4-tooltip2-indicator {
  cursor: help;
  border-bottom: 1px dotted;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, sans-serif;
  overflow: hidden;
}

iframe {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol, dl, dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover, a:focus {
  outline: none;
}

.wrapper {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: none;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  padding: 0;
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: none;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

@font-face {
  font-family: Inter;
  src: url("inter.1faf0d9b.ttf");
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("inter.1faf0d9b.ttf");
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("inter.1faf0d9b.ttf");
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("inter.1faf0d9b.ttf");
  font-display: swap;
  font-weight: 700;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes appear-top {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-bottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-left {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-up-center-subtle {
  0% {
    transform: scale(.96);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide-top {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100px);
  }
}

@keyframes rotate-in-center {
  0% {
    opacity: 0;
    transform: rotate(-360deg);
  }

  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: 1;
  }
}

:focus {
  outline: none;
}

.bp3-skeleton {
  transition: height .3s ease-in-out;
}

.bp3-toast {
  box-shadow: none;
  box-shadow: none;
  border-radius: 6px;
  align-items: center;
  font-weight: 600;
}

.bp3-toast[class*="bp3-intent-"] svg {
  color: #fff;
}

.bp3-toast > svg {
  color: #fff;
  margin-left: 10px;
}

.bp3-toast.bp4-intent-primary {
  background-color: #0092ff;
}

.bp3-toast.bp4-intent-success {
  background-color: #15b371;
}

.bp3-toast.bp4-intent-warning {
  background-color: #f29d49;
}

.bp3-toast.bp4-intent-danger {
  background-color: #f55656;
}

.bp3-toast .bp4-button {
  outline: none;
  align-self: flex-start;
  border: none !important;
}

.bp3-callout {
  color: #202b35cc;
  max-width: 300px;
  min-height: 38px;
  margin-bottom: 6px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
}

.bp3-callout.bp4-intent-warning {
  background: #f29d4914;
}

.bp3-card {
  box-shadow: none;
  border: 1px solid #ebf1f5;
  border-radius: 6px;
}

.bp3-slider {
  width: calc(100% - 20px);
  margin: 10px;
}

.bp3-slider-handle {
  border-radius: 50%;
  outline: none;
}

.bp3-slider-progress.bp4-intent-primary {
  background-color: #0092ff;
}

.bp3-heading {
  font-weight: 700;
}

.bp3-dialog {
  background: #fff;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  padding: 24px;
  box-shadow: 1px 1px 10px #0000000f;
}

.bp3-dialog .bp4-icon {
  margin-right: 16px;
}

.bp3-dialog p {
  margin-bottom: 10px;
}

.bp3-dialog svg {
  flex-shrink: 0;
}

.bp3-dialog .custom-icon {
  margin-right: 12px;
}

.bp3-dialog .bp4-callout {
  min-width: none;
}

.bp3-dialog .bp4-icon {
  background: none;
}

.bp3-dialog .bp4-icon svg {
  width: 24px;
  height: 24px;
}

.bp3-dialog .bp4-button.bp4-intent-danger {
  background: #f55656;
}

.bp3-dialog.bp4-alert svg, .bp3-dialog.bp4-alert .custom-icon {
  color: #f55656;
}

.bp3-overlay-backdrop {
  background: #00000008;
}

.bp3-control {
  color: #536f84;
}

.bp3-control-indicator {
  outline: none !important;
}

.bp3-control:hover input:checked ~ .bp4-control-indicator {
  background: #0092ff;
}

.bp3-control:hover input ~ .bp4-control-indicator {
  background: #ebf1f5;
}

.bp3-control.bp4-switch:hover input:checked ~ .bp4-control-indicator {
  background: #0075cc;
}

.bp3-control.bp4-switch input:checked ~ .bp4-control-indicator, .bp3-control input:checked ~ .bp4-control-indicator {
  background: #0092ff;
}

.bp4-control:hover input:checked ~ .bp4-control-indicator .bp3-tab-indicator-wrapper ~ .bp4-tab {
  outline: none;
}

.bp3-input {
  box-shadow: none;
  resize: none;
  height: 36px;
  color: #8a9ba8;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
}

.bp3-input:focus {
  box-shadow: none;
  border-color: #e1e8ed;
}

.bp3-input ::-webkit-input-placeholder, .bp3-input-group ::-webkit-input-placeholder {
  color: #8a9ba8;
}

.bp3-input ::-moz-placeholder, .bp3-input-group ::-moz-placeholder {
  color: #8a9ba8;
}

.bp3-input :-ms-input-placeholder, .bp3-input-group :-ms-input-placeholder {
  color: #8a9ba8;
}

.bp3-input :-moz-placeholder, .bp3-input-group :-moz-placeholder {
  color: #8a9ba8;
}

.bp3-file-upload-input {
  box-shadow: none;
  background: #f9f9f9;
  border: 1px solid #0000;
  border-radius: 4px;
}

.bp3-file-input {
  border-radius: 6px;
}

.bp3-file-input input {
  width: 100%;
}

.bp3-button {
  box-shadow: none;
  border-radius: 6px;
  outline: none;
  transition: all .1s ease-in-out;
}

.bp3-button.bp4-minimal.bp4-intent-primary, .bp3-button.bp4-minimal.bp4-intent-primary svg {
  color: #0092ff;
}

.bp3-button.bp4-minimal.bp4-intent-primary:hover {
  color: #0092ff;
  background: #f5fbff;
}

.bp3-button.bp4-minimal.bp4-intent-danger, .bp3-button.bp4-minimal.bp4-intent-danger svg {
  color: #f55656;
}

.bp3-button:not(.bp4-intent-primary) .bp4-icon, .bp3-button:not(.bp4-intent-success) .bp4-icon, .bp3-button:not(.bp4-intent-warning) .bp4-icon, .bp3-button:not(.bp4-intent-danger) .bp4-icon {
  color: #5c7680;
}

.bp3-button:not([class*="bp3-intent-"]):hover, .bp3-button.bp4-intent-primary, .bp3-button.bp4-intent-success, .bp3-button.bp4-intent-warning, .bp3-button.bp4-intent-danger, .bp3-button.bp4-intent-primary:hover, .bp3-button.bp4-intent-success:hover, .bp3-button.bp4-intent-warning:hover, .bp3-button.bp4-intent-danger:hover {
  box-shadow: none;
}

.bp3-button.bp4-outlined {
  border-color: #ebf1f5;
}

.bp3-button.bp4-outlined.bp4-intent-primary {
  color: #0092ff;
  border-color: #0092ff;
}

.bp3-button.bp4-outlined.bp4-intent-primary svg {
  color: #0092ff;
}

.bp3-button.bp4-outlined.bp4-intent-primary:hover {
  color: #0092ff;
  background: #f5fbff;
}

.bp3-button.bp4-outlined.bp4-intent-danger {
  color: #f55656;
  border-color: #f55656;
}

.bp3-button.bp4-outlined.bp4-intent-danger svg {
  color: #f55656;
}

.bp3-button:not([class*="bp3-intent-"]) {
  box-shadow: none;
  background: #f9f9f9;
}

.bp3-button:not([class*="bp3-intent-"]):disabled, .bp3-button:not([class*="bp3-intent-"]).bp4-disabled {
  color: #afc1cc;
  background: none;
  border: 1px solid #0000;
}

.bp3-button:not([class*="bp3-intent-"]):disabled svg, .bp3-button:not([class*="bp3-intent-"]).bp4-disabled svg {
  color: #8a9ba8;
}

.bp3-button:hover {
  box-shadow: none;
  background: #f1f1f1;
  border-color: #0000;
}

.bp3-progress-bar.bp4-intent-primary .bp4-progress-meter {
  background-color: #0092ff;
}

.bp3-menu-item:hover {
  background-color: #f9f9f9;
}

.bp3-spinner.bp4-intent-danger .bp4-spinner-head {
  stroke: #f55656;
}

.bp3-form-group {
  margin-bottom: 8px;
}

.bp3-non-ideal-state-visual {
  margin-bottom: 10px;
  font-size: 16px;
}

.bp3-non-ideal-state-visual .bp4-icon svg {
  width: 40px;
  height: 40px;
  color: #8a9ba8;
  margin-bottom: 10px;
}

.bp3-non-ideal-state-visual .custom-icon {
  width: 50px;
  height: 50px;
  color: #8a9ba8;
}

.bp4-menu-item.bp4-intent-primary:hover, .bp4-submenu .bp4-popover-target.bp4-popover-open > .bp4-intent-primary.bp4-menu-item, .bp4-menu-item.bp4-intent-primary.bp4-active {
  background: #0092ff;
}

.bp4-popover2 {
  animation: none;
  box-shadow: 1px 1px 10px #0000000f;
}

.bp4-popover2 .bp4-popover2-arrow:before {
  box-shadow: 1px 1px 10px #0000000f;
}

.bp4-popover2 .bp4-popover2-arrow-border {
  fill: #d8e1e8;
}

.bp4-icon svg {
  width: 16px;
  height: 16px;
  color: #2c3a45;
}

svg path {
  fill: currentColor;
}

svg.custom-icon {
  color: #2c3a45;
  width: 24px;
  height: 24px;
}

.link {
  color: #0092ff;
  display: inline-block;
}

.link .bp4-icon {
  margin-right: 4px;
}

.link .bp4-icon svg {
  color: #0092ff;
}

.link:hover, .link:hover .bp4-icon svg {
  color: #0083e6;
}

.notFound-message {
  padding: 40px 0;
}

.bp4-popover2-transition-container {
  z-index: 999;
  position: relative;
}

.bp4-tooltip2 {
  z-index: 999;
  will-change: auto;
  position: relative;
}

.bp4-tooltip2 .bp4-popover2-content {
  white-space: nowrap;
  color: #182026;
  background: #fff;
  border: 1px solid #ebf1f5;
  border-radius: 6px;
  margin: -20px 0 0 -24px;
  padding: 4px 6px;
  font-size: 10px;
  line-height: 1.2;
  transition: background-color .3s ease-in-out;
}

.bp4-tooltip2 .bp4-popover2-arrow {
  display: none;
}

textarea {
  resize: none;
}

.editor-container {
  width: 100%;
  height: 100vh;
  flex-direction: row;
  display: flex;
}

.editor-container .left-sidebar {
  z-index: 4;
  width: 62px;
  background: #fff;
  border-right: 1px solid #e1e8ed;
  flex-direction: column;
  display: flex;
}

.editor-container .left-sidebar-drawer {
  z-index: 3;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60px;
}

.editor-container .left-sidebar-drawer .bp4-portal .bp4-drawer {
  left: 60px;
  overflow-y: auto;
}

.editor-container .left-sidebar-drawer .bp4-portal .bp4-overlay-backdrop {
  background-color: #10161a4d;
}

.editor-container .viewport-container {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

*, :focus {
  outline: none;
}

.preview-mode #chat-widget-container, .preview-mode .player-ui-container .player-logo {
  display: none;
}

.restrictions-mobile-overlay {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #f9f9f9;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.restrictions-mobile-overlay .panel {
  width: 80%;
  background: #fff;
  border-radius: 16px;
  align-items: flex-start;
  padding: 28px 20px 28px 16px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 1px 1px 10px #0000000f;
}

.restrictions-mobile-overlay .panel .custom-icon {
  width: 36px;
  height: 36px;
  color: #0092ff;
  flex-shrink: 0;
  margin-top: -2px;
  margin-right: 10px;
}

.restrictions-mobile-overlay .panel .bp4-heading {
  font-size: 16px;
  line-height: 1.2;
}

.restrictions-mobile-overlay .panel p {
  font-size: 14px;
}

@media screen and (max-width: 680px) and (orientation: portrait) {
  .restrictions-mobile-overlay {
    display: block;
  }

  .editor-container .viewport-container {
    overflow: hidden;
  }
}

.left-sidebar .bp4-button, .left-sidebar .bottom-button {
  width: 60px;
  background: none;
  border: 1px solid #0000;
  padding: 15px 10px;
  position: relative;
}

.left-sidebar .bp4-button.bp4-active, .left-sidebar .bottom-button.bp4-active {
  background: #f5fbff;
}

.left-sidebar .bp4-button.bp4-active path, .left-sidebar .bottom-button.bp4-active path {
  fill: #0092ff;
}

.left-sidebar .bp4-button svg, .left-sidebar .bottom-button svg {
  color: #182026;
  width: 25px;
  height: 25px;
  margin-right: 0;
}

.left-sidebar .bp4-button .bp4-icon, .left-sidebar .bottom-button .bp4-icon {
  margin-right: 0;
}

.left-sidebar .bp4-button:hover, .left-sidebar .bottom-button:hover {
  background-color: #ebf1f5;
  border-radius: 0;
}

.left-sidebar .bottom-group {
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 0;
}

.left-sidebar .bottom-button {
  text-align: center;
  display: block;
}

.left-sidebar .bottom-button svg {
  width: 20px;
  height: 20px;
}

.toolbar-container {
  opacity: 1;
  width: 52px;
  transition: transform .2s ease-out, opacity .2s ease-in .15s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(50vw, 50vh);
}

.toolbar-container.hidden {
  opacity: 0;
  pointer-events: none;
  transition: transform .2s linear .1s, opacity .2s ease-out;
}

.toolbar-container .bp4-popover, .toolbar-container .bp4-submenu, .toolbar-container .bp4-popover-content, .toolbar-container .bp4-menu {
  min-width: auto;
  border-radius: 10px;
}

.toolbar-container .bp4-submenu.bp4-popover > .bp4-popover-content {
  border: 2px solid #e1e8ed;
  top: 3px;
  transform: translateY(-25%);
  box-shadow: 1px 1px 10px #0000000f;
}

.toolbar-container .annotation-label-submenu {
  width: 200px;
  padding: 10px;
}

.toolbar-box {
  min-width: auto;
  border: 2px solid #e1e8ed;
  margin-bottom: 5px;
  padding: 0;
  overflow: hidden;
  border-radius: 10px !important;
}

.toolbar-box .bp4-popover2-target {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.toolbar-box .bp4-popover2-target > li {
  width: 100%;
}

.toolbar-box .custom-icon {
  width: 26px;
  height: 26px;
}

.toolbar-box .sm-icon {
  width: 17px;
  height: 17px;
}

.toolbar-box .xs-icon {
  width: 14px;
  height: 14px;
}

.toolbar-box .bp4-menu {
  padding: 2px;
}

.toolbar-box .bp4-menu-item {
  height: 48px;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 0;
  padding: 0;
  line-height: 48px;
  display: block;
}

.toolbar-box .bp4-menu-item svg, .toolbar-box .bp4-menu-item .bp4-icon {
  margin: 0;
}

.toolbar-box .bp4-menu-item .bp4-icon-caret-right, .toolbar-box .bp4-menu-item .bp4-text-overflow-ellipsis {
  display: none;
}

.toolbar-box .bp4-popover2-content {
  margin-left: -20px;
}

.toolbar-box .bp4-active svg {
  color: #fff;
}

.highlight-color-picker {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  display: flex;
}

.highlight-color-picker .highlight-color-sample {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 25px;
  margin: 2px;
  padding: 2px;
  transition: all .3s ease-in-out;
  overflow: hidden;
}

.highlight-color-picker .highlight-color-sample:hover {
  border: 2px solid #dcdcdc;
}

.highlight-color-picker .highlight-color-sample.active {
  border: 2px solid #0092ff;
}

.highlight-color-picker .highlight-color-sample .sample {
  width: 100%;
  height: 100%;
  border-radius: 25px;
  box-shadow: inset 0 0 4px #10161a66;
}

.attribute-propagation-menu.hidden {
  display: none;
}

.attribute-propagation-menu .menu-item-container {
  transform-origin: 50% 94px;
  position: absolute;
  top: -63px;
  left: 3px;
}

.attribute-propagation-menu .menu-item-container.disabled .menu-item {
  color: #8a9ba8;
}

.attribute-propagation-menu .menu-item-container.disabled .menu-item svg, .attribute-propagation-menu .menu-item-container.disabled .menu-item svg path {
  color: #8a9ba8;
  fill: #8a9ba8;
}

.attribute-propagation-menu .menu-item-container.disabled .menu-item:hover {
  color: #8a9ba8;
  cursor: not-allowed;
  background-color: #fff;
}

.attribute-propagation-menu .menu-item-container.disabled .menu-item:hover svg, .attribute-propagation-menu .menu-item-container.disabled .menu-item:hover svg path {
  color: #8a9ba8;
  fill: #8a9ba8;
}

.attribute-propagation-menu .menu-item-container .menu-item {
  color: #5c7680;
  width: 47px;
  height: 47px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #ebf1f5;
  border-radius: 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.attribute-propagation-menu .menu-item-container .menu-item:hover svg, .attribute-propagation-menu .menu-item-container .menu-item:hover svg path {
  color: #fff;
  fill: #fff;
}

.attribute-propagation-menu .menu-item-container .menu-item:hover {
  color: #fff;
  background-color: #0092ff;
}

.attribute-propagation-menu .bp4-popover2-content {
  white-space: pre-wrap;
  min-width: 94px;
}

.ancestor-selector-menu .overlay {
  pointer-events: none;
  position: fixed;
  inset: 0;
}

.ancestor-selector-menu .overlay .menu-container {
  pointer-events: all;
  position: absolute;
  transform: translate(-30px, -20px);
  box-shadow: 1px 1px 10px #0000000f;
}

.ancestor-selector-menu .overlay .menu-container .bp4-menu {
  min-width: 144px;
  padding: 0;
  font-size: 12px;
}

.ancestor-selector-menu .overlay .menu-container .bp4-menu-item {
  padding-top: 4px;
  padding-bottom: 4px;
}

.settings-panel {
  padding: 20px 25px 25px;
}

.settings-panel .bp4-label {
  font-size: 16px;
}

.settings-panel .thumbnail-preview {
  margin: 10px 0 20px;
  position: relative;
}

.settings-panel .thumbnail-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.settings-panel .thumbnail-preview .bp4-form-group {
  margin-bottom: 0;
}

.settings-panel .thumbnail-preview .bp4-spinner {
  width: 100%;
  background: #fff9;
  position: absolute;
  top: 0;
  bottom: 44px;
  left: 0;
}

.settings-panel .thumbnail-preview .bp4-button {
  border-radius: 6px;
}

.settings-panel .thumbnail {
  border: 1px solid #ebf1f5;
  border-radius: 6px;
  margin-bottom: 8px;
  animation: fade-in .5s;
  overflow: hidden;
}

.settings-panel .bp4-slider {
  width: 100%;
  margin: 7px 0;
}

.model-gallery-panel {
  height: 100%;
  flex-direction: row;
  display: flex;
}

.model-gallery-panel .model-gallery-left-sidebar {
  text-align: left;
  border-right: 1px solid #ebf1f5;
  flex-direction: column;
  padding: 4px 0;
  display: flex;
}

.model-gallery-panel .model-gallery-left-sidebar .search-box {
  margin-bottom: 10px;
  padding: 0 5px;
  position: relative;
}

.model-gallery-panel .model-gallery-left-sidebar .search-box svg {
  z-index: 10;
  position: absolute;
  top: 5px;
  left: 5px;
  transform: scale(.8);
}

.model-gallery-panel .model-gallery-left-sidebar .search-box input {
  font-size: 13px;
}

.model-gallery-panel .model-gallery-left-sidebar .bp4-menu {
  padding: 5px 0;
}

.model-gallery-panel .model-gallery-left-sidebar .bp4-button {
  color: #182026;
  border-radius: 0;
  font-size: 14px;
  transition: all .2s ease-in-out;
}

.model-gallery-panel .model-gallery-left-sidebar .bp4-button.bp4-active {
  color: #fff;
  background-color: #0092ff;
}

.model-gallery-panel .model-gallery-left-sidebar .bp4-button.bp4-active svg {
  color: #fff;
}

.model-gallery-panel .model-gallery-left-sidebar .bp4-button-text {
  flex: auto;
}

.model-gallery-panel .model-gallery-left-sidebar .bp4-menu-item {
  border-radius: 0;
  padding: 10px 15px;
  font-size: 14px;
  transition: all .2s ease-in-out;
}

.model-gallery-panel .model-gallery-content {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.model-gallery-panel .model-gallery-content .spinner {
  margin-top: 100px;
}

.model-gallery-panel .model-gallery-content .bp4-non-ideal-state {
  margin-top: -60px;
}

.model-gallery-panel .user-model-navbar {
  box-shadow: none;
  border-bottom: 1px solid #ebf1f5;
  padding: 0 8px;
}

.model-gallery-panel .user-model-navbar .bp4-button {
  background: #ebf6ff;
  font-weight: 500;
}

.model-gallery-panel .user-model-navbar .navbar-label h5.bp4-heading {
  color: #90a9b8;
  margin: 0;
  font-weight: normal;
}

.model-gallery-panel .user-model-navbar .help {
  margin: 0 10px;
}

.model-gallery-panel .user-model-navbar .help svg {
  color: #7090a5;
}

.model-gallery-panel .user-model-navbar label.upload-button {
  margin-bottom: 0;
}

.model-gallery-panel .user-model-navbar .upload-progress {
  width: 300px;
}

.model-gallery-panel .user-model-navbar .confirmation-message {
  color: #15b371;
  font-weight: lighter;
}

.model-gallery-panel .user-model-navbar .confirmation-message span {
  margin-left: 5px;
}

.model-gallery-items-container {
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
  padding: 15px;
  display: grid;
  overflow-y: auto;
}

.model-gallery-items-container .model-gallery-item {
  cursor: pointer;
  flex-direction: column;
  transition: transform .2s;
  animation: scale-up-center-subtle .3s cubic-bezier(.39, .575, .565, 1) both;
  display: flex;
  position: relative;
}

.model-gallery-items-container .model-gallery-item:hover {
  transform: scale(.95);
}

.model-gallery-items-container .model-gallery-item:hover .delete-button {
  opacity: 1;
  pointer-events: auto;
  background: none;
}

.model-gallery-items-container .model-gallery-item:hover .delete-button:hover svg {
  color: #f22626;
}

.model-gallery-items-container .model-gallery-item .delete-button {
  opacity: 0;
  pointer-events: none;
  width: 40px;
  height: 40px;
  z-index: 10;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  right: 0;
}

.model-gallery-items-container .model-gallery-item .thumbnail {
  min-height: 180px;
  background: #f2f2f2;
  border-radius: 12px;
  transition: transform .3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.model-gallery-items-container .model-gallery-item .thumbnail:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.model-gallery-items-container .model-gallery-item .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.model-gallery-items-container .model-gallery-item .thumbnail:hover {
  border: none;
  transform: scale(1.02);
}

.model-gallery-items-container .model-gallery-item .name {
  text-align: center;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 6px 0 12px;
  font-size: 15px;
  line-height: 1.2;
  display: -webkit-box;
  overflow: hidden;
}

.step-list-sidebar {
  z-index: 2;
  min-width: 166px;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  padding: 0;
  display: flex;
  position: relative;
  box-shadow: 0 0 4px -2px #000;
}

.step-list-sidebar .step-list {
  min-height: 4px;
  scrollbar-width: thin;
  padding: 0 4px;
  overflow-x: hidden;
  overflow-y: auto;
}

.step-thumbnail {
  height: 120px;
  width: 150px;
  border: 2px solid #0000;
  margin: 8px 4px;
  padding: 0;
  transition: all .3s ease-in-out;
  position: relative;
  box-shadow: 0 0 0 1px #d8e1e8;
}

.step-thumbnail .background-spinner {
  position: absolute;
  inset: 0;
}

.step-thumbnail.dragging {
  opacity: .5;
  border: 2px solid #d6dcdf;
}

.step-thumbnail.dragging .bp4-button {
  display: none;
}

.step-thumbnail img {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: cover;
  -webkit-user-drag: none;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}

.step-thumbnail:last-child {
  margin-bottom: 4px;
}

.step-thumbnail:hover {
  cursor: pointer;
  box-shadow: 0 0 1px 2px #0092ff80;
}

.step-thumbnail:active, .step-thumbnail.selected {
  border-color: #0092ff;
}

.step-thumbnail.selected.dragging {
  box-shadow: 0 0 0 1px #0092ff;
}

.step-thumbnail:hover .delete-button, .step-thumbnail:hover .camera-button {
  opacity: 1;
}

.step-thumbnail .thumbnail-actions {
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.step-thumbnail .thumbnail-actions .bp4-tooltip2 {
  top: 10px;
}

.step-thumbnail .thumbnail-actions .bp4-tooltip2 .bp4-popover2-content {
  margin: 0;
}

.step-thumbnail .delete-button, .step-thumbnail .camera-button {
  opacity: 0;
  width: 26px;
  height: 26px;
  margin-left: 4px;
  transition: opacity .2s ease-out;
}

.step-thumbnail .delete-button svg, .step-thumbnail .camera-button svg {
  width: 20px;
  height: 20px;
}

.step-thumbnail .delete-button:hover, .step-thumbnail .camera-button:hover {
  background: #ebf1f5;
}

.step-thumbnail .camera-button.has-camera .bp4-icon, .step-thumbnail .camera-button.has-camera svg path {
  color: #f29d49;
  fill: #f29d49;
}

.drag-drop-box {
  position: relative;
}

.drag-drop-box .drop-overlay {
  display: block;
  position: absolute;
  inset: 0;
}

.drag-drop-box .drop-overlay.dragging {
  background: #0000001a;
  display: block;
}

.drag-drop-box .drop-overlay.dropping.drop-high {
  box-shadow: inset 0 50px 50px -50px #dcdcdc;
}

.drag-drop-box .drop-overlay.dropping.drop-low {
  box-shadow: inset 0 -50px 50px -50px #dcdcdc;
}

@-moz-document url-prefix() {
  .step-list.has-scroll {
    padding-right: 14px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #f55656b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #f5565600;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #f5565600;
  }
}

.step-instructions {
  height: 62px;
  min-width: 300px;
  flex-direction: row;
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.step-instructions .bp4-popover2-arrow {
  display: none;
}

.step-instructions .bp4-popover2-content {
  margin: 0;
}

.step-instructions .step-instructions-text textarea.bp4-input {
  width: 30vw;
  min-width: 100px;
  height: 100%;
  resize: none;
  background: #fff;
  border-radius: 16px;
  font-size: 13px;
}

.step-instructions-narration {
  flex-direction: row;
  align-items: normal;
  margin-right: 6px;
  display: flex;
}

.step-instructions-narration .record-microphone, .step-instructions-narration .upload-actions {
  margin-left: 6px;
}

.step-instructions-narration .record-microphone .bp4-popover2, .step-instructions-narration .upload-actions .bp4-popover2 {
  left: 0;
}

.step-instructions-narration svg {
  width: 28px;
  height: 28px;
  color: #0092ff;
  transform: scale(1.2);
}

.step-instructions-narration .bp4-button {
  width: 60px;
  height: 60px;
  background: #fff;
  border-color: #e1e8ed;
  border-radius: 12px;
  animation: fade-in .3s ease-in-out;
}

.step-instructions-narration .bp4-button:hover {
  background: #fafdff;
}

.step-instructions-narration .spinner-wrapper {
  width: 126px;
  height: 60px;
  background: #fff;
  border: 1px solid #e1e8ed;
  border-radius: 12px;
  margin-left: 6px;
  animation: fade-in .3s ease-in-out;
}

.step-instructions-narration .record-microphone .spinner-box {
  position: relative;
}

.step-instructions-narration .record-microphone .spinner-box .bp4-spinner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.step-instructions-narration .recording-hint {
  width: 10px;
  height: 10px;
  background: #f55656;
  border-radius: 50%;
  animation: pulse 2s infinite;
  display: block;
  transform: scale(1);
  box-shadow: 0 0 #f55656;
}

.step-instructions-narration .playback-actions {
  align-items: center;
  display: flex;
}

.step-instructions-narration .playback-actions .bp4-button {
  margin-left: 6px;
}

.add-step-button {
  padding: 4px 7px;
}

.add-step-button .bp4-button {
  background: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
}

.player-viewport {
  position: fixed;
  inset: 0;
}

.player-viewport canvas {
  width: 100%;
  height: 100%;
}

.loading-screen {
  z-index: 9999;
  background: #fff;
  position: fixed;
  inset: 0;
}

.loading-screen .progress-bar {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bp4-spinner {
  padding: 20px;
}

.bp4-spinner.bp4-intent-primary .bp4-spinner-head {
  stroke: #0092ff;
}

.spinner-overlay {
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #fff9;
  position: absolute;
  top: 0;
  left: 0;
}

.spinner-overlay .bp4-spinner {
  z-index: 10;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.preview-actions {
  position: absolute;
  top: 20px;
  right: 20px;
}

.save-status-display {
  z-index: 99;
  color: #5c7680;
  background: #fff;
  border: 1px solid #ebf1f5;
  border-radius: 6px;
  align-items: center;
  padding: 6px 12px;
  font-size: 13px;
  animation: fade-in .3s ease-in-out;
  display: inline-flex;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 1px 1px 10px #0000000f;
}

.save-status-display .bp4-spinner {
  margin-left: 10px;
  padding: 0;
}

.save-status-display .bp4-icon {
  margin-left: 10px;
  animation: scale-up-center .3s cubic-bezier(.39, .575, .565, 1) both;
}

.save-status-display .bp4-icon svg {
  color: #0092ff;
}

.annotation-marker-container {
  position: fixed;
  top: 0;
  left: 0;
}

.annotation-marker-container .marker-point {
  width: 18px;
  height: 18px;
  opacity: .3;
  z-index: 1;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 25px;
  transition: all .1s;
  position: absolute;
  top: -12px;
  left: -12px;
  box-shadow: 1px 1px 5px #0003;
}

.annotation-marker-container .marker-point .label {
  width: auto;
  text-align: center;
  color: #182026;
  min-width: 110px;
  background: #fff;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 14px;
  line-height: 1.2;
  transition: all .1s;
  display: none;
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 1px 1px 5px #0003;
}

.annotation-marker-container .marker-point.visible {
  z-index: 2;
  opacity: 1;
  border: none;
}

.annotation-marker-container .marker-point.visible .label {
  display: block;
}

.annotation-marker-container .marker-point.visible:hover, .annotation-marker-container .marker-point.visible.highlighted, .annotation-marker-container .marker-point.visible:hover .label, .annotation-marker-container .marker-point.visible.highlighted .label {
  color: #fff;
  background-color: #0092ff;
}

.annotation-box {
  border-bottom: 1px solid #ebf1f5;
  margin-bottom: 10px;
  padding: 5px;
}

.annotation-box.highlighted {
  background: #87cefa1a;
  box-shadow: inset 0 0 5px 2px #0092ff;
}

.annotation-label-submenu .bp4-input-group .bp4-input-left-container {
  top: 3px;
}

.player-ui-container .bp4-button {
  min-width: 62px;
  min-height: 62px;
  border-radius: 12px;
}

.player-ui-container .player-logo {
  z-index: 99;
  width: 120px;
  display: block;
}

.player-ui-container .player-logo img {
  display: block;
}

.player-ui-container .player-logo svg {
  width: 100%;
  height: auto;
}

.player-ui-container .top-button {
  background: #fff;
  border: 1px solid #ebf1f5;
}

.player-ui-container .top-button svg {
  color: #0092ff;
}

.player-ui-container .big-button {
  color: #0092ff;
  background: #fff;
  border: 1px solid #ebf1f5;
  border-radius: 12px;
  padding: 20px 28px;
}

.player-ui-container .big-button .bp4-icon svg, .player-ui-container .big-button .custom-icon {
  color: #0092ff;
}

.player-ui-container .big-button:hover {
  color: #0092ff;
  background: #f9f9f9;
}

.player-ui-container .big-button.bp4-disabled {
  color: #ced9e0;
  background: #fff;
  border-color: #ebf1f5;
}

.player-ui-container .big-button.bp4-disabled path {
  stroke: #ced9e0;
}

.player-ui-container .big-button.bp4-disabled:hover {
  background: #f9f9f9;
}

.player-ui-container .big-button svg {
  width: 20px;
  height: 20px;
}

.player-ui-container .player-controls svg path {
  stroke: #0092ff;
}

.player-ui-container .top-bar {
  width: 100%;
  height: 0;
  flex-direction: row;
  padding: 12px;
  display: flex;
  position: fixed;
}

.player-ui-container .top-bar .dropdown-menu {
  margin-left: auto;
}

.player-ui-container .player-controls {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  top: 50%;
  right: 12px;
  transform: translateY(-55%);
}

.player-ui-container .player-controls > * {
  align-self: center;
  margin-bottom: 9px;
}

.player-ui-container .player-controls .button-forward .big-button {
  border-radius: 12px;
}

.player-ui-container .player-controls .button-forward .big-button svg {
  width: 50px;
  height: 50px;
}

.player-ui-container .player-controls .button-back .big-button svg {
  width: 30px;
  height: 30px;
}

.player-ui-container .player-controls .progress-info {
  color: #222d36;
  background: #fff;
  border-radius: 12px;
  padding: 5px 10px;
}

.player-ui-container .player-instructions-container {
  min-height: 72px;
  width: 80%;
  max-width: 800px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
}

.player-ui-container .player-audio-instructions {
  margin-left: 6px;
}

.player-ui-container .player-audio-instructions .big-button {
  width: 72px;
  height: 72px;
}

.player-ui-container .player-audio-instructions .big-button svg {
  width: 40px;
  height: 40px;
}

.player-ui-container .player-audio-instructions .audio-button svg {
  width: 36px;
  height: 36px;
  animation: fade-in-out 2s infinite forwards;
}

.player-ui-container .player-text-instructions {
  text-align: center;
  min-width: 30vw;
  max-width: 60vw;
  min-height: 72px;
  color: #222d36;
  background: #fff;
  border: 1px solid #ebf1f5;
  border-radius: 12px;
  padding: 20px;
  font-size: 21px;
  position: relative;
}

.player-ui-container .player-text-instructions.multiple-lines {
  text-align: left;
}

.player-ui-container .player-text-instructions p {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.player-ui-container .read-more {
  color: #0092ff;
  box-shadow: none;
  width: 100%;
  text-align: center;
  background: linear-gradient(0deg, #fff 0% 40%, #fff0 100%);
  border: none;
  margin-top: 2px;
  padding: 48px 10px 6px;
  font-size: 16px;
  font-weight: 500;
  display: none;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.player-ui-container .read-more.visible {
  display: block;
}

.player-ui-container .detailed-instructions {
  width: 100vw;
  max-height: calc(100vh - 60px);
  z-index: 99;
  background: #fff;
  padding: 16px 16px 66px;
  font-size: 16px;
  line-height: 1.4;
  animation: appear-bottom .3s ease-in-out;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  box-shadow: 1px 1px 10px #0000000f;
}

.player-ui-container .detailed-instructions.visible {
  display: block;
}

.player-ui-container .detailed-instructions.visible + .player-text-instructions {
  display: none;
}

.player-ui-container .detailed-instructions .bp4-button {
  min-height: 40px;
  background: #0092ff;
  border-radius: 6px;
  font-weight: 600;
}

.player-ui-container .detailed-instructions .bp4-button:hover {
  background: #0075cc;
}

.player-ui-container .detailed-instructions .actions {
  background: #fff;
  justify-content: flex-end;
  padding: 0 16px 16px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 680px) and (orientation: portrait) {
  .player-ui-container .detailed-instructions {
    padding-bottom: 60px;
    font-size: 15px;
  }

  .player-ui-container .detailed-instructions .bp4-button {
    width: 100%;
  }

  .player-ui-container .detailed-instructions .actions {
    padding: 12px;
  }

  .player-ui-container .read-more {
    background: linear-gradient(0deg, #fff 0% 30%, #fff0 100%);
    padding-top: 28px;
    font-size: 14px;
    bottom: 5px;
  }

  .player-ui-container .top-bar {
    height: auto;
    align-items: center;
    padding: 10px;
  }

  .player-ui-container .top-bar .top-button {
    min-width: 42px;
    min-height: 42px;
  }

  .player-ui-container .top-bar .player-logo {
    width: 87px;
  }

  .player-ui-container .player-controls {
    width: 100%;
    flex-direction: row;
    padding: 10px;
    top: auto;
    bottom: 0;
    left: 0;
    transform: none;
  }

  .player-ui-container .player-controls .bp4-button {
    width: 100%;
    height: 56px;
    min-width: 0;
    min-height: 0;
    padding: 10px;
    font-size: 14px;
  }

  .player-ui-container .player-controls .button-back, .player-ui-container .player-controls .button-forward {
    flex: 50%;
    margin-bottom: 0;
    font-weight: 500;
  }

  .player-ui-container .player-controls .button-back .big-button svg, .player-ui-container .player-controls .button-forward .big-button svg {
    width: 26px;
    height: 26px;
  }

  .player-ui-container .player-controls .button-back {
    order: -1;
    margin-right: 5px;
  }

  .player-ui-container .player-controls .button-back .big-button:after {
    content: "Previous step";
    margin-left: 10px;
  }

  .player-ui-container .player-controls .button-forward {
    margin-left: 5px;
  }

  .player-ui-container .player-controls .button-forward svg {
    margin-right: -10px;
  }

  .player-ui-container .player-controls .button-forward .big-button:before {
    content: "Next step";
  }

  .player-ui-container .player-controls .progress-info {
    text-align: center;
    padding: 2px 6px;
    position: fixed;
    top: 21px;
    left: 50%;
    transform: translateX(-50%);
  }

  .player-ui-container .player-instructions-container {
    width: 100%;
    min-height: 0;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 10px;
    bottom: 65px;
    left: 0;
    transform: none;
  }

  .player-ui-container .player-text-instructions {
    max-width: none;
    width: 100%;
    min-height: 0;
    text-align: left;
    flex: auto;
    margin: 0 0 10px;
    padding: 10px 11px;
    font-size: 15px;
  }

  .player-ui-container .player-text-instructions p {
    -webkit-line-clamp: 3;
  }

  .player-ui-container .player-audio-instructions {
    order: -1;
    position: static;
  }

  .player-ui-container .player-audio-instructions .big-button {
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
    background: none;
    border: none;
    margin: 0 2px 0 0;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
  }

  .player-ui-container .player-audio-instructions .big-button:after {
    content: "Play audio";
  }

  .player-ui-container .player-audio-instructions .big-button .custom-icon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }

  .player-ui-container .player-audio-instructions .audio-button:after {
    content: "Audio playing...";
  }
}

.manager-actions {
  width: calc(100% - 32px);
  background: #f9f9f9;
  border-bottom: 1px solid #d8e1e8;
  border-radius: 0;
  align-items: center;
  margin: 40px auto 0;
  padding: 12px 12px 12px 16px;
  display: flex;
}

.manager-actions .bp3-heading {
  text-transform: uppercase;
  color: #6d8292;
  margin: 0;
  font-size: 16px;
}

.manager-actions .bp3-control-group {
  margin-left: auto;
}

@media (min-width: 62.5em) {
  .manager-actions {
    width: 100%;
  }
}

.trainings-list {
  width: calc(100% - 30px);
  height: 100%;
  max-height: calc(100vh - 204px);
  margin: 0 auto;
  overflow-x: visible;
  overflow-y: auto;
}

.trainings-list .training-item {
  box-shadow: none;
  border: 1px solid #d8e1e8;
  border-top-color: #0000;
  border-radius: 0;
  align-items: center;
  display: flex;
}

.trainings-list .training-item p {
  margin: 10px 0;
}

.trainings-list .training-actions {
  flex: 0 0 130px;
  margin-left: auto;
  padding-left: 10px;
}

.trainings-list .training-actions .bp3-button {
  width: 40px;
  height: 40px;
  border: 1px solid #d8e1e8;
}

.trainings-list .training-actions .bp3-button.bp4-intent-danger path {
  fill: #f55656;
}

.trainings-list .training-actions .bp3-button.bp4-intent-primary path {
  fill: #0092ff;
}

.trainings-list .training-title {
  flex: 0 0 140px;
  font-size: 18px;
}

.trainings-list .training-info {
  flex: auto;
  padding-left: 10px;
}

@media (min-width: 62.5em) {
  .trainings-list {
    width: 100%;
  }
}

.main-header {
  height: 60px;
  background: #fff;
  align-items: center;
  padding: 16px;
  display: flex;
  box-shadow: 1px 1px 10px #0000000f;
}

.main-header .wrapper {
  width: 100%;
  align-items: center;
  display: flex;
}

.main-header .logo {
  width: 150px;
}

.main-header .bp4-button {
  border: 1px solid #d8e1e8;
  margin: 0 0 0 auto;
}

.auth-container {
  min-height: 100vh;
  background: #f9f9f9;
  flex-direction: column;
  display: flex;
}

.auth-form .bp3-form-group {
  margin-bottom: 20px;
}

.auth-form .bp3-label {
  font-size: 15px;
}

.auth-form .bp3-button {
  box-shadow: none;
  background: #0092ff;
  margin-top: 20px;
}

.auth-form .bp3-button:hover {
  background: #0083e6;
}

.auth-card {
  width: 90%;
  max-width: 400px;
  box-shadow: none;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  margin: 40px auto;
  padding: 26px;
  transition: none;
}

.auth-title {
  margin: 0 0 10px;
  font-size: 20px;
}

.auth-link {
  text-align: center;
  color: #0092ff;
  padding: 16px 0 0;
  display: block;
}

@media (min-width: 48em) {
  .auth {
    margin-top: -40px;
  }

  .auth-card {
    margin-top: -40px;
    padding: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .auth-title {
    font-size: 24px;
  }
}

/*# sourceMappingURL=index.11ee5a33.css.map */
