// Toolbar

.toolbar-container {
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.2s ease-out, opacity 0.2s ease-in 0.15s;
  opacity: 1;
  width: 52px;

  // this will be overwritten by the component's inline styles
  // but prevents some janky situations when the toolbar flies from the corner
  transform: translate(50vw, 50vh);

  &.hidden {
    opacity: 0;
    pointer-events: none;
    // display: none;
    transition: transform 0.2s linear 0.1s, opacity 0.2s ease-out;
  }

  // Submenus

  .bp4-popover,
  .bp4-submenu,
  .bp4-popover-content,
  .bp4-menu {
    border-radius: 10px;
    min-width: auto;
  }

  .bp4-submenu.bp4-popover > .bp4-popover-content {
    // box-shadow: 0 0 0 1px rgba(16 22 26 / 10%), 0 1px 4px rgb(16 22 26 / 20%);
    box-shadow: $elevation1;
    border: 2px solid $gray-light-3;
    top: 3px;
    transform: translateY(-25%);
  }

  // Annotation Label Submenu

  .annotation-label-submenu {
    width: 200px;
    padding: 10px;
  }
}

// Toolbar box

.toolbar-box {
  min-width: auto;
  border: 2px solid $gray-light-3;
  border-radius: 10px !important;
  padding: 0;
  overflow: hidden;
  margin-bottom: 5px;

  .bp4-popover2-target {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > li {
      width: 100%;
    }
  }

  .custom-icon {
    width: 26px;
    height: 26px;
  }

  .sm-icon {
    width: 17px;
    height: 17px;
  }

  .xs-icon {
    width: 14px;
    height: 14px;
  }

  .bp4-menu {
    padding: 2px;
  }

  .bp4-menu-item {
    height: 48px;
    line-height: 48px;
    display: block;
    border: none;
    border-radius: 0;
    width: 100%;
    text-align: center;
    padding: 0;
    svg {
      margin: 0;
    }
    .bp4-icon {
      margin: 0;
    }

    .bp4-icon-caret-right,
    .bp4-text-overflow-ellipsis {
      display: none;
    }
  }

  .bp4-popover2-content {
    margin-left: -20px;
  }

  .bp4-active {
    svg {
      color: #fff;
    }
  }
}

// Color Picker

.highlight-color-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;

  .highlight-color-sample {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    overflow: hidden;
    padding: 2px;
    margin: 2px;
    cursor: pointer;
    transition: all $general-change;

    &:hover {
      border: 2px solid gainsboro;
    }

    &.active {
      border: 2px solid $primary;
    }

    .sample {
      box-shadow: inset 0 0px 4px rgb(16 22 26 / 40%);
      width: 100%;
      height: 100%;
      border-radius: 25px;
    }
  }
}

// Attribute Propagation Radial Menu

.attribute-propagation-menu {
  &.hidden {
    display: none;
  }

  .menu-item-container {
    position: absolute;
    top: -63px;
    left: 3px;
    transform-origin: 50% 94px;

    &.disabled {
      .menu-item {
        color: $gray-3;
        svg,
        svg path {
          color: $gray-3;
          fill: $gray-3;
        }
        &:hover {
          background-color: white;
          color: $gray-3;
          cursor: not-allowed;
          svg,
          svg path {
            color: $gray-3;
            fill: $gray-3;
          }
        }
      }
    }

    .menu-item {
      color: $gray-1;
      width: 47px;
      height: 47px;
      background-color: white;
      border: 2px solid $gray-light-4;
      border-radius: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        svg,
        svg path {
          color: #fff;
          fill: #fff;
        }
      }

      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }
  .bp4-popover2-content {
    white-space: pre-wrap;
    min-width: 94px;
  }
}

// Ancestor selector menu

.ancestor-selector-menu {
  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    .menu-container {
      position: absolute;
      transform: translate(-30px, -20px);
      box-shadow: $elevation1;
      pointer-events: all;
      .bp4-menu {
        font-size: 12px;
        min-width: 144px;
        padding: 0;
        &-item {
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
    }
  }
}
