// Settings

// Colors

$gray-1: #5c7680;
$gray-2: #6d8292;
$gray-3: #8a9ba8;
$gray-4: #d6dcdf;
$gray-5: #afafaf;
$gray-light-1: #ced9e0;
$gray-light-2: #d8e1e8;
$gray-light-3: #e1e8ed;
$gray-light-4: #ebf1f5;
$gray-light-5: #f9f9f9;
$gray-light-6: #f2f2f2;
$primary: #0092ff;
$success: #15b371;
$warning: #f29d49;
$error: #f55656;
$blue-dark-1: #202b35;
$white-transp: rgba(255, 255, 255, 0.7);

$black-font: #182026;
$primary-light-4: rgba($primary, 0.1);
$primary-light-5: rgb(244, 249, 255);

// Fonts
$font-base: 16;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$font-black: 900;

$font: 'Inter', sans-serif;

$space-xs: 8px;
$space-s: 12px;
$space-m: 14px;
$space-l: 16px;
$space-xl: 26px;

$elevation1: 1px 1px 10px 0 rgba(0, 0, 0, 0.06);
$elevation2: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
$border-radius: 6px;
$border-radius-md: 12px;
$border-radius-xl: 16px;
$nav-width: 58px;
$sidebar-width: 224px;
$box-height: 36px;

// Layout
$layout-maxWidth: 1100px;

// Breakpoints
$breakpoints: (
  phone: 667px,
  tablet: 768px,
  desktop: 1024px,
  desktop-wide: 1280px,
);

$mobile-threshold: 680px;
