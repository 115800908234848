// Model gallery panel

.model-gallery-panel {
  display: flex;
  flex-direction: row;
  height: 100%;

  .model-gallery-left-sidebar {
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    text-align: left;
    border-right: 1px solid $gray-light-4;

    .search-box {
      margin-bottom: 10px;
      padding: 0 5px;
      position: relative;

      svg {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 10;
        transform: scale(0.8);
      }
      input {
        font-size: 13px;
      }
    }

    .bp4-menu {
      padding: 5px 0;
    }

    .bp4-button {
      color: rgb(24, 32, 38);
      font-size: 14px;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
      &.bp4-active {
        background-color: $primary;
        color: #fff;
        svg {
          color: #fff;
        }
      }
    }

    .bp4-button-text {
      flex: 1 1 auto;
    }

    .bp4-menu-item {
      padding: 10px 15px;
      font-size: 14px;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
    }
  }

  .model-gallery-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .spinner {
      margin-top: 100px;
    }

    .bp4-non-ideal-state {
      margin-top: -60px;
    }
  }

  .user-model-navbar {
    box-shadow: none;
    border-bottom: 1px solid $gray-light-4;
    padding: 0 8px;

    .bp4-button {
      background: lighten($primary, 46%);
      font-weight: $medium;
    }
    .navbar-label {
      h5.bp4-heading {
        margin: 0;
        font-weight: normal;
        color: darken($gray-light-1, 20%);
      }
    }
    .help {
      margin: 0 10px;
      svg {
        color: darken($gray-light-1, 30%);
      }
    }
    label.upload-button {
      margin-bottom: 0;
    }
    .upload-progress {
      width: 300px;
    }
    .confirmation-message {
      font-weight: lighter;
      color: $success;
      span {
        margin-left: 5px;
      }
    }
  }
}

// Model gallery items

.model-gallery-items-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
  padding: 15px;
  overflow-y: auto;

  .model-gallery-item {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.2s ease;
    animation: scale-up-center-subtle $scale-change;

    &:hover {
      transform: scale(0.95);
      .delete-button {
        opacity: 1;
        pointer-events: auto;
        background: transparent;
        &:hover {
          svg {
            color: darken($error, 10%);
          }
        }
      }
    }

    .delete-button {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease-out;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      z-index: 10;
    }

    .thumbnail {
      min-height: 180px;
      border-radius: $border-radius-md;
      overflow: hidden;
      background: $gray-light-6;
      position: relative;
      transition: transform $general-change;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      img {
        @include centered(absolute);
        width: 100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: multiply;
      }
      &:hover {
        border: none;
        transform: scale(1.02);
      }
    }

    .name {
      line-height: 1.2;
      text-align: center;
      font-size: 15px;
      margin: 6px 0 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
